import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import i18n from '../../../../../i18n.config';

export interface MenuFilter {
    id: string;
    name: string;
    diet: number;
    checked: boolean;
}

export interface CuisineFilter {
    id: string;
    name: string;
    checked: boolean;
}

export interface UiState {
    language: string;
    dataSaver: boolean;
    animations: boolean;
    selectedFestivalCodeName: string | null | undefined;
    userEmail: string | null | undefined;
    menuFilters: MenuFilter[];
    favouriteRestaurants: string[];
    cuisineFilter: CuisineFilter | null;
}

const initialState: UiState = {
    language: "sk",
    dataSaver: false,
    animations: true,
    selectedFestivalCodeName: null,
    userEmail: null,
    favouriteRestaurants: [],
    menuFilters: [],
    cuisineFilter: null
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        setDataSaver: (state, action: PayloadAction<boolean>) => {
            state.dataSaver = action.payload;
        },
        setAnimations: (state, action: PayloadAction<boolean>) => {
            state.animations = action.payload;
        },
        setMenuFilters: (state, action: PayloadAction<MenuFilter[]>) => {
            state.menuFilters = action.payload;
        },
        setCuisineFilter: (state, action: PayloadAction<CuisineFilter>) => {
            state.cuisineFilter = action.payload;
        },
        setUserEmail: (state, action: PayloadAction<string>) => {
            state.userEmail = action.payload;
        },
        setSelectedFestivalCodeName: (state, action: PayloadAction<string | null | undefined>) => {
            state.selectedFestivalCodeName = action.payload;
        },
        setFavouriteRestaurants: (state, action: PayloadAction<string[]>) => {
            state.favouriteRestaurants = action.payload;
        },
        addFavouriteRestaurants: (state, action: PayloadAction<string[]>) => {
            if (state?.favouriteRestaurants?.length > 0) {
                let newRestaurants = action.payload.filter(c => state.favouriteRestaurants.map(id => id).indexOf(c) === -1);
                if (newRestaurants && newRestaurants.length > 0) {
                    state.favouriteRestaurants = [...state.favouriteRestaurants, ...newRestaurants];
                }
            } else {
                state.favouriteRestaurants = action.payload;
            }
        },
        removeFavouriteRestaurants: (state, action: PayloadAction<string[]>) => {
            state.favouriteRestaurants = [...state.favouriteRestaurants.filter(id => action.payload.indexOf(id) === -1)];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setLanguage,
    setDataSaver,
    setAnimations,
    setMenuFilters,
    setCuisineFilter,
    setSelectedFestivalCodeName,
    setUserEmail,
    setFavouriteRestaurants,
    addFavouriteRestaurants,
    removeFavouriteRestaurants
} = uiSlice.actions

export default { uiReducer: uiSlice.reducer } 
