import React from "react";
import { Button as _Button, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { BrambleColor } from "../../types/theme";
import { ViewProps, Text, StyleSheet } from "react-native";
// import { default as MIcon } from 'react-native-vector-icons/MaterialIcons';

import { createIconSetFromIcoMoon } from 'react-native-vector-icons';

const BrambleIcon = createIconSetFromIcoMoon(require('../../../assets/fonts/selection.json'), 'bramble', 'bramble');

let MaterialCommunityIcons: React.ComponentType<
    React.ComponentProps<
        typeof import('react-native-vector-icons/MaterialCommunityIcons').default
    > & {
        color: string;
        pointerEvents?: ViewProps['pointerEvents'];
    }
>;

try {
    // Optionally require vector-icons
    MaterialCommunityIcons =
        require('react-native-vector-icons/MaterialCommunityIcons').default;
} catch (e) {
    let isErrorLogged = false;

    // Fallback component for icons
    MaterialCommunityIcons = ({ name, color, size, ...rest }) => {
        /* eslint-disable no-console */
        if (!isErrorLogged) {
            if (
                !/(Cannot find module|Module not found|Cannot resolve module)/.test(
                    (e as any).message
                )
            ) {
                console.error(e);
            }

            console.warn(
                `Tried to use the icon '${name}' in a component from 'react-native-paper', but 'react-native-vector-icons/MaterialCommunityIcons' could not be loaded.`,
                `To remove this warning, try installing 'react-native-vector-icons' or use another method to specify icon: https://callstack.github.io/react-native-paper/docs/guides/icons`
            );

            isErrorLogged = true;
        }

        return (
            <Text
                {...rest}
                style={[styles.icon, { color, fontSize: size }]}
                // @ts-expect-error: Text doesn't support this, but it seems to affect TouchableNativeFeedback
                pointerEvents="none"
                selectable={false}
            >
                □
            </Text>
        );
    };
}


export interface IconProps {
    name: string;
    size: number;
    color: string;
    style?: any;
    onPress?: () => void;
}

export default function Icon(props: IconProps) {

    const theme = useTheme<AppTheme>();

    let _color = theme.colors[props.color as BrambleColor];

    if (!_color) {
        _color = props.color;
    }

    return (
        <BrambleIcon
            name={props.name}
            size={props.size}
            style={[
                {
                    lineHeight: props.size,
                },
                props?.style,
                styles.icon
            ]}
            color={_color}
            onPress={props.onPress}
        />
    );
}

const styles = StyleSheet.create({
    // eslint-disable-next-line react-native/no-color-literals
    icon: {
        backgroundColor: 'transparent'
    },
});