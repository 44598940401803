import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView, ActivityIndicator, Platform, Linking } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Checkbox, ProgressBar, Text, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import Button from "../components/Button/Button";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { RootState } from "../core/store/store";
import TextField from "../components/TextField/TextField";
import { newGuid, sleep, validateEmail } from "../utils/utilities";
import { useTranslation } from "react-i18next";
import { setCurrentOrder, setPaymentAmount } from "../core/store/features/currentOrder/currentOrderSlice";
import StripeElement from "../components/Stripe/Web/StripeElement";
import { dataSaverSelector } from "../core/store/selectors";
import { useIsFocused } from "@react-navigation/native";
import { PAYMENT_SCREEN_LOADING_TIME } from "../core/constants";
import { clearShoppingCart } from "../core/store/features/shoppingCart/shoppingCartSlice";
import { setBottomSheetContext, setBottomSheetOpen } from "../core/store/features/bottomSheet/bottomSheetSlice";
import Conditions from "../components/Conditions/Conditions";
import { setUserEmail } from "../core/store/features/ui/uiSlice";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "payment">) {

    const dispatch = useDispatch();
    let currentOrder = useSelector((state: RootState) => state.currentOrder);

    const orderItems = useSelector((state: RootState) => state.shoppingCart.items);
    const selectedFestivalCodeName = useSelector((state: RootState) => state.ui.selectedFestivalCodeName);
    const userEmail = useSelector((state: RootState) => state.ui.userEmail);
    const dataSaver = useSelector(dataSaverSelector);

    const { t } = useTranslation();
    const theme = useTheme<AppTheme>();
    const [currentPage, setCurrentPage] = useState<number>(1); // start from 1
    const [email, setEmail] = useState<string>("");
    const [processing, setProcessing] = useState<boolean>(true);

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [serviceFee, setServiceFee] = useState<number>(0.15);
    const [agree, setAgree] = React.useState(false);

    const [loaded, setLoaded] = useState<boolean>(dataSaver ? true : false);

    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);
    const steps = ['', 'Email', 'Payment', 'Processing'];
    const [paymentStatus, setPaymentStatus] = useState<string>('');
    const [isOverloaded, setIsOverloaded] = useState<boolean>(dataSaver ? true : false);

    const isFocused = useIsFocused();


    useEffect(() => {

        async function apiCall() {

            if (orderItems && orderItems?.length > 0) {

                let response = await axiosSecure.get(`/api/restaurant?id=${orderItems[0].menuitem.restaurantId}`, false);

                if (response.data && response.data?.length > 0) {
                    setIsOverloaded(response.data[0].isOverloaded)
                }
            }
        }

        apiCall();
    }, [])

    useEffect(() => {
        if (isFocused) {
            setTotalPrice((Math.round((orderItems.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0) + Number.EPSILON + serviceFee) * 100) / 100));
        }
    }, [JSON.stringify(orderItems)])

    useEffect(() => {
        if (isFocused) {
            if (Platform.OS === 'web' && !loaded && !dataSaver) {
                setInterval(() => {
                    setLoaded(!loaded);
                }, PAYMENT_SCREEN_LOADING_TIME);
            }
        }
    }, [])

    useEffect(() => {

        async function processPaymentStatus() {
            if (selectedFestivalCodeName === undefined) return;
            setProcessing(true);
            let paymentId = currentOrder.currentOrderPayment?.id;
            let response = await axiosSecure.put(`/api/order_payment`, {
                id: paymentId,
            });

            if (response.status === 200) {
                if (response.data?.paymentStatus === 'SUCCEEDED') {
                    setPaymentStatus('success');
                    await placeOrder({
                        paymentId: paymentId as string
                    });
                    setProcessing(false);
                    await sleep(300)
                    navigation.navigate("orderState", { festivalCodeName: selectedFestivalCodeName as string });
                    dispatch(clearShoppingCart());
                } else {
                    setPaymentStatus('failed');
                }
            }
            setProcessing(false);
        }

        async function processOrder() {
            setProcessing(true);
            setOrderAmount();
            setProcessing(false);
        }

        function setOrderAmount() {
            let totalPrice = Math.round((orderItems.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0) + Number.EPSILON) * 100) / 100;
            dispatch(setPaymentAmount(totalPrice));
        }

        async function placeOrder({
            paymentId
        }: { paymentId: string }) {
            setProcessing(true);

            if (orderItems && orderItems?.length > 0) {

                let orderNumber = Math.floor(1000 + Math.random() * 9000);

                let orderId = newGuid();

                let orderData = {
                    id: orderId,
                    orderNumber: '' + orderNumber,
                    userEmail: userEmail,
                    orderStateId: 'e261ece3-3cd8-4e8b-b4e6-3efc9d239790',
                    restaurantId: orderItems[0].menuitem.restaurantId,
                    // festivalId: selectedFestival?.id,
                    festivalCodeName: selectedFestivalCodeName,
                    // createdAt: new Date((new Date().setHours(new Date().getHours() - (new Date().getTimezoneOffset() / 60)))).toISOString(),
                    orderPaymentId: paymentId,
                    orderMenuItems: orderItems.map(orderItem => {

                        let orderMenuItemId = newGuid();

                        return ({
                            id: orderMenuItemId,
                            orderId: orderId,
                            menuitemId: orderItem.menuitem.id,
                            amount: orderItem.amout,
                            price: orderItem.menuitem.price,
                            discountPrice: orderItem.menuitem.discountPrice,
                            note: orderItem.note,
                            orderMenuItemExtraMenuItem: orderItem.extraMenuitems.map(extraMenuitem => {

                                let orderMenuItemExtraMenuItemId = newGuid();

                                return ({
                                    id: orderMenuItemExtraMenuItemId,
                                    amount: orderItem.amout, // 1,
                                    price: extraMenuitem.price,
                                    discountPrice: extraMenuitem.discountPrice,
                                    note: '',
                                    orderMenuitemId: orderMenuItemId,
                                    extraMenuitemId: extraMenuitem.id
                                })
                            })
                        })
                    })
                }

                let response = await axiosSecure.post(`/api/order/place`, orderData);

                if (response.data && response.data?.length > 0) {
                    dispatch(setCurrentOrder(response.data?.[0]));
                }
            }
            setProcessing(false);
        }

        if (Platform.OS === 'web') {
            let query_params = new URLSearchParams(window.location.search);
            // check if query params has payment_intent
            if (query_params.has('payment_intent')) {
                let payment_intent = query_params.get('payment_intent');
                if (payment_intent) {
                    setCurrentPage(3);
                }
            }
        }

        if (currentPage === 2) {
            processOrder();
        }

        if (currentPage === 3) {
            processPaymentStatus();
        }

    }, [currentPage]);

    async function handleNextPage() {

        if (orderItems && orderItems?.length > 0) {

            let response = await axiosSecure.get(`/api/restaurant?id=${orderItems[0].menuitem.restaurantId}`, false);

            if (response.data && response.data?.length > 0) {
                let isOverloaded = response.data[0].isOverloaded;
                if (isOverloaded) {
                    setIsOverloaded(isOverloaded);
                    return;
                }
            }

            if (currentPage < steps.length - 1) {
                setCurrentPage(currentPage + 1);
            } else {
                // TODO: change this later
                setCurrentPage(1); // start from 1
            }
        }
    }

    function handleEmailChange(e: string) {
        // setEmail(e);
        dispatch(setUserEmail(e))
    }

    return (
        <>
            {!loaded &&
                <View
                    style={{
                        backgroundColor: colors.white,
                        // marginBottom: 48,
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <ActivityIndicator animating={true} size={'large'} color={colors.ketchup} />
                </View>
            }

            {loaded && <>
                <IconButton
                    icon='arrows_outline_arrow_left'
                    color="white"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1
                    }}
                    onPress={() =>
                        navigation.goBack()
                    }
                />

                {currentPage === 1 && <View
                    style={styles.stickyActionContainer}
                >

                    {isOverloaded && <View style={styles.warningContainer}>
                        <Text variant="headingBold" style={styles.paragraph16}>{t('orderScreen.restaurantOverloaded.title')}</Text>
                        <Text variant="paragraphRegular" style={styles.paragraph16}>{t('orderScreen.restaurantOverloaded.subtitle')}</Text>
                    </View>}

                    <View style={styles.emailContainer}>
                        <View style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8
                        }}>
                            <Text variant="headingBlack" style={styles.emailHeader}>{t('paymentScreen.emailStep.title')}</Text>
                            <Text variant="paragraphRegular" style={styles.emailLabel}>{t('paymentScreen.emailStep.subtitle')}</Text>
                        </View>

                        <TextField
                            style={{
                                width: '100%'
                            }}
                            autoFocus={true}
                            borderRadius={4}
                            label={t('paymentScreen.emailStep.placeholder')}
                            value={userEmail || ''}
                            onChangeText={handleEmailChange}
                        />

                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 16
                        }}>
                            <Text variant="paragraphRegular" style={styles.agreementLabel}>
                                {t('paymentScreen.emailStep.conditions1')}
                                <Text variant="paragraphRegular" style={styles.agreementLink} onPress={() => {
                                    dispatch(setBottomSheetContext({
                                        isOpen: true,
                                        initialHeight: 170,
                                        content: (
                                            <Conditions
                                                onClosePress={() => dispatch(setBottomSheetOpen(false))}
                                                onConfirmPress={() => dispatch(setBottomSheetOpen(false))}
                                            />
                                        )
                                    }));
                                }}>
                                    {t('paymentScreen.emailStep.conditions2')}
                                </Text>

                                <Text variant="paragraphRegular" style={styles.agreementLabel}>
                                    {t('paymentScreen.emailStep.conditions3')}
                                </Text>

                                <Text variant="paragraphRegular" style={styles.agreementLink} onPress={() => {
                                    dispatch(setBottomSheetContext({
                                        isOpen: true,
                                        initialHeight: 170,
                                        content: (
                                            <Conditions
                                                onClosePress={() => dispatch(setBottomSheetOpen(false))}
                                                onConfirmPress={() => dispatch(setBottomSheetOpen(false))}
                                            />
                                        )
                                    }));
                                }}>
                                    {t('paymentScreen.emailStep.conditions4')}
                                </Text>
                            </Text>

                            <Checkbox
                                status={agree ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setAgree(!agree);
                                }}
                            />
                        </View>

                    </View>

                    <View
                        style={styles.actionContainer}
                    >
                        <Button
                            mode="contained"
                            text={t('paymentScreen.action')}
                            disabled={!validateEmail(userEmail || '') || !agree || isOverloaded}
                            color="ketchup"
                            style={{
                                width: '100%'
                            }}
                            onPress={handleNextPage}
                        />
                    </View>
                </View>}

                {currentPage === 2 && <View
                    style={styles.stickyInfoContainer}
                >
                    <Text variant="paragraphBold" style={styles.paragraph16}>{t('paymentScreen.payStep.info')}</Text>
                    <Text variant="labelMedium" style={styles.paragraph16}>{totalPrice} €</Text>
                </View>}

                <View style={{
                    ...styles.container,
                    gap: 8
                }}>
                    {currentPage !== 3 && <Text variant="headingBlack" style={styles.header}>{t('paymentScreen.header')}</Text>}
                    <ProgressBar progress={((currentPage) / 3)} color={colors.black} style={{ backgroundColor: "#999999" }} />
                </View>

                <ScrollView
                    bounces={false} // iOS only - prevent drag whole view if it reach bottom
                    style={{
                        backgroundColor: theme.colors.white,
                        marginBottom: currentPage === 1 ? 80 : undefined,
                        height: Platform.OS === 'web' ? 10 : undefined
                    }}
                    contentContainerStyle={{
                        padding: 16
                    }}
                >
                    {currentPage === 2 &&
                        <>
                            {/* {processing && <ActivityIndicator animating={true} size={'large'} color={theme.colors.ketchup} />} */}
                            {!processing && <StripeElement />}
                        </>
                    }

                    {currentPage === 3 &&

                        <ScrollView
                            style={{
                                backgroundColor: colors.white,
                                // marginBottom: 48,
                                height: '100%'
                            }}
                            contentContainerStyle={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >

                            {processing && <ActivityIndicator animating={true} size={'large'} color={theme.colors.ketchup} />}

                            {!processing && <>
                                {paymentStatus === "failed" && <Image
                                    style={{
                                        width: 96,
                                        height: 96
                                    }}
                                    source={require(`../../assets/images/animation/error.gif`)}
                                />}
                                {paymentStatus !== "failed" && <Image
                                    style={{
                                        width: 96,
                                        height: 96
                                    }}
                                    source={require(`../../assets/images/animation/success.gif`)}
                                />}
                                <Text variant="headingBlack" style={styles.orderHeader}>{paymentStatus == 'failed' ? t('paymentScreen.failedStep.title') : t('paymentScreen.successStep.title')}</Text>
                                <Text variant="paragraphMedium" style={styles.orderLabel}>{paymentStatus == 'failed' ? t('paymentScreen.failedStep.subtitle') : t('paymentScreen.successStep.subtitle')}</Text>
                                {paymentStatus == 'failed' &&
                                    <Button mode="contained" text={t('paymentScreen.failedStep.action')} color="ketchup" style={{
                                        width: '100%'
                                    }} onPress={() => {
                                        setCurrentPage(2);
                                    }} />
                                }
                            </>}
                        </ScrollView>

                    }

                </ScrollView>
            </>}
        </>
    );

}

const makeStyles = (colors: any) => StyleSheet.create({
    stickyActionContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // padding: 16,
        // gap: 16,
        backgroundColor: colors.white
    },
    stickyInfoContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        width: '100%',
        padding: 16,
        backgroundColor: colors.white,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
    actionContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        gap: 16,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    container: {
        backgroundColor: colors.white,
        // marginBottom: 48
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    emailContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: 16,
        gap: 16
    },
    warningContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 16,
        // height: 56,
        backgroundColor: colors.mustardLight,
        borderRadius: 4
    },
    header: {
        lineHeight: 35,
        fontSize: 28,
        marginTop: 48,
        marginHorizontal: 16
    },
    emailHeader: {
        fontSize: 20,
        lineHeight: 25
    },
    emailLabel: {
        fontSize: 16,
        lineHeight: 12
    },
    orderHeader: {
        lineHeight: 30,
        fontSize: 24
    },
    orderLabel: {
        lineHeight: 24,
        fontSize: 16
    },
    agreementLabel: {
        lineHeight: 21,
        fontSize: 14,
        color: colors.greyMedium
    },
    agreementLink: {
        lineHeight: 21,
        fontSize: 14,
        color: colors.greyMedium,
        textDecorationLine: 'underline'
    },
    stepContainer: {
        // flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        // gap: 16,
        padding: 16
    }
});
