import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState: {
        restaurants: [] as any[]
    },
    reducers: {
        setRestaurants: (state, action: PayloadAction<any[]>) => {
            state.restaurants = action.payload;
        },
        addRestaurants: (state, action: PayloadAction<any[]>) => {
            if (state.restaurants.length > 0) {
                let newRestaurants = action.payload.filter(c => state.restaurants.map(i => i.id).indexOf(c.id) === -1);
                if (newRestaurants && newRestaurants.length > 0) {
                    state.restaurants = [...state.restaurants, ...newRestaurants];
                }
            } else {
                state.restaurants = action.payload;
            }
        },
        addRestaurant: (state, action: PayloadAction<any>) => {
            state.restaurants.push(action.payload);
        },
        updateRestaurant: (state, action: PayloadAction<any>) => {
            const restaurant = state.restaurants.find(c => c.id === action.payload.id) as any;
            Object.assign(restaurant, action.payload);
        },
        updateRestaurants: (state, action: PayloadAction<Partial<any>[]>) => {
            for (let change of action.payload) {
                const restaurant = state.restaurants.find(c => c.id === change.id) as any;
                Object.assign(restaurant, change);
            }
        },
        deleteRestaurant: (state, action: PayloadAction<string>) => {
            state.restaurants = [...state.restaurants.filter(c => c.id !== action.payload)];
        },
        deleteRestaurants: (state, action: PayloadAction<string[]>) => {
            state.restaurants = [...state.restaurants.filter(c => action.payload.indexOf(c.id) === -1)];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setRestaurants,
    addRestaurants,
    addRestaurant,
    updateRestaurant,
    updateRestaurants,
    deleteRestaurant,
    deleteRestaurants
} = restaurantSlice.actions

export default { restaurantReducer: restaurantSlice.reducer }
