import React, { useEffect, useState } from "react";
import { StyleSheet, Image, ScrollView, View, useWindowDimensions, Platform, FlatList, TouchableOpacity, ActivityIndicator, Share } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, useTheme } from "react-native-paper";
import { AppTheme } from "../../App";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import CategoryButton from "../components/CategoryButton/CategoryButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../core/store/store";
import RestaurantCard from "../components/RestaurantCard/RestaurantCard";
import { Restaurant } from "../core/interfaces/restaurant.interface";
import useAxiosSecure from "../hooks/useAxiosSecure";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import Performance from "../components/Performance/Performance";
import { useTranslation } from "react-i18next";
import { addFavouriteRestaurants, removeFavouriteRestaurants } from "../core/store/features/ui/uiSlice";
import { dataSaverSelector, festivalByCodeNameSelector, restaurantByCuisineCodeNameSelector, selectedFestivalCodeNameSelector } from "../core/store/selectors";
import Button from "../components/Button/Button";
import axios, { MINIO_URL } from "../core/api/axios";
import dayjs, { Dayjs } from 'dayjs';
import { sleep } from "../utils/utilities";
import { PROGRAM_SCREEN_LOADING_TIME } from "../core/constants";
import { useIsFocused } from "@react-navigation/native";
import { addPerformances } from "../core/store/features/performance/performanceSlice";
export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "program">) {

    const dispatch = useDispatch();

    const dimension = useWindowDimensions();
    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);
    const festival = useSelector((state) => festivalByCodeNameSelector(state, route?.params?.festivalCodeName));
    const allPerformances = useSelector((state: RootState) => state.performance.performances);

    const [thumbnail, setThumbnail] = useState<string>("../../assets/images/festival_placeholder.png");
    const [days, setDays] = useState([]);
    const [stages, setStages] = useState<any[]>([]);
    
    // const [allPerformances, setAllPerformances] = useState<any[]>([]);
    const [filteredPerformances, setFilteredPerformances] = useState<any[]>([]);
    const [selectedDay, setSelectedDay] = useState<string | null>(null);
    const [selectedStageId, setSelectedStageId] = useState<string | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);

    const dataSaver = useSelector(dataSaverSelector);
    const theme = useTheme<AppTheme>();

    const isFocused = useIsFocused();
    const { t } = useTranslation();
    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);


    async function apiCall() {

        if (Platform.OS === 'web' && !loaded && dataSaver) {
            await sleep(100);
        }

        let response = await axiosSecure.get(`/api/festival/${selectedFestivalCodeName}/program`, false);
        setDays(response.data.days);
        setStages(response.data.stages);
        
        // setAllPerformances(response.data.performances);
        dispatch(addPerformances(response.data.performances));

        setFilteredPerformances(_filterPerformances(null, null, response.data.performances));

        if (Platform.OS === 'web' && !loaded && dataSaver) {
            setLoaded(true);
        }
    }

    useEffect(() => {
        if (isFocused) {
            apiCall();
            if (Platform.OS === 'web' && !loaded && !dataSaver) {
                setInterval(() => {
                    setLoaded(!loaded);
                }, PROGRAM_SCREEN_LOADING_TIME);
            }
        }

    }, [route?.params?.festivalCodeName]);

    useEffect(() => {
        if (festival) {
            setThumbnail(`${MINIO_URL}/festivals/${festival?.id}`);
        }
    }, [festival])


    function _filterPerformances(day: string | null, stageId: string | null, performances: any[]) {
        if (day != null) {
            performances = performances.filter(p => {
                const startDay = dayjs(p.startDatetime).startOf('day');
                return startDay.isSame(day, 'day');
            });
        }

        if (stageId != null) {
            performances = performances.filter(p => p.stageId === stageId);
        }

        return performances;
    }

    function handleDayPress(event: any) {
        let day = event;
        if (event === selectedDay) {
            day = null;
        }
        setSelectedDay(day);
        setFilteredPerformances(_filterPerformances(day, selectedStageId, allPerformances));
    }

    function handleStagePress(event: any) {
        let stageId = event;
        if (event === selectedStageId) {
            stageId = null;
        }
        setSelectedStageId(stageId);
        setFilteredPerformances(_filterPerformances(selectedDay, stageId, allPerformances));
    }

    function getDay(datetime: string) {
        const line1 = dayjs(datetime).format("D.M.");

        // Get the name of the day
        const line2 = dayjs(datetime).format('dddd');

        // Calculate the length of the longest line
        let maxLength = Math.max(line1.length, line2.length);

        // Calculate the number of spaces needed to center each line
        let spaces1 = Math.floor((maxLength - line1.length) / 2);
        let spaces2 = Math.floor((maxLength - line2.length) / 2);

        // Create centered lines by adding spaces on both sides
        let centeredLine1 = line1.padStart(spaces1 + line1.length, " ").padEnd(maxLength, " ");
        let centeredLine2 = line2.padStart(spaces2 + line2.length, " ").padEnd(maxLength, " ");

        // Combine both centered lines
        let centeredText = centeredLine1 + "\n" + centeredLine2;

        return centeredText;
    }

    function getTime(datetime: string) {
        return dayjs(datetime).format('HH:mm');
    }

    function handlePerformancePress(event: any) {
        navigation.navigate("artist", {
            performanceId: event.id,
            festivalCodeName: selectedFestivalCodeName as string
        })
    }

    async function handleSharePress() {
        try {
            const result = await Share.share({
                url: window.location.href
            });
        } catch (error: any) {
            // alert(error.message);
        }
    }
    
    return (
        <>

            <View style={{ ...styles.appBar }}>
                <IconButton
                    icon='arrows_outline_arrow_left'
                    color="white"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1
                    }}
                    onPress={() =>
                        navigation.goBack()
                    }
                />

                <IconButton
                    icon='interaction_outline_share'
                    color="white"
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1
                    }}
                    onPress={handleSharePress}
                />
            </View>

            {!loaded &&
                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        marginBottom: 48,
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    {/* <Skeleton colors={colors} /> */}
                    <ActivityIndicator animating={true} size={'large'} color={theme.colors.ketchup} />
                </View>
            }

            {loaded && <ScrollView
                bounces={false} // iOS only - prevent drag whole view if it reach bottom
                style={{
                    ...styles.container,
                    height: Platform.OS === 'web' ? 10 : undefined
                }}
            >
                <View style={{
                    padding: 8
                }}>
                    <Text variant="headingBlack" style={styles.header}>{t('festivalProgramScreen.title')}</Text>
                </View>

                <ScrollView horizontal
                    style={{
                        marginTop: 8,
                        paddingHorizontal: 8
                    }}
                    contentContainerStyle={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // paddingHorizontal: 16,
                        gap: 8
                    }}
                >
                    {days.map((day, index) => (<Button
                        key={day}
                        color={selectedDay === day ? "black" : "greyLight"}
                        contentStyle={{
                            color: selectedDay === day ? "white" : "black"
                        }}
                        mode="contained"
                        text={getDay(day)}
                        size="medium"
                        touchableStyle={{
                            borderRadius: 4,
                            height: 64
                        }}
                        onPress={() => { handleDayPress(day) }}
                    ></Button>))}
                </ScrollView>

                <View style={{
                    padding: 4
                }}>
                    <Image
                        style={{
                            width: '100%',
                            height: 160,
                        }}
                        defaultSource={require("../../assets/images/festival_placeholder.png")}
                        source={{ uri: thumbnail }}
                    />
                </View>

                <ScrollView horizontal
                    style={{
                        marginTop: 16,
                        paddingHorizontal: 8
                    }}
                    contentContainerStyle={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // paddingHorizontal: 16,
                        gap: 8
                    }}
                >
                    {stages.map((stage, index) => (<Button
                        key={stage.id}
                        color={selectedStageId === stage.id ? "black" : "greyLight"}
                        contentStyle={{
                            color: selectedStageId === stage.id ? "white" : "black"
                        }}
                        mode="contained"
                        text={stage.name}
                        size="medium"
                        touchableStyle={{
                            borderRadius: 20
                        }}
                        onPress={() => { handleStagePress(stage.id) }}
                    ></Button>))}
                </ScrollView>

                {filteredPerformances && filteredPerformances?.length > 0 && <View style={styles.timeline}>
                    <View style={styles.verticalLine} />

                    <FlatList
                        data={filteredPerformances}
                        keyExtractor={(item) => item.id}
                        renderItem={({ item }) => (
                            <View
                                style={[
                                    styles.timelineItem,
                                ]}
                            >
                                <View style={{
                                    height: 64,
                                    paddingVertical: 8,
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start'
                                }}>
                                    <Text variant="paragraphRegular" style={styles.timeText}>{getTime(item.startDatetime)}</Text>
                                </View>
                                <View style={styles.content}>
                                    <Performance
                                        onPress={() => handlePerformancePress(item)}
                                        performance={item}
                                    />
                                </View>
                            </View>
                        )}
                    />

                </View>}

                {!filteredPerformances || filteredPerformances?.length === 0 &&
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'

                    }}><Text>
                            No program yet.
                        </Text>
                    </View>}

            </ScrollView >}
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    appBar: {
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.white
    },
    container: {
        backgroundColor: colors.white,
        paddingTop: 0,
        paddingBottom: 8,
        paddingHorizontal: 8
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    header: {
        lineHeight: 35,
        fontSize: 28
    },
    timeline: {
        position: "relative",
    },
    verticalLine: {
        position: "absolute",
        width: 1,
        height: "100%",
        left: 40,
        top: 10,
        zIndex: -1,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderRadius: 1,
        borderLeftColor: colors.grey
    },
    timelineItem: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
        marginLeft: 10,
        paddingLeft: 10
    },
    timeText: {
        fontSize: 16,
        lineHeight: 24,
        backgroundColor: colors.white
    },
    content: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    },
});