import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, useWindowDimensions, InteractionManager, Platform, ActivityIndicator } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, useTheme } from "react-native-paper";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import Button from "../components/Button/Button";
import OrderItem from "../components/OrderItem/OrderItem";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { RootState } from "../core/store/store";
import { ShoppingCartItem, removeItemFromShoppingCart, updateItemInShoppingCart } from "../core/store/features/shoppingCart/shoppingCartSlice";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { sleep } from "../utils/utilities";
import { ORDER_SCREEN_LOADING_TIME } from "../core/constants";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "order">) {

    const dispatch = useDispatch();

    const restaurant = useSelector((state: RootState) => state.restaurant.restaurants.find(r => r.codeName === route.params.restaurantCodeName));
    const orderItems = useSelector((state: RootState) => state.shoppingCart.items);
    const selectedFestivalCodeName = useSelector((state: RootState) => state.ui.selectedFestivalCodeName);
    const dataSaver = useSelector((state: RootState) => state.ui.dataSaver);

    const [orderActionsOpen, setOrderActionsOpen] = useState<string | null>(null);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [itemsPrice, setItemsPrice] = useState<number>(0);
    const [serviceFee, setServiceFee] = useState<number>(0.15);
    const [savedMoney, setSavedMoney] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(dataSaver ? true : false);
    const [isOverloaded, setIsOverloaded] = useState<boolean>(dataSaver ? true : false);


    const axiosSecure = useAxiosSecure();
    const { t } = useTranslation();
    const isFocused = useIsFocused();
    const dimension = useWindowDimensions();
    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);


    useEffect(() => {

        async function apiCall() {
            let response = await axiosSecure.get(`/api/restaurant?codeName=${route.params.restaurantCodeName}`, false);

            if (response.data && response.data?.length > 0) {
                setIsOverloaded(response.data[0].isOverloaded)
            }
        }

        apiCall();
    }, [])

    useEffect(() => {
        if (isFocused) {
            if (Platform.OS === 'web' && !loaded && !dataSaver) {
                setInterval(() => {
                    setLoaded(!loaded);
                }, ORDER_SCREEN_LOADING_TIME);
            }
        }
    }, [])

    useEffect(() => {
        if (isFocused) {
            setTotalPrice((Math.round((orderItems.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0) + Number.EPSILON + serviceFee) * 100) / 100));
            setItemsPrice((Math.round((orderItems.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0) + Number.EPSILON) * 100) / 100));
        }
    }, [JSON.stringify(orderItems)]);

    useEffect(() => {
        if (isFocused) {
            setSavedMoney(Math.round((orderItems.reduce((sum, orderItem) => sum + (orderItem.savedMoney || 0), 0) + Number.EPSILON) * 100) / 100);
        }
    }, [JSON.stringify(orderItems)]);

    function handleActionsPress(orderItemId: string) {
        setOrderActionsOpen(orderItemId);
    }

    function handleDoneActionPress() {
        setOrderActionsOpen(null);
    }

    function handleAddActionPress(e: ShoppingCartItem) {
        dispatch(updateItemInShoppingCart(e));
    }

    function handleRemoveActionPress(e: ShoppingCartItem) {
        dispatch(removeItemFromShoppingCart(e));
    }

    function handleGoToRestaurant() {
        navigation.navigate("restaurant", { restaurantCodeName: restaurant.codeName, festivalCodeName: selectedFestivalCodeName as string });
    }

    function handleOrderItemPress(e: ShoppingCartItem) {
        // TODO: set filled item
        navigation.navigate('menuitem', {
            orderItemId: e.id,
            festivalCodeName: selectedFestivalCodeName as string,
            restaurantCodeName: e?.menuitem?.restaurantCodeName as string
        });
    }

    return (
        <>
            {!loaded &&
                <View
                    style={{
                        backgroundColor: colors.white,
                        marginBottom: 48,
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <ActivityIndicator animating={true} size={'large'} color={colors.ketchup} />
                </View>
            }

            {loaded &&
                <>
                    <View
                        style={styles.stickyActionContainer}
                    >
                        <View
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                gap: 16
                            }}
                        >
                            <View style={{
                                ...styles.row,
                                width: '100%'
                            }}>
                                <Text variant="paragraphBold" style={styles.paragraph16}>{t('orderScreen.tax.title')}</Text>
                                <Text variant="paragraphMedium" style={styles.paragraph14}>{totalPrice} €</Text>
                            </View>

                            <Button
                                mode="contained"
                                text={t('orderScreen.action')}
                                color="ketchup"
                                disabled={isOverloaded || (orderItems?.length || 0) === 0}
                                style={{
                                    width: '100%'
                                }}
                                onPress={() =>
                                    // TODO: upsale
                                    navigation.navigate('payment', { festivalCodeName: selectedFestivalCodeName as string })
                                }
                            />
                        </View>
                    </View>

                    <ScrollView
                        bounces={false} // iOS only - prevent drag whole view if it reach bottom
                        style={{
                            ...styles.container,
                            maxHeight: dimension.height - 120
                        }}
                    >
                        <IconButton
                            icon='arrows_outline_arrow_left'
                            color="white"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1
                            }}
                            onPress={() =>
                                navigation.goBack()
                            }
                        />

                        {restaurant && <Button
                            size="medium"
                            text={restaurant?.name}
                            color="black"
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 16,
                                zIndex: 1
                            }}
                            onPress={handleGoToRestaurant}
                        >
                        </Button>}

                        <View
                            style={{
                                marginTop: 32,
                                padding: 16,
                                gap: 24
                            }}>
                            <Text variant="headingBlack" style={styles.header}>{t('orderScreen.header')}</Text>

                            {isOverloaded && <View style={styles.warningContainer}>
                                <Text variant="headingBold" style={styles.paragraph16}>{t('orderScreen.restaurantOverloaded.title')}</Text>
                                <Text variant="paragraphRegular" style={styles.paragraph16}>{t('orderScreen.restaurantOverloaded.subtitle')}</Text>
                            </View>}
                        </View>

                        {orderItems.map((orderItem, index) => (
                            <OrderItem
                                key={orderItem.id}
                                styles={{
                                    marginHorizontal: 16,
                                    borderBottomColor: colors.greyLight,
                                    borderBottomWidth: 1
                                }}
                                order={orderItem}
                                showActions={orderActionsOpen === orderItem.id ? true : false}
                                onPress={() => handleOrderItemPress(orderItem)}
                                onActionPress={() => handleActionsPress(orderItem.id as string)}
                                onDoneActionPress={handleDoneActionPress}
                                onAddActionPress={handleAddActionPress}
                                onRemoveActionPress={handleRemoveActionPress}
                            />
                        ))}

                        <View style={{
                            padding: 16,
                            gap: 8
                        }}>
                            <View style={styles.row}>
                                <Text variant="paragraphBold" style={styles.paragraph14}>{t('orderScreen.footer.tax.title')}</Text>
                                <Text variant="paragraphMedium" style={styles.paragraph14}>{itemsPrice} €</Text>
                            </View>
                            <View style={styles.row}>
                                <Text variant="paragraphBold" style={styles.paragraph14}>{t('orderScreen.footer.fee.title')}</Text>
                                <Text variant="paragraphMedium" style={styles.paragraph14}>{serviceFee} €</Text>
                            </View>
                            {savedMoney > 0 && <View style={styles.row}>
                                <Text variant="paragraphBold" style={styles.paragraph14}>{t('orderScreen.footer.saved.title')}</Text>
                                <Text variant="paragraphMedium" style={styles.paragraph14}>-{savedMoney} €</Text>
                            </View>}
                            <Text variant="paragraphRegular" style={styles.infoText}>{t('orderScreen.footer.title')}</Text>
                        </View>
                    </ScrollView>
                </>}
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    stickyActionContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        gap: 16,
        backgroundColor: colors.white,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    warningContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 16,
        // height: 56,
        backgroundColor: colors.mustardLight,
        borderRadius: 4
    },
    container: {
        backgroundColor: colors.white,
        // padding: 16,
        // marginBottom: 48
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    header: {
        lineHeight: 35,
        fontSize: 28
    },
    infoText: {
        fontSize: 12,
        lineHeight: 18,
        color: colors.greyMedium
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
    paragraph14: {
        lineHeight: 21,
        fontSize: 14,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    divider: {
        height: 16,
        backgroundColor: colors.offWhite
    }
});
