
import React, { useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import { TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";


export interface RatingProps {
    style?: any;
    size?: 'small' | 'large';
    value: number;
}

export default function Rating({
    style,
    value,
    size = 'small',
    ...rest
}: RatingProps) {

    const { colors } = useTheme<AppTheme>();
    let dimension = size === 'large' ? 12 : 8;
    let radius = size === 'large' ? 4 : 2;

    const styles = makeStyles(colors);


    return (
        <View
            style={styles.container}
        >
            <View style={{
                backgroundColor: value >= 1 ? colors.green : '#D9D9D9',
                width: dimension,
                height: dimension,
                borderRadius: radius
            }}></View>
            <View style={{
                backgroundColor: value >= 2 ? colors.green : '#D9D9D9',
                width: dimension,
                height: dimension,
                borderRadius: radius
            }}></View>
            <View style={{
                backgroundColor: value >= 3 ? colors.green : '#D9D9D9',
                width: dimension,
                height: dimension,
                borderRadius: radius
            }}></View>
            <View style={{
                backgroundColor: value >= 4 ? colors.green : '#D9D9D9',
                width: dimension,
                height: dimension,
                borderRadius: radius
            }}></View>
            <View style={{
                backgroundColor: value === 5 ? colors.green : '#D9D9D9',
                width: dimension,
                height: dimension,
                borderRadius: radius
            }}></View>
        </View>
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 4
    },
    indicator: {
        backgroundColor: '#D9D9D9'
    },
    checkedIndicator: {
        backgroundColor: colors.green
    }
});
