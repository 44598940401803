import React, { useEffect, useState } from "react";
import { StyleSheet, Image, ScrollView, View, useWindowDimensions, Platform, FlatList, TouchableOpacity, ActivityIndicator, ImageBackground, Share, Linking } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Card, Text, useTheme } from "react-native-paper";
import { AppTheme } from "../../App";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import CategoryButton from "../components/CategoryButton/CategoryButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../core/store/store";
import RestaurantCard from "../components/RestaurantCard/RestaurantCard";
import { Restaurant } from "../core/interfaces/restaurant.interface";
import useAxiosSecure from "../hooks/useAxiosSecure";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import { Performance } from "../core/interfaces/performance.interface";
import { useTranslation } from "react-i18next";
import { addFavouriteRestaurants, removeFavouriteRestaurants } from "../core/store/features/ui/uiSlice";
import { dataSaverSelector, festivalByCodeNameSelector, restaurantByCuisineCodeNameSelector, selectedFestivalCodeNameSelector } from "../core/store/selectors";
import Button from "../components/Button/Button";
import axios, { MINIO_URL } from "../core/api/axios";
import dayjs, { Dayjs } from 'dayjs';
import Spotify from 'spotify-web-api-js';
import { sleep } from "../utils/utilities";
import { ARTIST_SCREEN_LOADING_TIME } from "../core/constants";
import { useIsFocused } from "@react-navigation/native";
import { SpotifyEmbed } from 'spotify-embed';
import { LinearGradient } from 'expo-linear-gradient';
import Icon from "../components/Icon/Icon";

const CLIENT_ID = "a8acb6917bc04fe2bd0c33b27f9f4073";
const CLIENT_SECRET = "c51a28e255154db9abcc9854ef4fe8a8";

export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "artist">) {

    const dispatch = useDispatch();

    const dimension = useWindowDimensions();
    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);
    const festival = useSelector((state) => festivalByCodeNameSelector(state, route?.params?.festivalCodeName));

    const [thumbnail, setThumbnail] = useState<string>("../../assets/images/festival_placeholder.png");
    const [performance, setPerformance] = useState<Performance>();
    const [loaded, setLoaded] = useState<boolean>(false);

    const dataSaver = useSelector(dataSaverSelector);
    const theme = useTheme<AppTheme>();

    const { t } = useTranslation();
    const isFocused = useIsFocused();
    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);


    async function apiCall() {

        if (Platform.OS === 'web' && !loaded && dataSaver) {
            await sleep(100);
        }

        let response = await axiosSecure.get(`/api/performance?id=${route.params.performanceId}`, false);
        setPerformance(response.data?.[0]);

        if (Platform.OS === 'web' && !loaded && dataSaver) {
            setLoaded(true);
        }
    }

    useEffect(() => {
        if (isFocused) {
            apiCall();
            if (Platform.OS === 'web' && !loaded && !dataSaver) {
                setInterval(() => {
                    setLoaded(!loaded);
                }, ARTIST_SCREEN_LOADING_TIME);
            }
        }

    }, [route?.params?.performanceId]);


    useEffect(() => {
        if (performance) {
            setThumbnail(`${MINIO_URL}/artists/${performance.artistId}`);
        }
    }, [performance])

    function getDateLabel() {
        let startDate = dayjs(performance?.startDatetime);
        let endDate = dayjs(performance?.endDatetime);
        return `${startDate.format('dddd DD.MM')} ${t('artistScreen.date.from')} ${startDate.format('HH:mm')} ${t('artistScreen.date.to')} ${endDate.format('HH:mm')}`;
    }

    function getHeader() {
        return `${t('artistScreen.thisIs')} ${performance?.artist?.name}`;
    }

    async function handleSharePress() {
        try {
            const result = await Share.share({
                title: getHeader(),
                // message: 'Check this!',
                url: window.location.href
            });
            // console.log(result)
            // if (result.action === Share.sharedAction) {
            //     if (result.activityType) {
            //         // shared with activity type of result.activityType
            //     } else {
            //         // shared
            //     }
            // } else if (result.action === Share.dismissedAction) {
            //     // dismissed
            // }
        } catch (error: any) {
            // alert(error.message);
        }
    }


    return (
        <>
            <IconButton
                icon='arrows_outline_arrow_left'
                color="white"
                style={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    zIndex: 1
                }}
                onPress={() =>
                    navigation.goBack()
                }
            />

            <IconButton
                icon='interaction_outline_share'
                color="white"
                style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1
                }}
                onPress={handleSharePress}
            />

            {!loaded &&
                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        marginBottom: 48,
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    {/* <Skeleton colors={colors} /> */}
                    <ActivityIndicator animating={true} size={'large'} color={theme.colors.ketchup} />
                </View>
            }

            {loaded && <ScrollView
                bounces={false} // iOS only - prevent drag whole view if it reach bottom
                style={{
                    ...styles.container,
                    height: Platform.OS === 'web' ? 10 : undefined
                }}
            >
                <Card mode="contained" style={{
                    ...styles.container
                }}>
                    <View>

                        <ImageBackground
                            style={
                                styles.cover
                            }
                            defaultSource={require("../../assets/images/festival_placeholder.png")}
                            source={{ uri: thumbnail }}
                        >
                            <View style={styles.headerBackgroud}>
                                <Text variant="headingBlack" style={styles.header}>{getHeader()}</Text>

                                <LinearGradient
                                    colors={[
                                        'transparent',
                                        '#000',
                                    ]}
                                    style={{
                                        width: '100%',
                                        height: 60
                                    }}
                                />
                            </View>

                        </ImageBackground>

                        {performance?.artist?.previewUrl && <View style={{
                            width: '100%',
                            height: 160,
                            backgroundColor: theme.colors.black,
                            padding: 8,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <SpotifyEmbed src={performance?.artist?.previewUrl} theme="dark" size="compact" />
                        </View>}
                    </View>


                    <View style={styles.card}>
                        <View style={{
                            padding: 16,
                            backgroundColor: colors.white,
                            gap: 16
                        }}>
                            <Text variant="headingBlack" style={styles.actionLabel}>{t('artistScreen.title')}</Text>

                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 16
                            }}>
                                <Icon
                                    name="general_outline_calendar"
                                    size={20}
                                    color="black"
                                />
                                <View style={{
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center'
                                }}>
                                    <Text variant="headingBold" style={styles.placeLabel}>{`${performance?.stage?.name} ${t('artistScreen.location.on')} ${performance?.stage?.festivalName}`}</Text>
                                    <Text variant="paragraphRegular" style={styles.dateLabel}>{getDateLabel()}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={{
                            padding: 16,
                            gap: 16,
                            backgroundColor: colors.white
                        }}>
                            <Text variant="headingBlack" style={styles.actionLabel}>{t('artistScreen.about')}</Text>
                            <Text variant="paragraphRegular" style={styles.description}>{performance?.artist?.description}</Text>
                        </View>
                    </View>

                    <View style={{
                        padding: 16,
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        gap: 8
                    }}>
                        {performance?.artist?.instagramLink && <Button
                            color="black"
                            // endIcon='Instagram'
                            mode="contained"
                            text={'Instagram'}
                            size="medium"
                            onPress={() => {
                                Linking.openURL(performance?.artist?.instagramLink as string)
                            }}
                        ></Button>}

                        {performance?.artist?.facebookLink && <Button
                            color="black"
                            // endIcon='Facebook'
                            mode="contained"
                            text={'Facebook'}
                            size="medium"
                            onPress={() => {
                                Linking.openURL(performance?.artist?.facebookLink as string)
                            }}
                        ></Button>}

                        {performance?.artist?.xLink && <Button
                            color="black"
                            // endIcon='Facebook'
                            mode="contained"
                            text={'X'}
                            size="medium"
                            onPress={() => {
                                Linking.openURL(performance?.artist?.xLink as string)
                            }}
                        ></Button>}

                        {performance?.artist?.appleMusicLink && <Button
                            color="black"
                            // endIcon='Facebook'
                            mode="contained"
                            text={'Apple Music'}
                            size="medium"
                            onPress={() => {
                                Linking.openURL(performance?.artist?.appleMusicLink as string)
                            }}
                        ></Button>}

                        {performance?.artist?.youtubeLink && <Button
                            color="black"
                            // endIcon='Facebook'
                            mode="contained"
                            text={'YouTube'}
                            size="medium"
                            onPress={() => {
                                Linking.openURL(performance?.artist?.youtubeLink as string)
                            }}
                        ></Button>}

                        {performance?.artist?.spotifyLink && <Button
                            color="black"
                            // endIcon='Facebook'
                            mode="contained"
                            text={'Spotify'}
                            size="medium"
                            onPress={() => {
                                Linking.openURL(performance?.artist?.spotifyLink as string)
                            }}
                        ></Button>}
                    </View>
                </Card>


            </ScrollView >}
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: colors.white,
        paddingTop: 0
    },
    card: {
        backgroundColor: colors.offWhite,
        gap: 16
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    actionLabel: {
        lineHeight: 30,
        fontSize: 24
    },
    placeLabel: {
        lineHeight: 24,
        fontSize: 16
    },
    dateLabel: {
        lineHeight: 21,
        fontSize: 14,
        color: colors.greyMedium
    },
    description: {
        lineHeight: 24,
        fontSize: 16,
        color: colors.black
    },
    headerBackgroud: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        position: 'absolute',
        zIndex: 1,
        bottom: 0
    },
    header: {
        lineHeight: 35,
        fontSize: 28,
        color: colors.white,
        left: 16,
        position: 'absolute',
        zIndex: 1
    },
    cover: {
        width: '100%',
        height: 240,
        paddingTop: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
});