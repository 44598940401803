import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Order } from '../../../interfaces/order.interface';
import { OrderPayment } from '../../../interfaces/order-payment.interface';


export const currentOrderSlice = createSlice({
    name: 'currentOrder',
    initialState: {
        paymentAmount: 0,
        currentOrder: null as Order | null,
        currentOrderPayment: null as OrderPayment | null
    },
    reducers: {
        setCurrentOrder: (state, action: PayloadAction<any>) => {
            state.currentOrder = action.payload;
        },
        setCurrentOrderPayment: (state, action: PayloadAction<any>) => {
            state.currentOrderPayment = action.payload;
        },
        setPaymentAmount: (state, action: PayloadAction<any>) => {
            state.paymentAmount = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setCurrentOrder,
    setCurrentOrderPayment,
    setPaymentAmount
} = currentOrderSlice.actions

export default { currentOrderReducer: currentOrderSlice.reducer }
