import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosInterceptorManager } from 'axios';
import { showLoading } from '../store/features/loading/loadingSlice';
import { displayNotification } from '../store/features/notification/notificationSlice';
import { store } from '../store/store';
import { ExceptionMap } from './exceptionsMapping';
import { newGuid } from '../../utils/utilities';


// generate unique session id
window.senderId = newGuid();

// axios with overriden methods to show loading
export class ApiSecure {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                'x-sender': window.senderId
            },
            withCredentials: true
        });
    }

    get interceptors(): {
        request: AxiosInterceptorManager<AxiosRequestConfig>;
        response: AxiosInterceptorManager<AxiosResponse>;
    } {
        return this.axios.interceptors;
    }

    request<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.axios(url, config);
    }

    uploadImage<T = any, R = AxiosResponse<T>, D = any>(url: string, bucketName: string, objectName: string, file: File, data?: D, displayLoading?: boolean): Promise<R>;
    uploadImage<T = any, R = AxiosResponse<T>, D = any>(url: string, bucketName: string, objectName: string, file: File, data?: D, displayLoading?: boolean, config?: AxiosRequestConfig<D>): Promise<R> {

        if (displayLoading !== undefined && displayLoading) {
            store.dispatch(showLoading({
                showLoading: true
            }));
        }

        var bodyFormData = new FormData();
        bodyFormData.append('bucket_name', bucketName);
        bodyFormData.append('object_name', objectName);
        bodyFormData.append('image', file);

        return axios({
            method: "post",
            baseURL: BASE_URL,
            url,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                'x-sender': window.senderId
            },
            withCredentials: true
        }).then((res: any) => {

            if (displayLoading !== undefined && displayLoading) {
                store.dispatch(showLoading({
                    showLoading: false
                }));
            }

            return res;
        });
    }

    get<T = any, R = AxiosResponse<T>, D = any>(url: string, displayLoading?: boolean): Promise<R>;
    get<T = any, R = AxiosResponse<T>, D = any>(url: string, displayLoading?: boolean, config?: AxiosRequestConfig<D>): Promise<R> {

        if (displayLoading !== undefined && displayLoading) {
            store.dispatch(showLoading({
                showLoading: true
            }));
        }

        return this.axios.get(url, config).then((res: any) => {
            if (displayLoading !== undefined && displayLoading) {
                store.dispatch(showLoading({
                    showLoading: false
                }));
            }
            return res;
        });
    }

    delete<T = any, R = AxiosResponse<T>, D = any>(url: string, displayLoading?: boolean): Promise<R>;
    delete<T = any, R = AxiosResponse<T>, D = any>(url: string, displayLoading: boolean, config?: AxiosRequestConfig<D>): Promise<R> {

        if (displayLoading !== undefined && displayLoading) {
            store.dispatch(showLoading({
                showLoading: true
            }));
        }

        return this.axios.delete(url, config).then((res: any) => {
            if (displayLoading !== undefined && displayLoading) {
                store.dispatch(showLoading({
                    showLoading: false
                }));
            }
            return res;
        });
    }

    post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, displayLoading?: boolean): Promise<R>;
    post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, displayLoading?: boolean, config?: AxiosRequestConfig<D>): Promise<R> {

        if (displayLoading !== undefined && displayLoading) {
            store.dispatch(showLoading({
                showLoading: true
            }));
        }

        return this.axios.post(url, data, config).then((res: any) => {

            if (displayLoading !== undefined && displayLoading) {
                store.dispatch(showLoading({
                    showLoading: false
                }));
            }

            return res;
        });
    }

    put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, displayLoading?: boolean): Promise<R>;
    put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, displayLoading?: boolean, config?: AxiosRequestConfig<D>): Promise<R> {

        if (displayLoading !== undefined && displayLoading) {
            store.dispatch(showLoading({
                showLoading: true
            }));
        }

        return this.axios.put(url, data, config).then((res: any) => {

            if (displayLoading !== undefined && displayLoading) {
                store.dispatch(showLoading({
                    showLoading: false
                }));
            }

            return res;
        });
    }
}

export const BASE_URL = 'https://app.bramble.at';
export const MINIO_URL = 'https://app.bramble.at/images';
// export const BASE_URL = 'http://localhost:8000';
// export const MINIO_URL = 'http://localhost:9000';

const api = axios.create({
    baseURL: BASE_URL
});

export const apiSecure = new ApiSecure();

api.interceptors.response.use(
    function (response: any) {
        return response;
    },
    function (error: any) {

        let message;

        if (!error.response) {
            message = "Chyba pri pripájaní k serveru. Skontrolujte svoje internetové pripojenie.";
        } else {
            message = error.response.data.detail.traceback; // ExceptionMap[error.response.data.detail.errorCode] || "Neznáma chyba.";
        }

        store.dispatch(displayNotification({
            message: message,
            severity: "error"
        }));

        return Promise.reject(error);
    }
);

export default api;