import React, { useEffect, useState } from "react";
import { StyleSheet, ScrollView, View, useWindowDimensions, Platform } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, useTheme } from "react-native-paper";
import { AppTheme } from "../../App";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import CategoryButton from "../components/CategoryButton/CategoryButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../core/store/store";
import RestaurantCard from "../components/RestaurantCard/RestaurantCard";
import { Restaurant } from "../core/interfaces/restaurant.interface";
import useAxiosSecure from "../hooks/useAxiosSecure";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import MenuItemSmall from "../components/MenuItemSmall/MenuItemSmall";
import { useTranslation } from "react-i18next";
import { addFavouriteRestaurants, removeFavouriteRestaurants } from "../core/store/features/ui/uiSlice";
import { dataSaverSelector, restaurantByCuisineCodeNameSelector, selectedFestivalCodeNameSelector } from "../core/store/selectors";
import Button from "../components/Button/Button";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "cuisine">) {

    const dispatch = useDispatch();
    const [filteredMenuItems, setFilteredMenuItems] = useState<Menuitem[]>([]);
    const [selectedCuisine, setSelectedCuisine] = useState<any>(route.params.cuisine);
    const [selectedPrice, setSelectedPrice] = useState<number | null>(null);

    const dimension = useWindowDimensions();
    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);
    const cuisines = useSelector((state: RootState) => state.cuisine.cuisines);

    const restaurants = useSelector((state) => restaurantByCuisineCodeNameSelector(state, selectedCuisine));
    const [allMenuitems, setAllMenuitems] = useState<Menuitem[]>([]);

    const dataSaver = useSelector(dataSaverSelector);

    const { t } = useTranslation();
    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);

    async function apiCall() {
        let response = await axiosSecure.get(`/api/festival/${selectedFestivalCodeName}/menuitems`, true);
        setAllMenuitems(response.data);
        setFilteredMenuItems(_filterMenuItems(route.params.cuisine, null, response.data));
    }

    useEffect(() => {
        apiCall();
    }, []);


    function _filterMenuItems(cuisine: string, price: number | null, menuitems: Menuitem[]) {
        // let menuitems = restaurants?.flatMap(r => r.)

        // categories?.filter(c => c?.name?.toLocaleLowerCase()
        //     ?.includes(cuisine?.toLocaleLowerCase())
        //     || c?.menuitems?.map((c: any) => c?.name)
        //         ?.some((c: string) => c?.toLocaleLowerCase()?.includes(cuisine?.toLocaleLowerCase())))
        //     .flatMap(c => c?.menuitems)

        if (price != null) {
            menuitems = menuitems.filter(m => m.price <= price);
        }

        return menuitems;
    }

    function handRestaurantPress(restaurant: Restaurant) {
        navigation.navigate('restaurant', { restaurantCodeName: restaurant.codeName, festivalCodeName: selectedFestivalCodeName as string });
    }

    function handleOnFavouritePress(restaurant: Restaurant) {
        if (restaurant._isFavourite) {
            dispatch(removeFavouriteRestaurants([restaurant.id]));
        } else {
            dispatch(addFavouriteRestaurants([restaurant.id]));
        }
    }

    function handlePricePress(event: any) {
        let price = event;
        if (event === selectedPrice) {
            price = null;
        }
        setSelectedPrice(price);
        setFilteredMenuItems(_filterMenuItems(selectedCuisine, price, allMenuitems));
    }

    function handleCuisineItemPress(event: any) {
        setSelectedCuisine(event);
        setFilteredMenuItems(_filterMenuItems(event, selectedPrice, allMenuitems));
    }

    function handleMenuItemPress(restaurant: Restaurant, menuitem: Menuitem) {
        navigation.navigate('restaurant', {
            restaurantCodeName: restaurant.codeName,
            festivalCodeName: selectedFestivalCodeName as string,
            menuitemId: menuitem?.id
        });
    }

    return (
        <>
            <View style={{ ...styles.appBar }}>
                <IconButton
                    icon='arrows_outline_arrow_left'
                    color="white"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1
                    }}
                    onPress={() =>
                        navigation.goBack()
                    }
                />
                <Text variant="headingBlack" style={styles.header}>{t("categoryScreen.header")}</Text>
            </View>

            <ScrollView
                bounces={false} // iOS only - prevent drag whole view if it reach bottom
                style={{
                    ...styles.container,
                    height: Platform.OS === 'web' ? 10 : undefined
                }}
            >
                <ScrollView horizontal
                    style={{
                        marginTop: 16,
                        paddingHorizontal: 16
                    }}
                    contentContainerStyle={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // paddingHorizontal: 16,
                        gap: 16
                    }}
                >
                    <Button
                        color={selectedPrice === 10 ? "black" : "greyLight"}
                        contentStyle={{
                            color: selectedPrice === 10 ? "white" : "black"
                        }}
                        mode="contained"
                        text={t("categoryScreen.upTo10")}
                        size="medium"
                        style={{
                            width: (dimension.width - 64) / 3
                        }}
                        touchableStyle={{
                            borderRadius: 4
                        }}
                        onPress={() => { handlePricePress(10) }}
                    ></Button>

                    <Button
                        color={selectedPrice === 15 ? "black" : "greyLight"}
                        contentStyle={{
                            color: selectedPrice === 15 ? "white" : "black"
                        }}
                        mode="contained"
                        text={t("categoryScreen.upTo15")}
                        size="medium"
                        style={{
                            width: (dimension.width - 64) / 3
                        }}
                        touchableStyle={{
                            borderRadius: 4
                        }}
                        onPress={() => { handlePricePress(15) }}
                    ></Button>

                    <Button
                        color={selectedPrice === 30 ? "black" : "greyLight"}
                        contentStyle={{
                            color: selectedPrice === 30 ? "white" : "black"
                        }}
                        mode="contained"
                        text={t("categoryScreen.upTo30")}
                        size="medium"
                        style={{
                            width: (dimension.width - 64) / 3
                        }}
                        touchableStyle={{
                            borderRadius: 4
                        }}
                        onPress={() => { handlePricePress(30) }}
                    ></Button>
                </ScrollView>

                <ScrollView horizontal
                    style={{
                        padding: 16
                    }}
                    contentContainerStyle={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 8
                    }}
                >

                    {cuisines?.map((cuisine, index) => <Button
                        key={cuisine.id}
                        color={selectedCuisine === cuisine.codeName ? "black" : "greyLight"}
                        contentStyle={{
                            color: selectedCuisine === cuisine.codeName ? "white" : "black"
                        }}
                        mode="contained"
                        text={t(`festivalScreen.${cuisine.codeName}`)}
                        size="medium"
                        onPress={() => handleCuisineItemPress(cuisine.codeName)}
                    ></Button>)}

                </ScrollView>

                <View
                    style={styles.restaurantContainer}
                >
                    {restaurants.map((restaurant, index) => (
                        <View
                            key={restaurant.id}
                            style={{
                                borderBottomColor: index !== restaurants.length - 1 ? colors.greyLight : 'unset',
                                borderBottomWidth: index !== restaurants.length - 1 ? 1 : 0
                            }}
                        >
                            <RestaurantCard
                                saveDataMode={dataSaver}
                                festivalCodeName={selectedFestivalCodeName as string}
                                isPopular={restaurant.isPopular}
                                restaurant={restaurant}
                                onPress={() => handRestaurantPress(restaurant)}
                                onFavouritePress={() => handleOnFavouritePress(restaurant)}
                            />

                            <ScrollView
                                horizontal
                                contentContainerStyle={{
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {filteredMenuItems?.filter(m => m.restaurantId === restaurant?.id).map((menuitem, index) => (

                                    <MenuItemSmall
                                        key={menuitem.id}
                                        onPress={() => handleMenuItemPress(restaurant, menuitem)}
                                        menuitem={menuitem}
                                        styles={{
                                            paddingLeft: index === 0 ? 8 : 16
                                        }}
                                    />
                                ))}
                            </ScrollView>

                        </View>
                    ))}
                </View>
            </ScrollView >
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    appBar: {
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.white
    },
    container: {
        backgroundColor: colors.white
    },
    restaurantContainer: {
        padding: 8
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    header: {
        lineHeight: 27,
        fontSize: 18
    }
});