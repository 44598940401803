import * as React from 'react';
import { StyleSheet } from "react-native";
import { Portal, Text, useTheme, Dialog, Button } from 'react-native-paper';
import { AppTheme } from '../../../App';
import { useDispatch } from 'react-redux';
import useDialog from '../../hooks/useDialog';
import { closeDialog } from '../../core/store/features/dialog/dialogSlice';
import { capitalize } from '../../utils/utilities';


export default function ConfirmDialog() {

    const { dismissable, style, visible, title, content, yesActionLabel, noActionLabel, showNo, onNo, onYes } = useDialog();
    const dispatch = useDispatch();

    const theme = useTheme<AppTheme>();
    const styles = makeStyles(theme.colors);

    let _onYes: any = "on" + capitalize('ketchup').toString();
    let backgroundColorYes = theme.colors['ketchup'];
    let textColorYes: string = theme.colors[_onYes as keyof object];
    
    let _onNo: any = "on" + capitalize('black').toString();
    let backgroundColorNo = theme.colors['black'];
    let textColorNo: string = theme.colors[_onNo as keyof object];

    
    function handleYesAction() {
        onYes?.();
        dispatch(closeDialog())
    }
    
    function handleNoAction() {
        onNo?.();
        dispatch(closeDialog());
    }


    return (
        <Portal>
            <Dialog
                visible={visible as boolean}
                onDismiss={handleNoAction}
                dismissable={dismissable as boolean}
                theme={theme}
                style={{
                    ...styles.container,
                    ...style
                }}
            >
                <Dialog.Title>{title}</Dialog.Title>
                <Dialog.Content>
                    <Text>{content}</Text>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button
                        mode='contained'
                        labelStyle={{
                            color: textColorYes
                        }}
                        style={{
                            backgroundColor: backgroundColorYes,
                            ...styles.button
                        }}
                        onPress={handleYesAction}
                    >
                        {yesActionLabel}
                    </Button>
                    {showNo && <Button
                        mode='contained'                        
                        labelStyle={{
                            color: textColorNo
                        }}
                        style={{
                            backgroundColor: backgroundColorNo,
                            ...styles.button
                        }}
                        onPress={handleNoAction}
                    >
                        {noActionLabel}
                    </Button>}
                </Dialog.Actions>
            </Dialog>
        </Portal>
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        borderRadius: 0,
        backgroundColor: colors.white
    },
    button: {
        borderRadius: 48,
        fontSize: 16,
        lineHeight: 24,
        borderWidth: 0,
        paddingHorizontal: 16,
        fontWeight: "500" as "500"
    }
});