
import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { ShoppingCartItem } from "../../core/store/features/shoppingCart/shoppingCartSlice";
import Icon from "../Icon/Icon";


export interface OrderItemProps {
    order: ShoppingCartItem;
    styles?: any;
    showActions?: boolean;
    saveDataMode?: boolean;
    onPress?: () => void;
    onActionPress?: () => void;
    onDoneActionPress?: () => void;
    onAddActionPress?: (event: ShoppingCartItem) => void;
    onRemoveActionPress?: (event: ShoppingCartItem) => void;
}

export default function OrderItem(props: OrderItemProps) {

    const [actions, setActions] = useState<boolean>(props?.showActions || false);
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);
    
    var extraMenuItemsText = props.order.extraMenuitems.map(extraMenuItem => extraMenuItem.name).concat(props.order.note || "").filter(Boolean).join(" + ");
    
    if (extraMenuItemsText) {
        extraMenuItemsText = "+ " + extraMenuItemsText;
    }

    function handleSetActionsPress() {
        setActions(!actions);
        props?.onActionPress?.();
    }

    function handleDoneActionsPress() {
        setActions(false);
        props?.onDoneActionPress?.();
    }

    function handleAddActionPress() {
        props?.onAddActionPress?.({ ...props.order, amout: (props.order.amout as number) + 1 });
    }

    function handleRemoveActionPress() {
        props?.onRemoveActionPress?.(props.order);
    }

    useEffect(() => {
        setActions(props?.showActions || false)
    }, [props?.showActions])

    return (
        <TouchableRipple
            onPress={props?.onPress}
            style={{
                ...styles.container,
                ...props.styles
            }}
        >
            <>
                <View style={styles.actionsContainer}>
                    <TouchableRipple style={{
                        ...styles.amout,
                        borderTopRightRadius: actions ? 0 : 4,
                        borderBottomRightRadius: actions ? 0 : 4
                    }} onPress={handleSetActionsPress}>
                        <Text variant="paragraphRegular" style={styles.amoutText}>{props.order.amout}</Text>
                    </TouchableRipple>

                    {actions && <View style={styles.actions}>
                        <TouchableRipple style={{
                            ...styles.amout,
                            borderRadius: 0
                        }} onPress={handleRemoveActionPress}>
                            <Icon
                                name={props.order.amout === 1 ? "ecommerce_outline_trash" : "ecommerce_fill_minus"}
                                color="black"
                                size={20}
                            />
                        </TouchableRipple>

                        <TouchableRipple style={{
                            ...styles.amout,
                            borderRadius: 0
                        }} onPress={handleAddActionPress}>
                            <Icon
                                name="ecommerce_fill_plus"
                                color="black"
                                size={20}
                            />
                        </TouchableRipple>

                        <TouchableRipple style={{
                            ...styles.amout,
                            backgroundColor: colors.greenLight,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                        }} onPress={() => handleDoneActionsPress()}>
                            <Icon
                                name="system__status_fill_checkmark"
                                color="black"
                                size={20}
                            />
                        </TouchableRipple>
                    </View>}

                    <View style={styles.orderDetail}>
                        <View>
                            <Text variant="headingBold" numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
                                {props.order.menuitem.name}
                            </Text>

                            <Text
                                variant="paragraphRegular"
                                style={styles.extraMenuItems}>{extraMenuItemsText}
                            </Text>

                            {/* {extraMenuItemsText && <Text
                                variant="paragraphRegular"
                                style={styles.extraMenuItems}>+ {extraMenuItemsText}
                            </Text>} */}
                        </View>

                    </View>
                </View>
                <Text variant="paragraphMedium" style={styles.price}>{props.order.totalPrice} €</Text>
            </>
        </TouchableRipple >
    );
}



const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        minHeight: 80,
        backgroundColor: colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    actionsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16
    },
    actions: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        left: 48,
        zIndex: 1
    },
    title: {
        fontSize: 16,
        lineHeight: 26
    },
    price: {
        fontSize: 14,
        lineHeight: 21
    },
    name: {
        fontSize: 16,
        lineHeight: 24
    },
    amout: {
        backgroundColor: colors.white,
        borderColor: colors.greyLight,
        borderWidth: 1,
        borderRadius: 4,
        width: 48,
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    amoutText: {
        fontSize: 16,
        lineHeight: 24
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    orderDetail: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8
    },
    extraMenuItems: {
        fontSize: 14,
        lineHeight: 21,
        color: colors.greyMedium
    }
});
