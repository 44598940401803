
import React from "react";
import { GestureResponderEvent, StyleSheet, View } from "react-native";
import { Chip as _Chip, Button as _Button, Text, useTheme, TouchableRipple, Surface } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { BrambleColor } from "../../types/theme";
import { capitalize, contrastColor } from "../../utils/utilities";
import Icon from "../Icon/Icon";
import { default as _color } from 'color';


export type TagMode =
    | 'outlined'
    | 'contained';

export interface TagProps {
    mode?: TagMode;
    color?: BrambleColor;
    size?: 'small' | 'medium' | 'large';
    text?: string;
    closable?: boolean;
    icon?: string;
    style?: any;
    disabled?: boolean;
    accessible?: boolean | undefined;
    /**
     * Accessibility label for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityLabel?: string;
    /**
     * Accessibility hint for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityHint?: string;
    /**
     * Function to execute on press.
     */
    onPress?: (e: GestureResponderEvent) => void;
    /**
     * Function to execute as soon as the touchable element is pressed and invoked even before onPress.
     */
    onPressIn?: (e: GestureResponderEvent) => void;
    /**
     * Function to execute as soon as the touch is released even before onPress.
     */
    onPressOut?: (e: GestureResponderEvent) => void;
    /**
     * Function to execute on long press.
     */
    onLongPress?: (e: GestureResponderEvent) => void;
    /**
     * The number of milliseconds a user must touch the element before executing `onLongPress`.
     */
    delayLongPress?: number;
    onClose?: () => void;
    /**
     * testID to be used on tests.
     */
    testID?: string;
}

export default function Tag({
    disabled,
    accessibilityLabel,
    accessibilityHint,
    onPress,
    onPressIn,
    onPressOut,
    onLongPress,
    onClose,
    delayLongPress,
    style,
    icon,
    text,
    testID = 'tag',
    size = 'large',
    color = 'ketchup',
    mode = 'contained',
    accessible,
    ...rest
}: TagProps) {

    const theme = useTheme<AppTheme>();

    let _on: any = "on" + capitalize(color).toString();

    let backgroundColor: string = theme.colors[color];
    let textColor: string = theme.colors[_on as keyof object];
    let height = size === 'large' ? 40 : (size === 'medium' ? 32 : 24);


    let paddingVertical = size === 'large' ? 10 : (size === 'medium' ? 8 : 6);
    let paddingHorizontal = size === 'large' ? 10 : (size === 'medium' ? 12 : 8);

    let marginHorizontal = size === 'large' ? 10 : (size === 'medium' ? 12 : 8);
    let iconSize = size === 'large' ? 20 : (size === 'medium' ? 16 : 12);
    let fontSize = size === 'large' ? 16 : (size === 'medium' ? 14 : 12);
    

    if (disabled) {
        backgroundColor = theme.colors["disabled"];
        textColor = theme.colors["onDisabled"];
    }

    const textStyle = {
        lineHeight: fontSize,
        fontSize: fontSize,
        fontWeight: "500" as "500",
        color: mode === 'contained' ? textColor : backgroundColor
    };

    const rippleColor = _color(contrastColor(mode === 'contained' ? backgroundColor : 'white', 'black', 'white')).alpha(0.12).rgb().string();

    const handlePressIn = (e: GestureResponderEvent) => {
        onPressIn?.(e);
    };

    const handlePressOut = (e: GestureResponderEvent) => {
        onPressOut?.(e);
    };

    return (
        <Surface
            {...rest}
            testID={`${testID}-container`}
            elevation={0}
        >
            <TouchableRipple
                borderless
                onPress={onPress}
                onLongPress={onLongPress}
                onPressIn={handlePressIn}
                onPressOut={handlePressOut}
                delayLongPress={delayLongPress}
                accessibilityLabel={accessibilityLabel}
                accessibilityHint={accessibilityHint}
                accessibilityRole="button"
                accessibilityState={{ disabled }}
                accessible={accessible}
                disabled={disabled}
                rippleColor={rippleColor}
                testID={testID}
                theme={theme}
                style={{
                    color: mode === 'contained' ? textColor : backgroundColor,
                    backgroundColor: mode === 'contained' ? backgroundColor : 'white',
                    borderColor: disabled ? textColor : mode === "outlined" ? backgroundColor : undefined,
                    height,
                    paddingVertical,
                    paddingHorizontal,
                    borderRadius: 4,
                    borderWidth: disabled ? 2 : mode === "outlined" ? 2 : undefined,
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...style
                }}
            >
                <View
                    style={styles.content}
                >
                    {icon && <Icon name={icon} size={iconSize} color={mode === 'outlined' ? backgroundColor : textColor} />}

                    <View style={{ flex: 1, marginRight: marginHorizontal }}>
                    </View>

                    <Text style={textStyle}>
                        {text}
                    </Text>

                    <View style={{ flex: 1, marginRight: marginHorizontal }}></View>
                    {onClose && <Icon name='Close' size={iconSize} onPress={onClose} color={mode === 'outlined' ? backgroundColor : textColor} />}
                </View>
            </TouchableRipple >
        </Surface >
    );
}

const styles = StyleSheet.create({
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
});
