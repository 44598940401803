
import React, { useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import { Chip as _Chip, Button as _Button, useTheme, TextInput } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { default as _color } from 'color';


export interface SearchFieldProps {
    size?: 'small' | 'large';
    label?: string;
    error?: boolean;
    value?: string;
    autoFocus?: boolean;
    style?: any;
    disabled?: boolean;
    onChangeText?: (text: string) => void;
    onPress?: (e: any) => void;
}

export default function SearchField({
    disabled,
    style,
    label,
    value,
    autoFocus,
    onChangeText,
    onPress,
    error,
    size = 'large',
    ...rest
}: SearchFieldProps) {


    const [isFocused, setIsFocused] = useState<boolean>(false);

    const theme = useTheme<AppTheme>();

    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);

    let height = 48;


    let paddingVertical = 12;
    let paddingHorizontal = 16;

    let iconSize = 24;
    let fontSize = 16;

    const textStyle = {
        fontSize: fontSize,
        fontWeight: "400" as "400"
    };

    return (
        <TextInput
            mode="flat"
            right={<TextInput.Icon
                icon="magnify"
                color={theme.colors.black}
                size={iconSize}
                onPress={onPress}
            />}
            autoFocus={autoFocus}
            theme={{ roundness: 4 }}
            contentStyle={{
                height,
                paddingTop: paddingVertical,
                paddingBottom: paddingVertical,
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal
            }}
            disabled={disabled}
            placeholder={label}
            placeholderTextColor={theme.colors.grey}
            underlineStyle={{
                display: "none"
            }}
            style={{
                ...(disabled ? styles.disabledContainer : isFocused ? styles.focusedContainer : styles.container),
                height,
                borderRadius: 4,
                // borderWidth: 2,
                borderColor: colors.black,
                ...textStyle,
                ...style
            }}
            value={value}
            onChangeText={onChangeText}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        />
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: colors.greyLight,
    },
    focusedContainer: {
        backgroundColor: colors.white,
    },
    disabledContainer: {
        backgroundColor: colors.disabled,
    }
});
