import * as React from 'react';
import BottomDrawer, {
    BottomDrawerMethods,
} from 'react-native-animated-bottom-drawer';

import { useDispatch } from 'react-redux';
import useBottomSheet from '../../hooks/useBottomSheet';
import { useEffect, useRef } from 'react';
import { setBottomSheetOpen } from '../../core/store/features/bottomSheet/bottomSheetSlice';


export default function BottomSheet() {

    const { isOpen, content, closeOnBackdropPress, closeOnDragDown, closeOnPressBack, initialHeight } = useBottomSheet();
    const dispatch = useDispatch();
    const bottomDrawerRef = useRef<BottomDrawerMethods>(null);


    useEffect(() => {
        if (isOpen) {
            bottomDrawerRef.current?.open();
        } else {
            bottomDrawerRef.current?.close();
        }
    }, [isOpen]);

    function handleBottomSheetOnClose() {
        dispatch(setBottomSheetOpen(false));
    }

    return (
        <BottomDrawer
            customStyles={{
                handleContainer: {
                    height: 0
                },
                handle: {
                    display: "none",
                },
                container: {
                    borderRadius: 0
                }
            }}
            ref={bottomDrawerRef}
            onClose={handleBottomSheetOnClose}
            initialHeight={initialHeight}
            onBackPress={handleBottomSheetOnClose}
            closeOnDragDown={closeOnDragDown}
            closeOnBackdropPress={closeOnBackdropPress}
            closeOnPressBack={closeOnPressBack}
            gestureMode={"none"}
        >
            {content}
        </BottomDrawer >
    );
}