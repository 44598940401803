
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, TouchableHighlight } from "react-native";
import { Avatar, Button, Card, Text, TouchableRipple, useTheme } from 'react-native-paper';
import Tag from "../Tag/Tag";
import Badge from "../Badge/Badge";
import Icon from "../Icon/Icon";
import { AppTheme } from "../../../App";
import { Restaurant } from "../../core/interfaces/restaurant.interface";
import IconButton from "../IconButton/IconButton";
import { MINIO_URL } from "../../core/api/axios";
import { useTranslation } from "react-i18next";
import Rating from "../Rating/Rating";


export interface RestaurantCardProps {
    style?: any;
    saveDataMode?: boolean;
    restaurant: Restaurant;
    festivalCodeName?: string;
    isPopular?: boolean;
    onPress?: () => void;
    onFavouritePress?: () => void;
}

export default function RestaurantCard(props: RestaurantCardProps) {

    const { colors } = useTheme<AppTheme>();

    const [thumbnail, setThumbnail] = useState<string>(`../../../assets/images/restaurant_placeholder.png`);

    const styles = makeStyles(colors);
    const { t } = useTranslation();

    // let containerStyle = (props?.restaurant?.isOpen ? styles.container : styles.closedContainer);
    let containerStyle = styles.container;

    useEffect(() => {
        if (props.restaurant) {
            setThumbnail(`${MINIO_URL}/restaurants/${props?.restaurant?.id}`);
        }
    }, [props.restaurant])

    function getLocation() {
        if (!props?.festivalCodeName) {
            return `${props?.restaurant?.state} • ${props?.restaurant?.street}`;
        }

        let location = props?.restaurant?.festivalRestaurants?.find((fr: any) => fr.festivalCodeName === props?.festivalCodeName);
        if (location) {
            return `${location.location}`;
        } else {
            return `${props?.restaurant?.state} • ${props?.restaurant?.street}`;
        }
    }

    return (
        <View>

            {/* {!props?.restaurant?.isOpen &&
                <View style={styles.closedInfoContainer}>
                    <Text style={styles.closedInfoText}
                        variant="paragraphBlack"
                    >
                        {t('festivalScreen.restaurant.isClosed')}
                    </Text>
                </View>
            } */}

            <TouchableRipple
                onPress={() => props?.onPress?.()}
                style={{
                    ...containerStyle,
                    ...props.style
                }}
            >
                <>
                    {!props?.saveDataMode && <View>

                        {props?.isPopular && <Badge
                            text={t('festivalScreen.restaurant.isPopular')}
                            color="green"
                            variant="secondary"
                            size="small"
                            dimension="rounded"
                            style={styles.popularBadge}
                        />}

                        <IconButton
                            size='small'
                            icon={props?.restaurant?._isFavourite ? "interaction_fill_heart" : "interaction_outline_heart"}
                            iconSize={20}
                            color={"white"}
                            iconColor={props?.restaurant?._isFavourite ? "ketchup" : "black"}
                            style={styles.favouriteIcon}
                            onPress={() => props?.onFavouritePress?.()}
                        />

                        <Image
                            style={styles.cover}
                            source={{ uri: thumbnail }}
                            defaultSource={require("../../../assets/images/restaurant_placeholder.png")}
                        />
                    </View>}

                    {props?.saveDataMode && <View
                        style={styles.saveDataCover}
                    >
                        {props?.isPopular && <Badge
                            text={t('festivalScreen.restaurant.isPopular')}
                            color="green"
                            variant="secondary"
                            size="small"
                            dimension="rounded"
                        />}

                        <IconButton
                            size='small'
                            icon='interaction_outline_heart'
                            color={props?.restaurant?._isFavourite ? "ketchup" : "white"}
                            style={styles.favouriteIcon}
                            onPress={() => props?.onFavouritePress?.()}
                        />
                    </View>}

                    <View style={{ paddingLeft: 0, paddingRight: 0 }}>

                        <View
                            style={styles.tagsContainer}
                        >
                            <View
                                style={styles.tags}
                            >
                                {props.restaurant.cuisines.map((cuisine: any, index: number) => (
                                    <Tag
                                        key={cuisine.id}
                                        mode="contained"
                                        color="black"
                                        size="small"
                                        text={cuisine.name}
                                    />
                                ))}
                            </View>

                        </View>

                        <View>
                            <Text
                                style={styles.restaurantName}
                                variant="headingBlack"
                            >
                                {props.restaurant.name}
                            </Text>

                            <View style={styles.row}>
                                <Text style={styles.locationText} variant="paragraphRegular">
                                    {getLocation()}
                                </Text>

                                <Text style={styles.locationText} variant="paragraphRegular">
                                    |
                                </Text>

                                <Rating
                                    size="small"
                                    value={props.restaurant.rating}
                                />
                            </View>
                        </View>

                    </View>
                </>
            </TouchableRipple>
        </View>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 0,
        margin: 8
    },
    closedContainer: {
        backgroundColor: 'white',
        borderRadius: 0,
        margin: 8,
        opacity: 0.4
    },
    closedInfoContainer: {
        position: "absolute",
        height: '100%',
        width: '100%',
        zIndex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    closedInfoText: {
        position: "absolute",
        top: 78,
        fontSize: 18,
        lineHeight: 27
    },
    tagsContainer: {
        marginTop: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tags: {
        flexDirection: "row",
        flexWrap: "wrap",
        maxWidth: "75%",
        gap: 8
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 4
    },
    locationIcon: {
        height: 15,
        width: 15
    },
    infoContainer: {
        marginTop: 16
    },
    restaurantName: {
        fontWeight: '900',
        fontSize: 18,
        lineHeight: 27
    },
    locationText: {
        fontSize: 12,
        lineHeight: 18,
        color: colors.greyDark
    },
    cover: {
        width: '100%',
        borderRadius: 4,
        height: 150
    },
    saveDataCover: {
        width: '100%',
        height: 48,
        backgroundColor: colors.offWhite,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
        justifyContent: 'space-between'
    },
    popularBadge: {
        position: 'absolute',
        top: 12,
        left: 12,
        zIndex: 1
    },
    favouriteIcon: {
        position: 'absolute',
        top: 4,
        right: 6,
        zIndex: 1
    }
});