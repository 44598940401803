import React, { useEffect, useRef, useState } from "react";
import { View, StyleSheet, ScrollView, useWindowDimensions, Platform, ActivityIndicator } from "react-native";
import { RootStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import Button from "../components/Button/Button";
import RestaurantInfo from "../components/RestaurantInfo/RestaurantInfo";
import { Badge, Text, TouchableRipple, useTheme } from "react-native-paper";
import Category from "../components/Category/Category";
import IconButton from "../components/IconButton/IconButton";
import { Category as ICategory } from "../core/interfaces/category.interface";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import RestaurantCard from "../components/RestaurantCard/RestaurantCard";
import { useDispatch, useSelector } from "react-redux";
import { Restaurant } from "../core/interfaces/restaurant.interface";
import { addFavouriteRestaurants, removeFavouriteRestaurants, setDataSaver, setMenuFilters } from "../core/store/features/ui/uiSlice";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import { setBottomSheetContext, setBottomSheetOpen } from "../core/store/features/bottomSheet/bottomSheetSlice";
import ContentLoader, { Rect } from "react-content-loader/native"
import { useIsFocused } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { RefreshControl } from 'react-native-web-refresh-control';
import { RESTAURANT_SCREEN_LOADING_TIME } from "../core/constants";
import { dataSaverSelector, menuFiltersSelector, orderItemsSelector, restaurantByCodeNameSelector, restaurantsSelector, selectedFestivalCodeNameSelector } from "../core/store/selectors";
import MenuFilter from "../components/MenuFilter/MenuFilter";



export default function ({
  navigation, route
}: NativeStackScreenProps<RootStackParamList, "restaurant">) {

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<ICategory[]>([]);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [categorySourceCords, setCategorySourceCords] = useState<any>({});
  const [menuitemSourceCords, setMenuitemSourceCords] = useState<any>({});
  const [tabSourceCords, setTabSourceCords] = useState<any>({});
  const [offset, setOffset] = useState<number>(0);

  const restaurants = useSelector(restaurantsSelector);
  const restaurant = useSelector((state) => restaurantByCodeNameSelector(state, route?.params?.restaurantCodeName))

  const dataSaver = useSelector(dataSaverSelector);
  const shoppingCart = useSelector(orderItemsSelector);
  const menuFilters = useSelector(menuFiltersSelector);
  const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(dataSaver ? true : false);

  const isFocused = useIsFocused();
  const axiosSecure = useAxiosSecure();
  const theme = useTheme<AppTheme>();
  const dispatch = useDispatch();
  const dimension = useWindowDimensions();
  const scrollViewRef = useRef<ScrollView | null>(null);
  const tabScrollViewRef = useRef<ScrollView | null>(null);

  const { colors } = useTheme<AppTheme>();

  const { t } = useTranslation();
  const styles = makeStyles(colors);


  async function apiCall() {

    let filter = `restaurantCodeName=${route.params.restaurantCodeName}&isStandingOffer=true`;

    let response = await axiosSecure.get(`/api/category?${filter}`, false);

    setCategories(response.data);
    filterMenuItems(response.data);
    // setActiveTab(response.data?.[0]?.id);
  }


  useEffect(() => {
    if (isFocused) {
      apiCall();
      if (Platform.OS === 'web' && !loaded && !dataSaver) {
        setInterval(() => {
          setLoaded(!loaded);
        }, RESTAURANT_SCREEN_LOADING_TIME);
      }
    }

  }, [restaurant?.id]);


  useEffect(() => {
    if (isFocused) {
      setTotalPrice(Math.round((shoppingCart.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0) + Number.EPSILON) * 100) / 100);
    }
  }, [isFocused])

  useEffect(() => {
    filterMenuItems(categories);
  }, [JSON.stringify(menuFilters)])

  useEffect(() => {
    if (activeTab) {
      tabScrollViewRef?.current?.scrollTo({
        x: tabSourceCords[activeTab],
        y: 0,
        animated: true,
      });
    }

  }, [activeTab])

  async function onRefresh() {
    setRefreshing(true);
    try {
      await apiCall();
    } catch { }
    setRefreshing(false);
  };

  useEffect(() => {
    let closest = -1;
    let closestKey = null;
    for (const [key, value] of Object.entries(categorySourceCords)) {
      if (Math.abs(value as number - offset) < Math.abs(closest - offset)) {
        closest = value as number;
        closestKey = key;
      }
    }

    setActiveTab(closestKey);
  }, [offset]);


  useEffect(() => {
    if (route?.params?.menuitemId) {
      scrollViewRef?.current?.scrollTo({
        x: 0,
        y: menuitemSourceCords[route?.params?.menuitemId] - 56,
        animated: true,
      });
    }
  }, [JSON.stringify(menuitemSourceCords)])


  function handleOnScroll(event: any) {
    const scrollOffset = event.nativeEvent.contentOffset.y;
    setOffset(scrollOffset);
  }

  function handleFavouritePress(restaurant: Restaurant) {
    if (restaurant._isFavourite) {
      dispatch(removeFavouriteRestaurants([restaurant.id]));
    } else {
      dispatch(addFavouriteRestaurants([restaurant.id]));
    }
  }

  function handleOrderScreenPress() {
    navigation.navigate("payment", { festivalCodeName: selectedFestivalCodeName as string });
  }

  function filterMenuItems(categories: ICategory[]) {
    let diets = menuFilters?.filter(f => f.checked)?.map(f => f.diet) || [];
    // filter menuitems by diet
    if (diets && diets?.length > 0) {
      let filteredCategories = categories.map(c => ({ ...c, menuitems: c.menuitems.filter(m => diets.indexOf(m.diet) > -1) }));
      setFilteredCategories(filteredCategories);
    } else {
      setFilteredCategories(categories);
    }
  }

  function handleTabPress(categoryId: string) {
    // setActiveTab(category.id);
    scrollViewRef?.current?.scrollTo({
      x: 0,
      y: categorySourceCords[categoryId] - 56,
      animated: true,
    });
  }

  function handleGoBack() {
    navigation.goBack();
  }

  function handleGoToRestaurantScreen(restaurant: Restaurant): void {
    navigation.navigate('restaurant', { restaurantCodeName: restaurant.codeName, festivalCodeName: selectedFestivalCodeName as string })
  }

  function handleShowAllRestaurants(): void {
    navigation.navigate('festival', { festivalCodeName: selectedFestivalCodeName as string })
  }

  function handleDataSaverToggle(event: boolean) {
    dispatch(setDataSaver(event));
  }

  function handleMenuItemPress(event: Menuitem) {
    navigation.navigate('menuitem', {
      menuitemId: event.id,
      festivalCodeName: selectedFestivalCodeName as string,
      restaurantCodeName: event?.restaurantCodeName as string
    });
  }

  function handleGoToOrderScreen() {
    if (shoppingCart?.length > 0) {
      navigation.navigate("order", {
        festivalCodeName: selectedFestivalCodeName as string,
        restaurantCodeName: shoppingCart[0].menuitem.restaurantCodeName as string
      });
    }
  }

  function handleFilterSave(e: any) {

    let filters = [];
    for (const [key, value] of Object.entries(e)) {
      filters.push({
        id: (value as any).filter.id,
        name: (value as any).filter.name,
        diet: (value as any).filter.diet,
        checked: (value as any).checked
      })
    }

    dispatch(setMenuFilters(filters));
    dispatch(setBottomSheetOpen(false))
  }

  function handleFilterClose() {
    dispatch(setBottomSheetOpen(false));
  }

  function handleBottomSheetOpen() {
    // dispatch(setBottomSheetOpen(true));
    dispatch(setBottomSheetContext({
      isOpen: true,
      initialHeight: 320,
      content: (
        <MenuFilter
          onConfirmPress={handleFilterSave}
          onClosePress={handleFilterClose}
          menuitems={categories?.flatMap((c: any) => c?.menuitems || []) as any}
        />
      )
    }));
  }

  function showActionContainer() {
    if (shoppingCart.length > 0) {
      let cartContainsItemsFromAnotherRestaurant = shoppingCart?.map(i => i.menuitem?.restaurantCodeName)?.indexOf(route?.params?.restaurantCodeName) === -1;
      if (cartContainsItemsFromAnotherRestaurant) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      {loaded && (showActionContainer() ? (<View
        style={styles.stickyActionContainer}
      >
        <View>
          <Badge
            size={24}
            onPress={handleGoToOrderScreen}
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              zIndex: 2
            }}
          >
            {shoppingCart.length}
          </Badge>
          <IconButton
            icon='ecommerce_outline_shopping_cart'
            color="black"
            onPress={handleGoToOrderScreen}
          />
        </View>

        <Button
          mode="contained"
          text={t('restaurantScreen.pay') + " " + totalPrice + "€"}
          color="ketchup"
          style={{
            width: '80%'
          }}
          onPress={handleOrderScreenPress}
        />
      </View>) : (shoppingCart?.length > 0 && <View
        style={{
          position: 'absolute',
          bottom: 16,
          left: 16,
          zIndex: 2
        }}
      >
        <View>
          <Badge
            size={24}
            onPress={handleGoToOrderScreen}
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              zIndex: 2
            }}
          >
            {shoppingCart.length}
          </Badge>
          <IconButton
            icon='ecommerce_outline_shopping_cart'
            color="black"
            onPress={handleGoToOrderScreen}
          />
        </View>
      </View>
      ))}

      {offset > 0 && <IconButton
        icon='arrows_outline_arrow_up'
        color="ketchup"
        variant="secondary"
        style={{
          position: 'absolute',
          bottom: showActionContainer() ? 48 : 8,
          right: 8,
          zIndex: 2
        }}
        onPress={() =>
          scrollViewRef?.current?.scrollTo({
            x: 0,
            y: 0,
            animated: true
          })
        }
      />}

      {!loaded &&
        <View
          style={{
            backgroundColor: theme.colors.white,
            marginBottom: 48,
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {/* <Skeleton colors={colors} /> */}
          <ActivityIndicator animating={true} size={'large'} color={theme.colors.ketchup} />
        </View>
      }

      {loaded && <ScrollView
        bounces={false} // iOS only - prevent drag whole view if it reach bottom
        scrollEventThrottle={150}
        stickyHeaderIndices={[3]} // make 2nd child element sticky (tabs)
        ref={scrollViewRef}
        refreshControl={
          <RefreshControl refreshing={refreshing}
            onRefresh={onRefresh} />
        }
        onScroll={handleOnScroll}
        style={{
          backgroundColor: theme.colors.offWhite,
          marginBottom: showActionContainer() ? 24 : undefined,
          height: Platform.OS === 'web' ? 10 : undefined
        }}
      >
        {restaurant && <IconButton
          icon={restaurant?._isFavourite ? "interaction_fill_heart" : "interaction_outline_heart"}
          color={restaurant?._isFavourite ? "ketchup" : "white"}
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1
          }}
          variant="secondary"
          onPress={() => handleFavouritePress(restaurant)}
        />}

        <IconButton
          icon='arrows_outline_arrow_left'
          color="white"
          style={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 1
          }}
          onPress={handleGoBack}
        />

        <RestaurantInfo
          festivalCodeName={selectedFestivalCodeName as string}
          restaurant={restaurant}
          saveDataMode={dataSaver}
          onDataSaverToggle={handleDataSaverToggle}
        />

        <View>

          <ScrollView
            ref={tabScrollViewRef}
            horizontal
            showsHorizontalScrollIndicator={false}
            style={{
              backgroundColor: colors.white,
              height: 56,
              width: restaurant ? dimension.width - 56 : dimension.width
            }}
          >
            {categories.map((category: any, index: number) => (

              <TouchableRipple
                key={category.id}
                borderless
                theme={theme}
                onLayout={(event) => {
                  const layout = event.nativeEvent.layout;
                  if (!(category.id in tabSourceCords)) {
                    tabSourceCords[category.id] = layout.x;
                    setTabSourceCords(tabSourceCords);
                  }
                }}
                onPress={() => handleTabPress(category.id)}
                style={{
                  ...styles.tab,
                  borderBottomColor: category.id === activeTab ? colors.black : colors.greyLight
                }}
              >
                <>
                  <Text
                    variant={category.id === activeTab ? "labelBlack" : "labelMedium"}
                    style={styles.tabText}>
                    {category.name}
                  </Text>
                </>
              </TouchableRipple>

            ))}
          </ScrollView>

          {menuFilters?.map(em => em.checked)?.some(Boolean) && <Badge
            size={24}
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              zIndex: 2
            }}
          >
            {menuFilters?.filter(em => em.checked)?.length}
          </Badge>}

          {restaurant && <IconButton
            icon="ecommerce_outline_filter"
            dimension="rectangle"
            size="large"
            color="black"
            onPress={handleBottomSheetOpen}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
          />}
        </View>


        {filteredCategories?.filter(category => (category?.menuitems || [])?.length > 0)?.map((category: any, index: number) => (
          <Category
            onLayout={(event) => {
              const layout = event.nativeEvent.layout;
              if (!(category.id in categorySourceCords)) {
                categorySourceCords[category.id] = layout.y;
                setCategorySourceCords(categorySourceCords);
              }
            }}
            onMenuItemsLayout={(event, menuitemId) => {
              if (!(menuitemId in menuitemSourceCords)) {
                event.nativeEvent.target.measure(
                  (x: any, y: any, width: any, height: any, pageX: any, pageY: any) => {
                    menuitemSourceCords[menuitemId] = pageY;
                    setMenuitemSourceCords({ ...menuitemSourceCords });
                  },
                );
              }
            }}
            key={category.id}
            showTitle={true}
            category={category}
            saveDataMode={dataSaver}
            onMenuItemPress={handleMenuItemPress}
            style={{
              marginBottom: 16
            }}
          />
        ))}

        <View
          style={{
            backgroundColor: colors.white,
            marginBottom: 16
          }}
        >
          <View
            style={{
              padding: 16,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <View>
              <Text variant="headingBlack" style={styles.titleBold}>{t('restaurantScreen.allRestaurants.title')}</Text>
              <Text variant="paragraphRegular" style={styles.subtitle}>{t('restaurantScreen.allRestaurants.subtitle')}</Text>
            </View>
            <Button color="black" mode="contained" text={t('restaurantScreen.allRestaurants.action')} size="small" onPress={handleShowAllRestaurants}></Button>
          </View>

          <ScrollView horizontal style={{
            // marginTop: 8,
            padding: 8
          }}>

            {restaurants.map((restaurant, index) => (
              <RestaurantCard
                key={restaurant.id}
                style={{
                  width: 300
                }}
                festivalCodeName={selectedFestivalCodeName as string}
                saveDataMode={dataSaver}
                isPopular={restaurant.isPopular}
                restaurant={restaurant}
                onPress={() => {
                  handleGoToRestaurantScreen(restaurant);
                  scrollViewRef?.current?.scrollTo({
                    x: 0,
                    y: 0,
                    animated: true
                  });
                }}
                onFavouritePress={() => handleFavouritePress(restaurant)}
              />
            ))}

          </ScrollView>
        </View>

      </ScrollView>}
    </>
  );
}

const makeStyles = (colors: any) => StyleSheet.create({
  stickyActionContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 16,
    width: '100%',
    padding: 16,
    backgroundColor: colors.white,
    borderTopColor: colors.greyLight,
    borderTopWidth: 1
  },
  actionContainer: {
    backgroundColor: colors.white
  },
  action: {
    height: 56,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    padding: 16,
    borderBottomColor: colors.greyLight,
    borderBottomWidth: 1
  },
  actionText: {
    fontSize: 16,
    lineHeight: 24
  },
  tab: {
    padding: 16,
    height: 56,
    backgroundColor: colors.white,
    borderBottomWidth: 4,
  },
  tabText: {
    fontSize: 14,
    lineHeight: 21
  },
  titleBold: {
    fontSize: 24,
    lineHeight: 36
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 24,
    color: colors.greyMedium
  }
})