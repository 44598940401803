import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { cz, de, en, hu, pl, sk } from "./translations";
import { languageDetectorPlugin } from "./src/utils/languageDetectorPlugin";
import { store } from "./src/core/store/store";

const resources = {
    en: {
        translation: en,
    },
    de: {
        translation: de,
    },
    cz: {
        translation: cz,
    },
    hu: {
        translation: hu,
    },
    pl: {
        translation: pl,
    },
    sk: {
        translation: sk,
    }
};


i18n.use(initReactI18next)
    .use(languageDetectorPlugin)
    .init({
        resources,
        //language to use if translations in user language are not available
        // lng: language,
        fallbackLng: "sk",
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
    });

export default i18n;