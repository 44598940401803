
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, TouchableHighlight } from "react-native";
import { Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Badge from "../Badge/Badge";
import { Menuitem } from "../../core/interfaces/menuitem.interface";
import axios, { MINIO_URL } from "../../core/api/axios";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from 'dayjs';


export interface PerformanceProps {
    performance: any;
    styles?: any;
    saveDataMode?: boolean;
    onPress?: () => void;
    onLayout?: (event: any) => void;
}

export default function Performance(props: PerformanceProps) {

    const { colors } = useTheme<AppTheme>();
    const [thumbnail, setThumbnail] = useState<string>("../../../assets/images/festival_placeholder.png");

    const { t } = useTranslation();
    const styles = makeStyles(colors);


    async function loadThumbnail() {
        try {
            let response = await axios.get(`${MINIO_URL}/artists/${props?.performance?.artistId}`);
            setThumbnail(`${MINIO_URL}/artists/${props?.performance?.artistId}`);
        } catch (e) {

        }
    }

    function getTime() {
        return `${dayjs(props?.performance?.startDatetime).format('HH:mm')} - ${dayjs(props?.performance?.endDatetime).format('HH:mm')}`;
    }

    useEffect(() => {
        setThumbnail(`${MINIO_URL}/artists/${props?.performance?.artistId}-sm`);
        // loadThumbnail();
    }, [])


    return (
        <TouchableRipple
            onLayout={props?.onLayout}
            onPress={() => props?.onPress?.()}
            style={styles.container}
        >
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 16
            }}>
                {!props?.saveDataMode && <Image
                    style={
                        styles.thumbnail
                    }
                    defaultSource={require("../../../assets/images/festival_placeholder.png")}
                    source={{ uri: thumbnail }}
                />}

                <View style={{
                    flex: 0.9,
                    gap: 4
                }}>
                    <Text variant="headingBold" style={styles.name} numberOfLines={1} ellipsizeMode="tail">{props.performance.artist.name}</Text>
                    <Text variant="paragraphRegular" style={styles.datetime} numberOfLines={1} ellipsizeMode="tail">{getTime()}</Text>
                </View>


                <Badge
                    text={props.performance.artist.genre}
                    color="black"
                    variant="primary"
                    size="small"
                    dimension="rounded"
                />

            </View>
        </TouchableRipple>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        minHeight: 64,
        width: '100%',
        backgroundColor: colors.white,
        justifyContent: 'center',
        paddingHorizontal: 16,
        paddingVertical: 8
    },
    name: {
        fontSize: 16,
        lineHeight: 24
    },
    datetime: {
        fontSize: 14,
        lineHeight: 21,
        color: colors.greyMedium
    },
    thumbnail: {
        width: 48,
        height: 48,
        borderRadius: 4,
        resizeMode: "cover"
    }
});
