import React, { useState } from 'react'
import { Snackbar, useTheme } from 'react-native-paper';
import { AppTheme } from '../../../App';


export interface NotificationProps {
    message: string,
    severity: string,
    duration: number
}


export default function Notification({ message, severity, duration }: NotificationProps): JSX.Element | null {
    const [notiOpen, setNotiOpen] = useState(true);
    const theme = useTheme<AppTheme>();

    if (!message) {
        return null
    }

    return (
        <Snackbar
            theme={theme}
            visible={notiOpen}
            duration={duration}
            onDismiss={() => setNotiOpen(false)}
            onIconPress={() => setNotiOpen(false)}
        >

            {message}
        </Snackbar>
    )
}