
import React, { } from "react";
import { View } from "react-native";
import { Text, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import IconButton from "../IconButton/IconButton";


export interface ConditionsProps {
    style?: any;
    onConfirmPress?: () => void;
    onClosePress?: () => void;
}

export default function Conditions(props: ConditionsProps) {

    const { colors } = useTheme<AppTheme>();

    const { t } = useTranslation();

    function handleConfirmPress() {
        props?.onConfirmPress?.();
    }

    function handleClosePress() {
        props?.onClosePress?.();
    }

    return (
        <>
            <IconButton
                icon='general_fill_close'
                color="white"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}
                onPress={handleClosePress}
            />

            <View style={{
                marginTop: 12,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Text variant="headingBold"
                    style={{
                        fontSize: 18,
                        lineHeight: 27
                    }}
                >{t('bottomDrawer.conditions.title')}</Text>
            </View>

            <View
                style={{
                    margin: 16,
                    gap: 32,
                    ...props?.style
                }}
            >
                <Text variant="paragraphRegular"
                    style={{
                        fontSize: 16,
                        lineHeight: 24
                    }}
                >{t('bottomDrawer.conditions.body')}</Text>

                <Button
                    style={{
                        width: '100%'
                    }}
                    mode='contained'
                    color="ketchup"
                    onPress={handleConfirmPress}
                    text={t(`bottomDrawer.conditions.action`)}
                />
            </View>
        </>
    );
}
