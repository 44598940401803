
import React, { useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import { TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";


export enum ReviewEnum {
    VeryBad = 1,
    Bad = 2,
    Medium = 3,
    Good = 4,
    VeryGood = 5
}


export interface ReviewPickerProps {
    style?: any;
    onSelect?: (e: ReviewEnum | null) => void;
}

export default function ReviewPicker(props: ReviewPickerProps) {

    const [selected, setSelected] = useState<ReviewEnum | null>(null);
    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);


    function handleReviewPress(e: ReviewEnum) {
        if (e === selected) {
            setSelected(null);
            props?.onSelect?.(null);
        } else {
            setSelected(e);
            props?.onSelect?.(e);
        }
    }

    return (
        <View
            style={styles.container}
        >
            <TouchableRipple
                onPress={() => handleReviewPress(ReviewEnum.VeryBad)}
            >
                <Image
                    style={{
                        width: 36,
                        height: 36
                    }}
                    source={
                        selected === ReviewEnum.VeryBad ?
                            require("../../../assets/images/pickers/very_bad_active.svg")
                            : require("../../../assets/images/pickers/very_bad.svg")
                    }
                />
            </TouchableRipple>

            <TouchableRipple
                onPress={() => handleReviewPress(ReviewEnum.Bad)}
            >
                <Image
                    style={{
                        width: 36,
                        height: 36
                    }}
                    source={
                        selected === ReviewEnum.Bad ?
                            require("../../../assets/images/pickers/bad_active.svg")
                            : require("../../../assets/images/pickers/bad.svg")
                    }
                />
            </TouchableRipple>

            <TouchableRipple
                onPress={() => handleReviewPress(ReviewEnum.Medium)}
            >
                <Image
                    style={{
                        width: 36,
                        height: 36
                    }}
                    source={
                        selected === ReviewEnum.Medium ?
                            require("../../../assets/images/pickers/medium_active.svg")
                            : require("../../../assets/images/pickers/medium.svg")
                    }
                />
            </TouchableRipple>

            <TouchableRipple
                onPress={() => handleReviewPress(ReviewEnum.Good)}
            >
                <Image
                    style={{
                        width: 36,
                        height: 36
                    }}
                    source={
                        selected === ReviewEnum.Good ?
                            require("../../../assets/images/pickers/good_active.svg")
                            : require("../../../assets/images/pickers/good.svg")
                    }
                />
            </TouchableRipple>

            <TouchableRipple
                onPress={() => handleReviewPress(ReviewEnum.VeryGood)}
            >
                <Image
                    style={{
                        width: 36,
                        height: 36
                    }}
                    source={
                        selected === ReviewEnum.VeryGood ?
                            require("../../../assets/images/pickers/very_good_active.svg")
                            : require("../../../assets/images/pickers/very_good.svg")
                    }
                />
            </TouchableRipple>
        </View>
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16
    }
});
