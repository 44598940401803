import * as React from 'react';
import useLoading from '../../hooks/useLoading';
import { Modal, Portal, ActivityIndicator, useTheme } from 'react-native-paper';
import { AppTheme } from '../../../App';


export default function Loading() {
  const { showLoading } = useLoading();
  
  const theme = useTheme<AppTheme>();

  return (
    <Portal>
      <Modal visible={showLoading}>
        <ActivityIndicator animating={true} size={'large'} color={theme.colors.ketchup} />
      </Modal>
    </Portal>
  );
}