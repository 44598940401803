import React, { useCallback, useEffect } from "react";
import Navigation from "./src/navigation";
import { Provider as PaperProvider, Text, useTheme } from 'react-native-paper';
import BrambleLightTheme from "./src/types/theme";
import { useFonts } from "expo-font";
import * as SplashScreen from 'expo-splash-screen';
import { Provider as StoreProvider } from "react-redux";
import { store } from "./src/core/store/store";
import { Platform, View } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import "./i18n.config";
import { SocketProvider } from "./src/context/websocketContext";

SplashScreen.preventAutoHideAsync();

const theme = BrambleLightTheme;

export type AppTheme = typeof theme;

export const useAppTheme = () => useTheme<AppTheme>();

export default function App() {

  const [fontsLoaded] = useFonts({
    'Inter-Regular': require('./assets/fonts/Inter-Regular.otf'),
    'Inter-Bold': require('./assets/fonts/Inter-Bold.otf'),
    'Inter-Black': require('./assets/fonts/Inter-Black.otf'),
    'Inter-Italic': require('./assets/fonts/Inter-Italic.otf'),
    'Inter-BoldItalic': require('./assets/fonts/Inter-BoldItalic.otf'),
    'bramble': require('./assets/fonts/bramble.ttf'),
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);


  if (!fontsLoaded) {
    return null;
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <StoreProvider store={store}>
        <PaperProvider theme={theme}>
          {/* <AuthProvider> */}
          <SocketProvider>
            <GestureHandlerRootView style={{ flex: 1 }}>
              <Navigation />
            </GestureHandlerRootView>
          </SocketProvider>
          {/* </AuthProvider> */}
        </PaperProvider>
      </StoreProvider>
    </View>

    // <View onLayout={onLayoutRootView}>
    //   <Text style={{ fontFamily: 'Inter-Black', fontSize: 30 }}>Inter Black</Text>
    //   <Text style={{ fontSize: 30 }} variant="paragraphBlack">Inter Black</Text>
    //   <Text style={{ fontFamily: 'serif', fontSize: 30 }}>serif</Text>
    //   <Text style={{ fontSize: 30 }}>Platform Default</Text>
    //   <Text style={{ fontSize: 30 }}>Platform Default</Text>
    // </View>
  );
}
