import * as React from 'react';
import { useEffect } from 'react';
import {
    Animated,
    Easing,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import { AppTheme } from '../../../App';


export interface SwitchProps {
    style?: any;
    value: boolean;
    onColor?: string;
    offColor?: string;
    onValueChange?: (e: any) => void;
    labelStyle?: any;
    label?: string;
}

export default function Switch({
    value = false,
    onColor,
    offColor,
    style,
    onValueChange,
    labelStyle,
    label
}: SwitchProps) {

    const { colors } = useTheme<AppTheme>();

    const animatedValue = new Animated.Value(0);

    const moveToggle = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 25],
    });

    if (!offColor) {
        offColor = colors.greyLight;
    }

    if (!onColor) {
        onColor = colors.greenDark;
    }

    const color = value ? onColor : offColor;

    animatedValue.setValue(value ? 0 : 1);


    useEffect(() => {
        Animated.timing(animatedValue, {
            toValue: value ? 1 : 0,
            duration: 300,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start();
    }, [value]);


    return (
        <View style={styles.container}>
            {!!label && <Text style={[styles.label, labelStyle]}>{label}</Text>}

            <TouchableOpacity onPress={onValueChange}>
                <View style={[styles.toggleContainer, style, { backgroundColor: color }]}>
                    <Animated.View
                        style={[
                            styles.toggleWheelStyle,
                            {
                                marginLeft: moveToggle,
                            },
                        ]}
                    />
                </View>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    toggleContainer: {
        width: 50,
        height: 30,
        marginLeft: 3,
        borderRadius: 15,
        justifyContent: 'center',
    },
    label: {
        marginRight: 2,
    },
    toggleWheelStyle: {
        width: 25,
        height: 25,
        backgroundColor: 'white',
        borderRadius: 12.5,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 2.5,
        elevation: 1.5,
    },
});