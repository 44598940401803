import React, { useEffect, useRef, useState } from "react";


import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";

import Main from "./MainStack";
import Loading from "../components/Loading/Loading";
import Notification from "../components/Notification/Notification";
import { StatusBar, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import useNotification from "../hooks/useNotification";
import { Badge, useTheme } from "react-native-paper";
import { AppTheme } from "../../App";
import * as Linking from "expo-linking";
import { RootState } from "../core/store/store";
import CurrentOrder from "../components/CurrentOrder/CurrentOrder";
import useAxiosSecure from "../hooks/useAxiosSecure";
import { setCurrentOrder } from "../core/store/features/currentOrder/currentOrderSlice";
import ConfirmDialog from "../components/ConfirmDialog/ConfirmDialog";
import BottomSheet from "../components/BottomSheet/BottomSheet";
import { orderItemsSelector, selectedFestivalCodeNameSelector } from "../core/store/selectors";
import IconButton from "../components/IconButton/IconButton";
import QrCodeOverlay from "../components/QrCodeOverlay/QrCodeOverlay";

declare global {
  interface Window { senderId: any; }
}

export default () => {

  const currentOrder = useSelector((state: RootState) => state.currentOrder.currentOrder);
  const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);
  const shoppingCart = useSelector(orderItemsSelector);

  const { colors } = useTheme<AppTheme>();
  const styles = makeStyles(colors);

  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef();
  const { message, severity, duration, messageId } = useNotification();
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const dispatch = useDispatch();


  const axiosSecure = useAxiosSecure();

  useEffect(() => {

    async function checkOrder() {
      try {
        let response = await axiosSecure.get(`/api/order?id=${currentOrder?.id}`, false);

        if (response.data && response.data?.length === 0) {
          dispatch(setCurrentOrder(null));
        }
      } catch (e) {

      }
    }

    if (currentOrder != null) {
      checkOrder();
    }

  }, []);

  const config = {
    screens: {
      drawer: {
        path: '/:festivalCodeName',
        parse: {
          festivalCodeName: String,
        },
        screens: {
          home: {
            path: 'home',
          },
          festival: {
            path: '',
            parse: {
              festivalCodeName: String,
            },
          },
          restaurant: {
            path: 'restaurant/:restaurantCodeName',
            parse: {
              festivalCodeName: String,
              restaurantCodeName: String,
            },
          },
          menuitem: {
            path: 'menuitem/:menuitemId',
            parse: {
              menuitemId: String,
              festivalCodeName: String,
              restaurantCodeName: String,
            },
          },
          order: {
            path: 'order/:restaurantCodeName',
            parse: {
              festivalCodeName: String,
              restaurantCodeName: String,
            },
          },
          orderState: {
            path: 'orderState',
            parse: {
              festivalCodeName: String,
            }
          },
          manageOrder: {
            path: 'manageOrder/:orderId/:orderStateId/:orderNumber',
            parse: {
              orderId: String,
              orderStateId: String,
              orderNumber: String
            },
          },
          cancelOrder: {
            path: 'cancelOrder/:orderId',
            parse: {
              orderId: String,
            },
          },
          helpOrder: {
            path: 'helpOrder/:type/:orderId',
            parse: {
              type: Number,
              orderId: String,
            }
          },
          rateOrder: {
            path: 'rateOrder/:restaurantName/:restaurantId/:orderId',
            parse: {
              restaurantName: String,
              restaurantId: String,
              orderId: String,
            },
          },
          orderNumber: 'orderNumber',
          upsale: 'upsale',
          payment: {
            path: 'payment',
            parse: {
              festivalCodeName: String,
              restaurantCodeName: String,
            },
          },
          cuisine: {
            path: 'cuisine/:cuisine',
            parse: {
              cuisine: String,
            },
          },
          program: {
            path: 'program',
            parse: {
              festivalCodeName: String,
            },
          },
          artist: {
            path: 'artist/:performanceId',
            parse: {
              performanceId: String,
              festivalCodeName: String,
            },
          },
          search: 'search',
          map: 'map',
          language: 'language'
        }
      },
    },
  };

  function handleGoToOrderScreen() {
    if (shoppingCart?.length > 0) {
      navigationRef.navigate("order", {
        festivalCodeName: selectedFestivalCodeName as string,
        restaurantCodeName: shoppingCart[0].menuitem.restaurantCodeName as string
      });
    }
  }

  const linking = {
    prefixes: [Linking.createURL('/'), 'https://bramble.com', 'bramble://'],
    config,
  };

  return (
    <>
      <StatusBar barStyle="dark-content" />

      <Loading />
      <ConfirmDialog />
      <QrCodeOverlay />

      <NavigationContainer
        linking={linking}
        ref={navigationRef}
        onReady={() => {
          (routeNameRef as any).current = navigationRef.getCurrentRoute()?.name;
          setCurrentRoute(navigationRef.getCurrentRoute()?.name as string);
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute()?.name;

          if (previousRouteName !== currentRouteName) {
            // Save the current route name for later comparison
            (routeNameRef as any).current = currentRouteName;
            setCurrentRoute(currentRouteName);
          }
        }}
      >
        <Main />

        <BottomSheet />

        {["orderState", "orderNumber", "manageOrder", "helpOrder", "cancelOrder", "rateOrder", "program"].indexOf(currentRoute) === -1 && currentOrder != null && <View style={styles.stickyActionContainer}>
          <CurrentOrder
            order={currentOrder}
            onOpenPress={() => navigationRef.navigate("orderState", { festivalCodeName: selectedFestivalCodeName as string })}
            onPress={() => navigationRef.navigate("orderState", { festivalCodeName: selectedFestivalCodeName as string })}
          />
        </View>}

        {["festival"].indexOf(currentRoute) !== -1 && currentOrder == null && shoppingCart?.length > 0 && <View
          style={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            zIndex: 2
          }}
        >
          <View>
            <Badge
              size={24}
              onPress={handleGoToOrderScreen}
              style={{
                position: 'absolute',
                top: -5,
                right: -5,
                zIndex: 2
              }}
            >
              {shoppingCart.length}
            </Badge>
            <IconButton
              icon='ecommerce_outline_shopping_cart'
              color="black"
              onPress={handleGoToOrderScreen}
            />
          </View>
        </View>}

      </NavigationContainer>

      <Notification key={messageId} message={message} severity={severity} duration={duration!} />
    </>
  );
};


const makeStyles = (colors: any) => StyleSheet.create({
  stickyActionContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    width: '100%'
  }
});
