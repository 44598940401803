import React, { useEffect, useState } from "react";
import { View, StyleSheet, useWindowDimensions, Image } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, TouchableRipple, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import Button from "../components/Button/Button";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { useTranslation } from "react-i18next";
import ReviewPicker, { ReviewEnum } from "../components/ReviewPicker/ReviewPicker";
import { HelpEnum } from "../types/enums";
import { newGuid } from "../utils/utilities";
import { selectedFestivalCodeNameSelector } from "../core/store/selectors";
import { MINIO_URL } from "../core/api/axios";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "rateOrder">) {

    const dispatch = useDispatch();

    const [selectedReview, setSelectedReview] = useState<ReviewEnum | null>(null);
    const [thumbnail, setThumbnail] = useState<string>(`../../../assets/images/restaurant_placeholder.png`);

    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);

    const dimension = useWindowDimensions();
    const axiosSecure = useAxiosSecure();
    const { t } = useTranslation();
    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);


    useEffect(() => {
        if (route?.params?.restaurantId) {
            setThumbnail(`${MINIO_URL}/restaurants/${route?.params?.restaurantId}-sm`);
        }
    }, [route?.params?.restaurantId])

    function handleReviewSelect(e: ReviewEnum | null) {
        setSelectedReview(e)
    }

    async function handleCompletePress() {
        if (selectedReview !== null) {
            let response = await axiosSecure.post(`/api/review`, {
                id: newGuid(),
                userEmail: route?.params?.userEmail,
                stars: selectedReview,
                orderId: route?.params?.orderId,
                restaurantId: route?.params?.restaurantId
            });

            navigation.navigate("festival", {
                festivalCodeName: selectedFestivalCodeName as string
            })
        } else {
            navigation.navigate("festival", {
                festivalCodeName: selectedFestivalCodeName as string
            })
        }
    }

    return (
        <>
            <View
                style={styles.headerRow}
            >
                <>
                    <IconButton
                        icon='general_fill_close'
                        color="white"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1
                        }}
                        onPress={() =>
                            navigation.goBack()
                        }
                    />

                    <Text variant="headingBlack" style={styles.header}>{t('rateOrderScreen.header')}</Text>
                </>
            </View>

            <View
                style={styles.stickyActionContainer}
            >
                <TouchableRipple
                    onPress={() => navigation.navigate("helpOrder", {
                        orderId: route?.params?.orderId,
                        type: HelpEnum.Order,
                    })}
                >
                    <Text variant="labelMedium"
                        style={[
                            styles.paragraph16,
                            styles.underline
                        ]}
                    >{t('rateOrderScreen.helpWithOrder')}</Text>
                </TouchableRipple>
            </View>

            <View
                style={{
                    ...styles.container,
                    height: dimension.height - 48,
                    justifyContent: 'center',
                    gap: 48
                }}
            >

                <Image
                    style={styles.thumbnail}
                    source={{ uri: thumbnail }}
                    defaultSource={require("../../assets/images/restaurant_placeholder.png")}
                />

                <View>
                    <Text variant="headingBlack" style={{
                        ...styles.paragraph20,
                        textAlign: "center"
                    }}>{t('rateOrderScreen.question')}</Text>
                    <Text variant="headingBlack" style={{
                        ...styles.paragraph20,
                        textAlign: "center"
                    }}>{route.params?.restaurantName}?</Text>
                </View>

                <ReviewPicker
                    onSelect={handleReviewSelect}
                />

                <Button
                    color="ketchup"
                    mode="contained"
                    text={selectedReview === null ? t('rateOrderScreen.actions.rateLater') : t('rateOrderScreen.actions.rate')}
                    style={{
                        margin: 16,
                        // width: '100%'
                    }}
                    onPress={handleCompletePress}
                ></Button>
            </View>
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    stickyActionContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        gap: 16,
        backgroundColor: colors.white,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    container: {
        backgroundColor: colors.white,
        flexDirection: 'column',
        alignItems: 'center'
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    header: {
        lineHeight: 27,
        fontSize: 18
    },
    actionTitle: {
        lineHeight: 25,
        fontSize: 20
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        backgroundColor: colors.white
    },
    infoContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 16,
        padding: 16,
        backgroundColor: colors.offWhite,
        borderRadius: 4
    },
    infoText: {
        fontSize: 12,
        lineHeight: 18,
        color: colors.greyMedium
    },
    underline: {
        textDecorationLine: "underline"
    },
    paragraph20: {
        lineHeight: 25,
        fontSize: 20,
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
    paragraph14: {
        lineHeight: 21,
        fontSize: 14,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    status: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        gap: 8
    },
    thumbnail: {
        width: 96,
        height: 96,
        borderRadius: 96 / 2
    },
    divider: {
        height: 16,
        backgroundColor: colors.offWhite
    }
});
