
import React, { } from "react";
import { View, Image } from "react-native";
import { Text, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";


export interface OrderInProgressProps {
    style?: any;
    imageStyle?: any;
    height?: number;
    subtitle?: string;
    onConfirmPress?: () => void;
    onCancelPress?: () => void;
}

export default function OrderInProgress(props: OrderInProgressProps) {

    const { colors } = useTheme<AppTheme>();

    const { t } = useTranslation();

    function handleCancelPress() {
        props?.onCancelPress?.();
    }

    function handleConfirmPress() {
        props?.onConfirmPress?.();
    }

    return (
        <View style={{
            ...props?.style
        }}>
            <Image
                style={{
                    ...props?.imageStyle
                }}
                source={require("../../../assets/images/order_in_progress.png")}
            />
            <View
                style={{
                    margin: 16,
                    gap: 16
                }}
            >
                <Text variant="headingBlack" style={{
                    fontSize: 24,
                    lineHeight: 30,
                    textAlign: "center"
                }}>{t(`bottomDrawer.orderInProgress.title`)}</Text>

                <Text variant="paragraphRegular" style={{
                    fontSize: 16,
                    lineHeight: 24,
                    textAlign: "center"
                }}>{props?.subtitle}</Text>

                <Button
                    style={{
                        width: '100%'
                    }}
                    mode='contained'
                    color="greyLight"
                    onPress={handleCancelPress}
                    text={t(`bottomDrawer.orderInProgress.action1`)}
                />

                <Button
                    style={{
                        width: '100%'
                    }}
                    mode='contained'
                    color="ketchup"
                    onPress={handleConfirmPress}
                    text={t(`bottomDrawer.orderInProgress.action2`)}
                />
            </View>
        </View>
    );
}
