
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Chip as _Chip, Button as _Button, Text, useTheme, TouchableRipple, Surface, TextInput } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { BrambleColor } from "../../types/theme";
import { capitalize } from "../../utils/utilities";
import { default as _color } from 'color';


export interface TextFieldProps {
    color?: BrambleColor;
    size?: 'small' | 'large';
    label?: string;
    error?: boolean;
    value?: string;
    multiline?: boolean;
    autoFocus?: boolean;
    borderRadius?: number;
    maxLength?: number;
    numberOfLines?: number;
    iconLeft?: string;
    iconRight?: string;
    style?: any;
    disabled?: boolean;
    onChangeText?: (text: string) => void;
}

export default function TextField({
    disabled,
    style,
    iconLeft,
    multiline = false,
    borderRadius = 0,
    autoFocus,
    maxLength,
    numberOfLines,
    iconRight,
    label,
    value,
    onChangeText,
    error,
    size = 'large',
    color = 'ketchup',
    ...rest
}: TextFieldProps) {


    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [text, setText] = useState<string>();

    const theme = useTheme<AppTheme>();

    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);

    let _on: any = "on" + capitalize(color).toString();

    let backgroundColor: string = theme.colors[color];
    let textColor: string = theme.colors[_on as keyof object];
    let height = multiline ? undefined : size === 'large' ? 48 : 24;


    let paddingVertical = size === 'large' ? 12 : 8;
    let paddingHorizontal = size === 'large' ? 16 : 9;

    // let marginHorizontal = size === 'large' ? 16 : (size === 'medium' ? 12 : 8);
    let iconSize = 17;
    let fontSize = 16;

    if (disabled) {
        backgroundColor = theme.colors["disabled"];
        textColor = theme.colors["onDisabled"];
    }

    const textStyle = {
        fontSize: fontSize,
        fontWeight: "400" as "400"
    };

    function handleChangeText(text: string) {
        setText(text);
        onChangeText?.(text);
    }

    return (
        <>
            <TextInput
                mode="flat"
                left={iconLeft && <TextInput.Icon
                    icon={iconLeft}
                    color={theme.colors["black"]}
                    size={iconSize}
                />}
                right={iconRight && <TextInput.Icon
                    icon={iconRight}
                    color={theme.colors["black"]}
                    size={iconSize}
                />}
                theme={{
                    roundness: borderRadius
                }}
                contentStyle={{
                    height,
                    paddingTop: paddingVertical,
                    paddingBottom: paddingVertical,
                    paddingLeft: paddingHorizontal,
                    paddingRight: paddingHorizontal
                }}
                autoFocus={autoFocus}
                numberOfLines={numberOfLines}
                multiline={multiline}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={label}
                placeholderTextColor={theme.colors["grey"]}
                underlineStyle={{
                    display: "none"
                }}
                style={{
                    ...(disabled ? styles.disabledContainer : error ? isFocused ? styles.errorFocusedContainer : styles.errorContainer : styles.container),
                    height,
                    borderRadius,
                    ...textStyle,
                    ...style
                }}
                value={value}
                onChangeText={handleChangeText}

                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            {maxLength && maxLength > 0 && <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end'
            }}><Text>{text?.length || 0}/{maxLength}</Text></View>}
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: colors.greyLight
        // borderWidth: 2
    },
    errorContainer: {
        borderWidth: 1,
        borderColor: colors.ketchup
    },
    errorFocusedContainer: {
        borderWidth: 2,
        borderColor: colors.ketchup
    },
    disabledContainer: {
        backgroundColor: colors.disabled
    }
});
