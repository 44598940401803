
import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Checkbox, RadioButton, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { ExtraCategory as IExtraCategory } from "../../core/interfaces/extra-category.interface";
import { ExtraCategoryExtraMenuitem } from "../../core/interfaces/extra-category-extra-menuitem.interface";
import Badge from "../Badge/Badge";


export interface ExtraCategoryProps {
    extraCategory: IExtraCategory;
    styles?: any;
    checkedIds?: string[];
    onPress?: () => void;
    onCheckedItemChange?: (e: any) => void;
}

export default function ExtraCategory(props: ExtraCategoryProps) {

    const { colors } = useTheme<AppTheme>();

    const [checkedExtraMenuItemsMap, setCheckedExtraMenuItemsMap] = useState<any>({});

    const styles = makeStyles(colors);


    useEffect(() => {
        buildCheckedExtraMenuItemsMap();
        // props?.onCheckedItemChange?.(checkedExtraMenuItemsMap);
    }, [props?.extraCategory]);


    useEffect(() => {
        props?.onCheckedItemChange?.(checkedExtraMenuItemsMap);
    }, [checkedExtraMenuItemsMap]);


    function buildCheckedExtraMenuItemsMap() {
        let _map: any = {};
        props?.extraCategory.extraCategoryExtraMenuitems.reduce(function (result: any, currentObject: ExtraCategoryExtraMenuitem) {
            
            let isChecked = (props?.checkedIds || [])?.indexOf(currentObject?.extraMenuitem?.id) > -1 

            _map[currentObject.id] = {
                extraMenuitem: currentObject.extraMenuitem,
                extraCategoryId: props?.extraCategory?.id,
                checked: isChecked ? true : false
            };
            return result;
        }, {});

        setCheckedExtraMenuItemsMap(_map);
    }

    function handleExtraMenuItemPress(extraMenuItem: ExtraCategoryExtraMenuitem) {

        if (props.extraCategory.isOneChoice) {
            Object.keys(checkedExtraMenuItemsMap).forEach(key => {
                if (key !== extraMenuItem.id) {
                    checkedExtraMenuItemsMap[key]["checked"] = false;
                }
            });
        }

        checkedExtraMenuItemsMap[extraMenuItem.id]["checked"] = !checkedExtraMenuItemsMap[extraMenuItem.id]["checked"]
        setCheckedExtraMenuItemsMap({ ...checkedExtraMenuItemsMap });
    }

    return (
        <View
            style={styles.container}
        >
            <Text
                style={styles.extraCategoryName}
                variant="headingBlack"
            >
                {props.extraCategory.name}
            </Text>

            <Text
                style={styles.choices}
                variant="paragraphRegular"
            >
                {props?.extraCategory?.isOneChoice ? 'Vyber jednu možnosť' : 'Vyberte viac možností'}
            </Text>

            {props.extraCategory.isMandatory && <Badge
                text="Povinné"
                color="ketchup"
                variant="primary"
                size="small"
                dimension="rounded"
                style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    zIndex: 1
                }}
            />}

            {!props.extraCategory.isMandatory && <Badge
                text="Dobrovoľné"
                color="grey"
                variant="primary"
                size="small"
                dimension="rounded"
                style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    zIndex: 1
                }}
            />}

            {props.extraCategory.extraCategoryExtraMenuitems.map((extraCategoryExtraMenuitem, index) => (
                <TouchableRipple
                    key={extraCategoryExtraMenuitem.extraMenuitem.id}
                    onPress={() => handleExtraMenuItemPress(extraCategoryExtraMenuitem)}
                    style={{
                        ...styles.extraMenuItem,
                        borderBottomColor: index !== props.extraCategory.extraCategoryExtraMenuitems.length - 1 ? colors.greyLight : 'unset',
                        borderBottomWidth: index !== props.extraCategory.extraCategoryExtraMenuitems.length - 1 ? 1 : 0
                    }}
                >
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 8
                    }}>
                        <View>
                            <Text variant="paragraphBold" style={styles.name}
                            >
                                {extraCategoryExtraMenuitem?.extraMenuitem?.name}
                            </Text>

                            <Text variant="paragraphMedium" style={styles.price}>
                                + {extraCategoryExtraMenuitem?.extraMenuitem?.price} €
                            </Text>
                        </View>

                        {props.extraCategory.isOneChoice && <RadioButton
                            value={extraCategoryExtraMenuitem.id}
                            status={checkedExtraMenuItemsMap[extraCategoryExtraMenuitem.id]?.["checked"] ? 'checked' : 'unchecked'}
                            onPress={() => handleExtraMenuItemPress(extraCategoryExtraMenuitem)}
                        />}

                        {!props.extraCategory.isOneChoice && <Checkbox
                            status={checkedExtraMenuItemsMap[extraCategoryExtraMenuitem.id]?.["checked"] ? 'checked' : 'unchecked'}
                            onPress={() => handleExtraMenuItemPress(extraCategoryExtraMenuitem)}
                        />}
                    </View>

                </TouchableRipple>
            ))
            }
        </View >
    );
}



const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        padding: 16,
        backgroundColor: colors.white
    },
    extraCategoryName: {
        fontSize: 24,
        lineHeight: 36
    },
    name: {
        fontSize: 16,
        lineHeight: 24
    },
    price: {
        fontSize: 14,
        lineHeight: 21
    },
    choices: {
        fontSize: 16,
        lineHeight: 24,
        color: colors.greyMedium
    },
    extraMenuItem: {
        height: 81,
        paddingVertical: 16
    },
    description: {
        fontSize: 14,
        lineHeight: 21,
        color: colors.greyMedium
    },
    thumbnail: {
        width: 96,
        height: 96
    }
});
