import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView, Dimensions, ImageBackground, InteractionManager, Platform } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Divider, Text, TouchableRipple, useTheme } from "react-native-paper";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { WebView } from 'react-native-webview';


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "map">) {

    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);

    return (
        <>

            <IconButton
                icon='arrows_outline_arrow_left'
                color="white"
                style={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    zIndex: 1
                }}
                onPress={() =>
                    navigation.goBack()
                }
            />

            {Platform.OS === "web" ? (
                <iframe src="https://bramble-pohoda-html-live.vercel.app" height={'100%'} width={'100%'}/>
            ) : (
                <View style={{ flex: 1 }}>
                    <WebView
                        source={{ uri: "https://bramble-pohoda-html-live.vercel.app" }}
                        style={{ flex: 1 }}
                    />
                </View>
            )}
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({

});
