
import { useSelector } from 'react-redux';
import { RootState } from '../core/store/store';

export interface Notification {
    message: string;
    type: string;
}

const useBottomSheet = () => {
    const bottomSheetState = useSelector((state: RootState) => state?.bottomSheet);
    return bottomSheetState;
}

export default useBottomSheet;