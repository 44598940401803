import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Menuitem } from '../../../interfaces/menuitem.interface';
import { ExtraMenuitem } from '../../../interfaces/extra-menuitem.interface';
import { isArraySame, newGuid } from '../../../../utils/utilities';

export interface ShoppingCartItem {
    id?: string;
    menuitem: Menuitem;
    extraMenuitems: ExtraMenuitem[];
    amout?: number;
    note?: string | null;
    totalPrice: number;
    savedMoney?: number;
}

export interface ShoppingCartState {
    items: ShoppingCartItem[];
}

const initialState: ShoppingCartState = {
    items: []
}

function isSame(a: ShoppingCartItem, b: ShoppingCartItem) {
    if (a.menuitem.id !== b.menuitem.id) return false;
    if (a.note !== b.note) return false;
    let aEm = a.extraMenuitems.map(em => em.id);
    let bEm = b.extraMenuitems.map(em => em.id);
    if (!isArraySame(aEm, bEm)) return false;
    return true;
}

export function computeTotalPrice(item: ShoppingCartItem) {
    let _totalPrice = 0;

    if (item.menuitem?.discountPrice && item.menuitem?.discountPrice > 0) {
        _totalPrice = item.menuitem?.discountPrice || 0;
    } else {
        _totalPrice = item.menuitem?.price || 0;
    }

    let amout = item.amout as number;
    item.extraMenuitems.forEach(em => _totalPrice += em.price);
    return Math.round(((_totalPrice * amout) + Number.EPSILON) * 100) / 100;
}

export function computeSavedMoney(item: ShoppingCartItem) {
    let _savedMoney = 0;

    if (item.menuitem?.discountPrice && item.menuitem?.discountPrice > 0) {
        _savedMoney = (item.menuitem?.price || 0) - (item.menuitem?.discountPrice || 0);
    }

    let amout = item.amout as number;
    return Math.round(((_savedMoney * amout) + Number.EPSILON) * 100) / 100;
}

export const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState,
    reducers: {
        addItemToShoppingCart: (state, action: PayloadAction<ShoppingCartItem>) => {
            if (state.items.filter(i => isSame(i, action.payload)).length === 0) {
                state.items.push({
                    id: action.payload.id ? action.payload.id : newGuid(),
                    menuitem: action.payload.menuitem,
                    amout: action.payload.amout || 1,
                    extraMenuitems: action.payload.extraMenuitems,
                    note: action.payload.note,
                    totalPrice: computeTotalPrice(action.payload),
                    savedMoney: computeSavedMoney(action.payload)
                });
            } else {
                const item = state.items.find(c => c.menuitem.id === action.payload.menuitem.id) as any;
                Object.assign(item, {
                    amout: item.amout + (action.payload.amout || 1),
                    extraMenuitems: action.payload.extraMenuitems,
                    note: action.payload.note,
                    totalPrice: computeTotalPrice(action.payload),
                    savedMoney: computeSavedMoney(action.payload)
                });
            }
        },
        updateItemInShoppingCart: (state, action: PayloadAction<ShoppingCartItem>) => {
            const orderItem = state.items.find(c => c.id === action.payload.id) as any;
            Object.assign(orderItem, {
                ...action.payload,
                totalPrice: computeTotalPrice(action.payload),
                savedMoney: computeSavedMoney(action.payload)
            });
        },
        removeItemFromShoppingCart: (state, action: PayloadAction<ShoppingCartItem>) => {
            const item = state.items.find(c => c.id === action.payload.id) as any;

            if (item) {
                if (item.amout > 1) {
                    Object.assign(item, {
                        amout: item.amout - 1,
                        totalPrice: computeTotalPrice({
                            ...item,
                            amout: item.amout - 1
                        }),
                        savedMoney: computeSavedMoney({
                            ...item,
                            amout: item.amout - 1
                        })
                    });
                } else {
                    state.items = [...state.items.filter(c => c.id !== action.payload.id)];
                }
            }
        },
        clearShoppingCart: (state) => {
            state.items = []
        }
    },
})

// Action creators are generated for each case reducer function
export const { addItemToShoppingCart, updateItemInShoppingCart, removeItemFromShoppingCart, clearShoppingCart } = shoppingCartSlice.actions

export default { shoppingCartReducer: shoppingCartSlice.reducer } 
