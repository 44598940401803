
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView } from "react-native";
import { Checkbox, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { MINIO_URL } from "../../core/api/axios";


export interface FestivalItemProps {
    style?: any;
    saveDataMode?: boolean;
    festival?: any;
    onPress?: (e: any) => void;
}

export default function FestivalItem(props: FestivalItemProps) {

    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);

    const [thumbnail, setThumbnail] = useState<string>("../../../assets/images/festival_placeholder.png");


    useEffect(() => {
        setThumbnail(`${MINIO_URL}/festivals/${props?.festival?.id}-sm`);
    }, [])


    return (
        <TouchableRipple
            onPress={() => props?.onPress?.(props?.festival?.codeName)}
            style={{
                ...props?.style,
                ...styles.container
            }}
        >
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 8
            }}>
                {!props?.saveDataMode && <Image
                    style={
                        styles.thumbnail
                    }
                    defaultSource={require("../../../assets/images/festival_placeholder.png")}
                    source={{ uri: thumbnail }}
                />}

                <Text variant="headingBold" style={styles.title}>
                    {props?.festival.name}
                </Text>
            </View>
        </TouchableRipple>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        // minHeight: 90,
        backgroundColor: colors.white,
        justifyContent: 'center',
        padding: 8
    },
    title: {
        fontSize: 16,
        lineHeight: 26
    },
    thumbnail: {
        width: 72,
        height: 72,
        borderRadius: 4,
        resizeMode: "cover"
    }
});