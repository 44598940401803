import React from "react";
import { Surface, TouchableRipple, IconButton as _IconButton, useTheme } from 'react-native-paper';
import { BadgeColor } from "../../types/theme";
import { capitalize } from "../../utils/utilities";
import { AppTheme } from "../../../App";
import Icon from "../Icon/Icon";

export interface IconButtonProps {
    variant?: 'primary' | 'secondary' | 'tertiary';
    color?: BadgeColor;
    iconColor?: BadgeColor;
    dimension?: 'rectangle' | 'circle';
    size?: 'small' | 'medium' | 'normal' | 'large';
    text?: string;
    closable?: boolean;
    icon: string;
    iconSize?: number;
    style?: any;
    disabled?: boolean;
    accessible?: boolean | undefined;
    /**
     * Accessibility label for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityLabel?: string;
    /**
     * Accessibility hint for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityHint?: string;
    /**
     * testID to be used on tests.
     */
    testID?: string;
    onPress?: () => void;
}

export default function IconButton({
    disabled,
    accessibilityLabel,
    accessibilityHint,
    style,
    icon,
    iconColor,
    iconSize,
    text,
    onPress,
    size = 'normal',
    dimension = 'circle',
    testID = 'badge',
    color = 'ketchup',
    variant = 'primary',
    accessible,
    ...rest
}: IconButtonProps) {

    const theme = useTheme<AppTheme>();

    let _suffix: string = variant === "secondary" ? "Light" : "";
    let _on: string = variant === "secondary" ? "on" + capitalize(color).toString() + _suffix : "on" + capitalize(color).toString();

    let _color: string = color + _suffix;

    let backgroundColor = theme.colors[_color as keyof object];
    if (!backgroundColor) {
        backgroundColor = theme.colors["greyLight" as keyof object];
    }

    let textColor: string = theme.colors[_on as keyof object];
    if (!textColor) {
        textColor = theme.colors["black" as keyof object];
    }

    if (variant === "tertiary") {
        textColor = theme.colors["black" as keyof object];
    }

    let _iconColor = iconColor ? iconColor : (variant === "primary" ? theme.colors[_on as keyof object] : theme.colors[color + "Dark" as keyof object]);

    let height = size === 'large' ? 56 : (size === 'normal' ? 48 : (size === 'medium' ? 40 : 32));
    let fontSize = size === 'large' ? 24 : (size === 'normal' ? 20 : (size === 'medium' ? 16 : 12));

    let borderRadius = dimension === 'circle' ? height : 4;


    return (
        <Surface
            {...rest}
            testID={`${testID}-container`}
            style={{ ...style }}
            elevation={0}
        >
            <TouchableRipple
                borderless
                accessibilityLabel={accessibilityLabel}
                accessibilityHint={accessibilityHint}
                accessibilityRole="button"
                accessibilityState={{ disabled }}
                accessible={accessible}
                disabled={disabled}
                testID={testID}
                theme={theme}
                onPress={onPress}
                style={{
                    backgroundColor: variant !== 'tertiary' ? backgroundColor : undefined,
                    width: height,
                    minWidth: height,
                    height,
                    borderWidth: 0,
                    borderRadius,
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...style
                }}
            >
                <Icon name={icon} size={iconSize || fontSize} color={_iconColor} />
            </TouchableRipple >
        </Surface >
    );
}

