import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView, Platform, ActivityIndicator } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { List, Text, TouchableRipple, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import Button from "../components/Button/Button";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { useTranslation } from "react-i18next";
import Icon from "../components/Icon/Icon";
import { setCurrentOrder } from "../core/store/features/currentOrder/currentOrderSlice";
import { OrderStateEnum } from "../core/interfaces/order-state.interface";
import { currentOrderSelector, dataSaverSelector, restaurantsSelector } from "../core/store/selectors";
import OrderDetails from "../components/OrderDetails/OrderDetails";
import { useIsFocused } from "@react-navigation/native";
import { ORDER_STATE_SCREEN_LOADING_TIME } from "../core/constants";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "orderState">) {

    const dispatch = useDispatch();

    const currentOrder = useSelector(currentOrderSelector);
    const restaurants = useSelector(restaurantsSelector);
    const dataSaver = useSelector(dataSaverSelector);
    const [location, setLocation] = useState<string | null>(null);
    const [loaded, setLoaded] = useState<boolean>(dataSaver ? true : false);
    const [confirmedPickUp, setConfirmedPickUp] = useState<boolean>(false);

    const axiosSecure = useAxiosSecure();
    const { t } = useTranslation();
    const isFocused = useIsFocused();
    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);


    useEffect(() => {

        async function apiCall() {
            let orderRestaurant = restaurants.find(r => r.id === currentOrder?.restaurantId);

            if (!orderRestaurant) {
                let response = await axiosSecure.get(`/api/restaurant?id=${currentOrder?.restaurantId}`, false);

                if (response.data && response.data?.length > 0) {
                    orderRestaurant = response.data[0];
                }
            }

            let location = orderRestaurant?.festivalRestaurants?.find((fr: any) => fr.festivalCodeName === currentOrder?.festivalCodeName);

            if (location) {
                setLocation(`${location.location}`);
            } else {
                setLocation(`${orderRestaurant?.state} • ${orderRestaurant?.street}`);
            }
        }

        if (isFocused) {
            apiCall();
            if (Platform.OS === 'web' && !loaded && !dataSaver) {
                setInterval(() => {
                    setLoaded(!loaded);
                }, ORDER_STATE_SCREEN_LOADING_TIME);
            }
        }
    }, [])

    function handleOk() {
        dispatch(setCurrentOrder(null));
        navigation.goBack()
    }

    async function handleMarkAsPickedUp() {
        if (confirmedPickUp) {
            let response = await axiosSecure.put(`/api/order`, {
                id: currentOrder?.id,
                orderStateId: OrderStateEnum.IsPickedUp
            });

            navigation.navigate("rateOrder", {
                orderId: currentOrder?.id as string,
                userEmail: currentOrder?.userEmail as string,
                restaurantId: currentOrder?.restaurantId as string,
                restaurantName: currentOrder?.restaurantName as string
            })

            if (response.data && response.data?.length > 0) {
                dispatch(setCurrentOrder(null));
            }
        } else {
            setConfirmedPickUp(true);
        }
    }

    function getOrderStateIcon() {
        switch (currentOrder?.orderState?.id) {
            case OrderStateEnum.IsCreated:
                return require("../../assets/images/animation/waiting.gif");
            case OrderStateEnum.IsPreparing:
                return require("../../assets/images/animation/preparing.gif");
            case OrderStateEnum.IsDone:
                return require("../../assets/images/animation/success.gif");
            case OrderStateEnum.IsPickedUp:
                return require("../../assets/images/animation/success.gif");
            case OrderStateEnum.IsCanceled:
                return require("../../assets/images/animation/error.gif");
            default:
                return require("../../assets/images/animation/waiting.gif");
        }
    }

    function getOrderStateTitle() {
        switch (currentOrder?.orderState?.id) {
            case OrderStateEnum.IsCreated:
                return t('orderStateScreen.orderState.title.isCreated');
            case OrderStateEnum.IsPreparing:
                return t('orderStateScreen.orderState.title.isPreparing');
            case OrderStateEnum.IsDone:
                return t('orderStateScreen.orderState.title.isDone');
            case OrderStateEnum.IsPickedUp:
                return t('orderStateScreen.orderState.title.isPickedUp');
            case OrderStateEnum.IsCanceled:
                return t('orderStateScreen.orderState.title.isCanceled');
            default:
                return t('orderStateScreen.orderState.title.isCreated');
        }
    }

    function getOrderStateSubtitle() {
        switch (currentOrder?.orderState?.id) {
            case OrderStateEnum.IsCreated:
                return `${t('orderStateScreen.orderState.subtitle.isCreated1')} ${currentOrder?.restaurantName} ${t('orderStateScreen.orderState.subtitle.isCreated2')}`;
            case OrderStateEnum.IsPreparing:
                return t('orderStateScreen.orderState.subtitle.isPreparing');
            case OrderStateEnum.IsDone:
                return t('orderStateScreen.orderState.subtitle.isDone');
            case OrderStateEnum.IsPickedUp:
                return `${t('orderStateScreen.orderState.subtitle.isPickedUp1')} ${currentOrder?.orderNumber} ${t('orderStateScreen.orderState.subtitle.isPickedUp2')} ${new Date(currentOrder?.updatedAt)?.toLocaleString()}`;
            case OrderStateEnum.IsCanceled:
                return `${t('orderStateScreen.orderState.subtitle.isCanceled1')} ${currentOrder?.restaurantName} ${t('orderStateScreen.orderState.subtitle.isCanceled2')} ${currentOrder?.orderStateReason}`;
            default:
                return t('orderStateScreen.orderState.subtitle.isCreated');
        }
    }

    return (
        <>
            <View
                style={styles.stickyActionContainer}
            >
                <View
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: 16
                    }}
                >
                    <TouchableRipple
                        onPress={() => navigation.navigate("manageOrder", {
                            orderId: currentOrder?.id as string,
                            orderStateId: currentOrder?.orderStateId as string
                        })}
                    >
                        <Text variant="labelMedium"
                            style={[
                                styles.paragraph16,
                                styles.underline
                            ]}
                        >{t('orderStateScreen.action2')}</Text>
                    </TouchableRipple>

                    {currentOrder?.orderStateId !== OrderStateEnum.IsCanceled && currentOrder?.orderStateId !== OrderStateEnum.IsPickedUp && <Button
                        mode="contained"
                        text={confirmedPickUp ? t('orderStateScreen.action4') : t('orderStateScreen.action')}
                        disabled={currentOrder?.orderStateId !== OrderStateEnum.IsDone}
                        color={confirmedPickUp ? "black" : "ketchup"}
                        // icon={confirmedPickUp ? undefined : "Checkmark"}
                        style={{
                            width: '100%'
                        }}
                        onPress={handleMarkAsPickedUp}
                    />}

                    {(currentOrder?.orderStateId === OrderStateEnum.IsCanceled || currentOrder?.orderStateId === OrderStateEnum.IsPickedUp) && <Button
                        mode="contained"
                        text={t('orderStateScreen.action5')}
                        color={"ketchup"}
                        style={{
                            width: '100%'
                        }}
                        onPress={handleOk}
                    />}
                </View>
            </View>

            {!loaded &&
                <View
                    style={{
                        backgroundColor: colors.white,
                        marginBottom: 48,
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <ActivityIndicator animating={true} size={'large'} color={colors.ketchup} />
                </View>
            }

            {loaded && <ScrollView
                alwaysBounceHorizontal={false}
                alwaysBounceVertical={false}
                stickyHeaderIndices={[0]}
                bounces={false} // iOS only - prevent drag whole view if it reach bottom
                style={{
                    ...styles.container,
                    height: Platform.OS === 'web' ? 10 : undefined,
                    // marginTop: 48,
                    marginBottom: 120
                }}
            >

                <TouchableRipple
                    style={styles.headerRow}
                    onPress={() =>
                        navigation.goBack()
                    }
                >
                    <>
                        <Text variant="headingBlack" style={styles.header}>{t('orderStateScreen.header')}</Text>

                        <IconButton
                            icon='arrows_outline_chevron_down'
                            color="white"
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                zIndex: 1
                            }}
                            onPress={() =>
                                navigation.goBack()
                            }
                        />
                    </>
                </TouchableRipple>

                <View style={styles.status}>
                    <Image
                        style={{
                            width: 96,
                            height: 96
                        }}
                        source={getOrderStateIcon()}
                    />

                    <Text variant="headingBlack" style={styles.actionTitle}>{getOrderStateTitle()}</Text>
                    <Text variant="paragraphRegular" style={{
                        ...styles.paragraph16,
                        textAlign: "center"
                    }}>{getOrderStateSubtitle()}</Text>

                    <Button
                        text={t('orderStateScreen.orderNumber') + ": " + currentOrder?.orderNumber}
                        size="normal"
                        contentStyle={{
                            fontWeight: "700" as "700",
                            fontSize: 18,
                            lineHeight: 27
                        }}
                        color="greyLight"
                        endIcon='ecommerce_outline_zoom_in'
                        onPress={() => { navigation.navigate("orderNumber") }}
                    />
                </View>

                <List.Section style={{
                    width: '100%'
                }}>
                    <List.Accordion
                        id="1"
                        title={
                            <Text variant="paragraphBold" style={styles.paragraph16}>{t('orderStateScreen.actions.pickUpPlace')}</Text>
                        }
                        right={(props) => <Icon name='arrows_outline_chevron_down' size={20} color='black' />}
                        style={{
                            borderBottomColor: colors.greyLight,
                            backgroundColor: colors.white,
                            borderBottomWidth: 1
                        }}
                    >
                        <View style={styles.location}>
                            <View style={{
                                ...styles.row,
                                gap: 24
                            }}>
                                <Icon
                                    name="general_outline_location"
                                    size={24}
                                    color="black"
                                    style={styles.icon}
                                />
                                <View style={styles.reviewRow}>
                                    <Text variant="headingBold" style={styles.infoTitle}>{location}</Text>
                                    <Text variant="paragraphRegular" style={styles.infoSubtitle}>{currentOrder?.restaurantName}</Text>
                                </View>
                            </View>

                            <Button
                                mode="contained"
                                size="small"
                                text={t('orderStateScreen.action3')}
                                color="black"
                                onPress={() => { }}
                            />
                        </View>
                    </List.Accordion>
                    <List.Accordion
                        id="2"
                        title={
                            <Text variant="paragraphBold" style={styles.paragraph16}>{t('orderStateScreen.actions.orderDetail')}</Text>
                        }
                        right={(props) => <Icon name='arrows_outline_chevron_down' size={20} color='black' />}
                        titleStyle={styles.paragraph16}
                        style={{
                            borderBottomColor: colors.greyLight,
                            backgroundColor: colors.white,
                            borderBottomWidth: 1
                        }}
                    >
                        {currentOrder && <OrderDetails
                            order={currentOrder}
                        />}

                    </List.Accordion>
                </List.Section>

                <View style={styles.infoContainer}>
                    <Text variant="headingBold" style={styles.paragraph16}>{t('orderStateScreen.info.title')}</Text>
                    <Text variant="paragraphRegular" style={styles.paragraph16}>{t('orderStateScreen.info.subtitle')}</Text>
                </View>

            </ScrollView>}
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    stickyActionContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        gap: 16,
        backgroundColor: colors.white,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    container: {
        backgroundColor: colors.white,
        gap: 16
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    header: {
        lineHeight: 27,
        fontSize: 18
    },
    infoTitle: {
        lineHeight: 24,
        fontSize: 16
    },
    infoSubtitle: {
        lineHeight: 24,
        fontSize: 14,
        color: colors.greyMedium
    },
    location: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        marginBottom: 16,
        padding: 16,
        gap: 24
    },
    reviewRow: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: "flex-start"
    },
    actionTitle: {
        lineHeight: 25,
        fontSize: 20
    },
    headerRow: {
        // position: 'absolute',
        // top: 0,
        width: '100%',
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        backgroundColor: colors.white
    },
    infoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: 16,
        padding: 16,
        backgroundColor: colors.offWhite,
        borderRadius: 4
    },
    infoText: {
        fontSize: 12,
        lineHeight: 18,
        color: colors.greyMedium
    },
    underline: {
        textDecorationLine: "underline"
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
    paragraph14: {
        lineHeight: 21,
        fontSize: 14,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    status: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        gap: 8
    },
    divider: {
        height: 16,
        backgroundColor: colors.offWhite
    }
});
