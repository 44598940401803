import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Button as _Button, useTheme, Text, Surface, TouchableRipple } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { BrambleColor } from "../../types/theme";
import { capitalize } from "../../utils/utilities";
import Icon from "../Icon/Icon";



export interface ButtonProps {
    mode?: 'text' | 'outlined' | 'contained' | 'elevated' | 'contained-tonal';
    color?: BrambleColor;
    size?: 'small' | 'medium' | 'normal' | 'large';
    text?: string;
    icon?: string;
    endIcon?: any;
    style?: any;
    touchableStyle?: any;
    contentStyle?: any;
    disabled?: boolean;
    children?: any;
    onPress?: () => void;
    accessible?: boolean | undefined;
    /**
     * Accessibility label for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityLabel?: string;
    /**
     * Accessibility hint for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityHint?: string;
    /**
     * testID to be used on tests.
     */
    testID?: string;
}

export default function Button({
    children,
    onPress,
    style,
    touchableStyle,
    contentStyle,
    icon,
    disabled,
    accessible,
    accessibilityLabel,
    accessibilityHint,
    testID = 'button',
    endIcon,
    text,
    size = 'normal',
    color = 'ketchup',
    mode = 'contained',
    ...rest
}: ButtonProps) {

    const theme = useTheme<AppTheme>();

    let _on: any = "on" + capitalize(color).toString();

    let backgroundColor = theme.colors[color];
    let textColor: string = theme.colors[_on as keyof object];
    let height = size === 'large' ? 56 : (size === 'normal' ? 48 : (size === 'medium' ? 40 : 32));
    let paddingVertical = size === 'large' ? 22 : (size === 'normal' ? 18 : (size === 'medium' ? 14 : 11));
    let paddingHorizontal = size === 'large' ? 24 : (size === 'normal' ? 16 : (size === 'medium' ? 14 : 8));
    let borderRadius = height;
    let fontSize = size === 'large' ? 18 : (size === 'normal' ? 16 : (size === 'medium' ? 14 : 12));
    let lineHeight = size === 'large' ? 27 : (size === 'normal' ? 24 : (size === 'medium' ? 21 : 18));
    let iconSize = fontSize;
    let marginHorizontal = 8;

    if (disabled) {
        backgroundColor = theme.colors["disabled"];
        textColor = theme.colors["onDisabled"];
    }

    const textStyle = {
        lineHeight: lineHeight,
        fontSize: fontSize,
        fontWeight: "500" as "500",
        color: mode === 'contained' ? textColor : backgroundColor,
        textDecorationLine: mode === 'text' ? 'underline' : undefined,
        ...contentStyle
    };

    return (
        <Surface
            {...rest}
            testID={`${testID}-container`}
            style={{ ...style }}
            elevation={0}
        >
            <TouchableRipple
                borderless
                accessibilityLabel={accessibilityLabel}
                accessibilityHint={accessibilityHint}
                accessibilityRole="button"
                accessibilityState={{ disabled }}
                accessible={accessible}
                disabled={disabled}
                testID={testID}
                theme={theme}
                onPress={Platform.OS === 'web' ? onPress : undefined}
                onPressIn={Platform.OS !== 'web' ? onPress : undefined}
                style={{
                    backgroundColor: mode === 'contained' ? backgroundColor : undefined,
                    height,
                    // paddingVertical,
                    paddingHorizontal,
                    borderWidth: 0,
                    borderRadius,
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...touchableStyle
                }}
            >
                <View
                    style={styles.content}
                >

                    {icon &&
                        <>
                            <Icon name={icon} size={iconSize} color={textColor} />
                            <View style={{ flex: 1, marginRight: marginHorizontal }}></View>
                        </>
                    }

                    <Text style={textStyle}>
                        {text}
                    </Text>

                    {endIcon &&
                        <>
                            <View style={{ flex: 1, marginRight: marginHorizontal }}></View>
                            <Icon name={endIcon} size={iconSize} color={textColor} />
                        </>
                    }

                </View>
            </TouchableRipple >
        </Surface >
    );
}


const styles = StyleSheet.create({
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
});
