
import React, { useEffect, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import { Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { Order } from "../../core/interfaces/order.interface";
import { useTranslation } from "react-i18next";
import { OrderStateEnum } from "../../core/interfaces/order-state.interface";
import IconButton from "../IconButton/IconButton";
import { sleep } from "../../utils/utilities";


export interface CurrentOrderProps {
    order: Order | null;
    styles?: any;
    onPress?: () => void;
    onOpenPress?: () => void;
}

export default function CurrentOrder(props: CurrentOrderProps) {


    const [orderStateTitle, setOrderStateTitle] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    const { colors } = useTheme<AppTheme>();
    const { t } = useTranslation();
    const styles = makeStyles(colors);

    let text = getOrderStateTitle();
    let delay = 50;


    useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setOrderStateTitle(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, delay);

            return () => clearTimeout(timeout);
        } else {

            // reset every 3 seconds
            const timeout = setTimeout(() => {
                setOrderStateTitle("");
                setCurrentIndex(0);
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, delay, text]);


    function getOrderStateTitle() {
        switch (props?.order?.orderState?.id) {
            case OrderStateEnum.IsCreated:
                return t('orderStateScreen.orderState.title.isCreated');
            case OrderStateEnum.IsPreparing:
                return t('orderStateScreen.orderState.title.isPreparing');
            case OrderStateEnum.IsDone:
                return t('orderStateScreen.orderState.title.isDone');
            case OrderStateEnum.IsPickedUp:
                return t('orderStateScreen.orderState.title.isPickedUp');
            case OrderStateEnum.IsCanceled:
                return t('orderStateScreen.orderState.title.isCanceled');
            default:
                return t('orderStateScreen.orderState.title.isCreated');
        }
    }

    function getOrderStateColor() {
        switch (props?.order?.orderState?.id) {
            case OrderStateEnum.IsCreated:
                return colors.black;
            case OrderStateEnum.IsPreparing:
                return colors.mustard;
            case OrderStateEnum.IsDone:
                return colors.green;
            case OrderStateEnum.IsPickedUp:
                return colors.green;
            case OrderStateEnum.IsCanceled:
                return colors.ketchup;
            default:
                return colors.black;
        }
    }


    return (
        <TouchableRipple
            style={{
                ...props.styles,
                ...styles.container,
                borderTopColor: getOrderStateColor()
            }}
            onPress={() => props?.onPress?.()}
        >
            <>
                {/* <Image
                    style={{
                        width: 32,
                        height: 32
                    }}
                    source={getOrderStateIcon()}
                /> */}

                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}>
                    <Text variant="headingBold" style={styles.paragraph16}>{props.order?.restaurantName} #{props.order?.orderNumber}</Text>
                    {/* <Text variant="paragraphRegular" style={styles.paragraph16}>Čaká na schválenie...</Text> */}
                    {/* <Text variant="paragraphRegular" style={styles.paragraph16}>{t('orderStateScreen.orderState.title')}</Text> */}
                    <Text variant="paragraphRegular" style={styles.paragraph16}>{orderStateTitle || " "}</Text>
                </View>

                <IconButton
                    icon='arrows_outline_chevron_up'
                    dimension="rectangle"
                    color="greyLight"
                    onPress={() => props?.onOpenPress?.()}
                />
            </>
        </TouchableRipple>
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        padding: 16,
        backgroundColor: colors.white,
        width: '100%',
        borderTopWidth: 3
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
});
