import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type ContextType = 'Menuitem' | 'Festival' | 'MenuItemFilters' | 'Festivals' | 'Allergens' | 'OrderInProgress';
export type Action = 'CREATE' | 'UPDATE' | 'VIEW';


export interface BottomSheetState {
    isOpen: boolean;
    content: string | null | undefined | JSX.Element;
    initialHeight?: number;
    closeOnDragDown?: boolean;
    closeOnBackdropPress?: boolean;
    closeOnPressBack?: boolean;
}


const initialState: BottomSheetState = {
    isOpen: false,
    content: null
}

export const bottomSheetSlice = createSlice({
    name: 'bottomSheet',
    initialState,
    reducers: {
        setBottomSheetOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setBottomSheetContext: (state, action: PayloadAction<BottomSheetState>) => {
            state.isOpen = true;
            state.content = action.payload.content;
            state.closeOnDragDown = action.payload.closeOnDragDown;
            state.closeOnBackdropPress = action.payload.closeOnBackdropPress;
            state.closeOnPressBack = action.payload.closeOnPressBack;
            state.initialHeight = action.payload.initialHeight || 340;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setBottomSheetOpen, setBottomSheetContext } = bottomSheetSlice.actions

export default { bottomSheetReducer: bottomSheetSlice.reducer } 
