import { useEffect, useState, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useWebSocket, { ReadyState } from 'react-use-websocket'
import PubSub from '../pubsub'

import { debounce } from 'lodash';
import { RootState } from '../core/store/store';
import { setCurrentOrder } from '../core/store/features/currentOrder/currentOrderSlice';
import { BASE_URL } from '../core/api/axios';
import { Platform } from 'react-native';

export const SocketContext = createContext<any | null>({})

let proto = '';

if (Platform.OS === 'web') {
  if (window.location.protocol === 'http:') {
    proto = window.location.protocol.replace('http', 'ws')
  } else {
    proto = window.location.protocol.replace('https', 'wss')
  }
} else {
  proto = 'wss';
}

const wsAddress = `${proto}//${BASE_URL.replace('https://', '').replace('http://', '')}/ws`

export const SocketProvider = (props: any) => {

  const [topics, setTopics] = useState<string[]>([])
  const dispatch = useDispatch();

  const currentOrder = useSelector((state: RootState) => state.currentOrder.currentOrder);


  const wsOpts = {
    shouldReconnect: () => {
      return true
    },
  }

  const { sendMessage, readyState, getWebSocket } = useWebSocket(wsAddress, wsOpts)


  const subscribe = () => {
    sendMessage(
      JSON.stringify({
        topic: 'auth',
        // token: localStorage.getItem('accessToken'),
        subscribe: topics
      }),
    )
  }

  useEffect(() => {
    if (currentOrder?.orderNumber) {
      setTopics([`order.update.${currentOrder?.orderNumber}`])
      console.log('Topics changed', topics)
    } else {
      setTopics([]);
    }
  }, [currentOrder])

  useEffect(() => {
    subscribe()
  }, [topics])

  const updateTopicsDebounce = debounce((newTopics: any) => {
    console.log('WS: Subscriptions changed')
    setTopics(newTopics)
  }, 200)

  PubSub.setOnSubscriptionsChange((newTopics: any) => updateTopicsDebounce(newTopics))

  const onMessage = (message: any) => {
    const data = JSON.parse(message.data)
    if (data.topic === 'heartbeat') return

    if (data.sender === window.senderId) {
      return // my own message. ignore
    }
    // if (data.topic === 'shout' && data?.summary?.text) toast.info(data.summary.text)

    console.log('Event RX', data)

    if (data.event) {
      dispatch(setCurrentOrder(data.event));
    }
    // PubSub.publish(data.topic, data)
  }

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      getWebSocket()!.onmessage = onMessage
      subscribe()
      // Dispatch a fake event to the frontend components
      // in case they depend on the event stream and may
      // miss some messages - this should force reloading
      // events using graphql
      PubSub.publish('client.connected', {
        topic: 'client.connected',
      })
    }
  }, [readyState, getWebSocket])

  return (
    <SocketContext.Provider
      value={{
        getWebSocket,
        readyState
      }}
    >
      {props.children}
    </SocketContext.Provider>
  )
}
