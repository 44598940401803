import React, { useEffect, useState } from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ActivityIndicator, Text, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { useTranslation } from "react-i18next";
import Button from "../components/Button/Button";
import TextField from "../components/TextField/TextField";
import { OrderStateEnum } from "../core/interfaces/order-state.interface";
import { setCurrentOrder } from "../core/store/features/currentOrder/currentOrderSlice";
import { selectedFestivalCodeNameSelector } from "../core/store/selectors";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "cancelOrder">) {

    const dispatch = useDispatch();

    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);

    const [note, setNote] = useState<string | null>();
    const [processing, setProcessing] = useState<boolean>(true);
    const [step, setStep] = useState<number>(0);

    const axiosSecure = useAxiosSecure();
    const { t } = useTranslation();
    const { colors } = useTheme<AppTheme>();
    const dimension = useWindowDimensions();

    const styles = makeStyles(colors);


    function handleNoteChangeText(event: any) {
        setNote(event);
    }

    useEffect(() => {
        async function processAnimation() {
            // only for testing
            setProcessing(true);

            let response = await axiosSecure.put(`/api/order`, {
                id: route?.params?.orderId,
                orderStateId: OrderStateEnum.IsCanceled,
                orderStateReason: note
            });

            if (response.data && response.data?.length > 0) {
                dispatch(setCurrentOrder(null));
            }

            // await sleep(500);
            setProcessing(false);
            setStep(2);
        }

        if (step === 2) {
            processAnimation();
        }

    }, [step]);

    function canSend() {
        if (note?.length || 0 > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            <View
                style={styles.headerRow}
            >
                <>
                    {step === 0 && <IconButton
                        icon='arrows_outline_arrow_left'
                        color="white"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1
                        }}
                        onPress={() =>
                            navigation.goBack()
                        }
                    />}

                    <Text variant="headingBlack" style={styles.header}>{t('cancelOrderScreen.header')}</Text>

                    {step === 1 && <IconButton
                        icon='general_fill_close'
                        color="white"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 1
                        }}
                        onPress={() =>
                            navigation.goBack()
                        }
                    />}
                </>
            </View>

            <View
                style={{
                    ...styles.container,
                    height: dimension.height - 48,
                    justifyContent: step === 2 ? 'center' : 'flex-start'
                }}
            >
                {step === 0 && <View style={{
                    width: '100%',
                    padding: 16
                }}>
                    <Text variant="paragraphRegular" style={styles.paragraph16}>{t('cancelOrderScreen.info')}</Text>

                    <Button
                        color="black"
                        mode="contained"
                        text={t('cancelOrderScreen.actions.cancel')}
                        style={{
                            margin: 16,
                            // width: '100%'
                        }}
                        onPress={() => setStep(1)}
                    ></Button>
                </View>}

                {step === 1 && <View style={{
                    width: '100%'
                }}>
                    <View style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 16,
                        gap: 16
                    }}>
                        <Image
                            style={{
                                width: 96,
                                height: 96
                            }}
                            source={require("../../assets/images/animation/error.gif")}
                        />

                        <Text variant="headingBlack" style={styles.paragraph20}>{t('cancelOrderScreen.steps.step1.title')}</Text>
                    </View>

                    <View
                        style={{
                            backgroundColor: colors.white,
                            padding: 16,
                            gap: 16
                        }}
                    >
                        <View>
                            <Text variant="labelMedium" style={styles.paragraph16}>{t('cancelOrderScreen.steps.step1.subtitle')}
                                <Text variant="paragraphBold" style={{
                                    ...styles.paragraph16,
                                    color: colors.ketchup
                                }}>
                                    *
                                </Text>
                            </Text>

                            <TextField
                                numberOfLines={3}
                                multiline={true}
                                label={t('cancelOrderScreen.steps.step1.placeholder')}
                                borderRadius={4}
                                onChangeText={handleNoteChangeText}
                            ></TextField>
                        </View>

                        <Button
                            disabled={!canSend()}
                            color="black"
                            mode="contained"
                            text={t('cancelOrderScreen.steps.step1.actions.send')}
                            onPress={() => { setStep(2) }}
                        ></Button>
                    </View>

                    <View
                        style={{
                            backgroundColor: colors.white,
                            padding: 16,
                            gap: 16
                        }}
                    >
                        <View style={styles.infoContainer}>
                            <Text variant="headingBold" style={styles.paragraph16}>{t('cancelOrderScreen.steps.step1.info.title')}</Text>
                            <Text variant="paragraphRegular" style={styles.paragraph16}>{t('cancelOrderScreen.steps.step1.info.subtitle')}</Text>
                        </View>
                    </View>
                </View>}

                {step === 2 && <View style={{
                    width: '100%',
                    padding: 16
                }}>

                    {processing && <ActivityIndicator animating={true} size={'large'} color={colors.ketchup} />}

                    {!processing && <View style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 16,
                        gap: 16
                    }}>
                        <Image
                            style={{
                                width: 96,
                                height: 96
                            }}
                            source={require("../../assets/images/animation/success.gif")}
                        />

                        <Text variant="headingBlack" style={styles.paragraph24}>{t('cancelOrderScreen.steps.step2.info.title')}</Text>

                        <View style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Text variant="paragraphMedium" style={styles.paragraph16}>{t('cancelOrderScreen.steps.step2.info.subtitle')}</Text>
                        </View>

                        <Button
                            color="ketchup"
                            mode="contained"
                            size="medium"
                            text={t('cancelOrderScreen.steps.step2.actions.back')}
                            style={{
                                margin: 16,
                                // width: '100%'
                            }}
                            onPress={() => navigation.navigate("festival", {
                                festivalCodeName: selectedFestivalCodeName as string
                            })}
                        ></Button>
                    </View>}
                </View>}
            </View>
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    infoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: 16,
        backgroundColor: colors.offWhite,
        borderRadius: 4
    },
    container: {
        backgroundColor: colors.white,
        flexDirection: 'column',
        alignItems: 'center'
    },
    paragraph24: {
        lineHeight: 30,
        fontSize: 24,
    },
    paragraph20: {
        lineHeight: 25,
        fontSize: 20,
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        backgroundColor: colors.white
    },
    header: {
        lineHeight: 27,
        fontSize: 18
    }
});
