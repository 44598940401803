
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet } from "react-native";
import { Card, Text, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Icon from "../Icon/Icon";
import { Restaurant } from "../../core/interfaces/restaurant.interface";
import Switch from "../Switch/Switch";
import { MINIO_URL } from "../../core/api/axios";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import Rating from "../Rating/Rating";


export interface RestaurantInfoProps {
    style?: any;
    saveDataMode: boolean;
    restaurant: Restaurant;
    festivalCodeName?: string;
    onPress?: () => void;
    onDataSaverToggle?: (e: boolean) => void;
}

export default function RestaurantInfo(props: RestaurantInfoProps) {

    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);

    const { t } = useTranslation();
    const [thumbnail, setThumbnail] = useState<string>(`../../../assets/images/restaurant_placeholder.png`);

    useEffect(() => {
        if (props.restaurant) {
            setThumbnail(`${MINIO_URL}/restaurants/${props?.restaurant?.id}`);
        }
    }, [props.restaurant])


    function getRatingLevel(rating: number) {

        if (rating > 0 && rating <= 1.9) {
            return t('restaurantScreen.info.review.title.veryPoor');
        }

        if (rating >= 2.0 && rating <= 2.9) {
            return t('restaurantScreen.info.review.title.poor');
        }

        if (rating >= 3.0 && rating <= 3.9) {
            return t('restaurantScreen.info.review.title.medium');
        }

        if (rating >= 4.0 && rating <= 4.4) {
            return t('restaurantScreen.info.review.title.good');
        }

        if (rating >= 4.5) {
            return t('restaurantScreen.info.review.title.veryGood');
        }
    }

    function getLocation() {
        if (!props?.restaurant) {
            return '';
        }

        if (!props?.festivalCodeName) {
            return `${props?.restaurant?.state} • ${props?.restaurant?.street}`;
        }

        let location = props?.restaurant?.festivalRestaurants?.find((fr: any) => fr.festivalCodeName === props?.festivalCodeName);
        if (location) {
            return `${location.location}`;
        } else {
            return `${props?.restaurant?.state} • ${props?.restaurant?.street}`;
        }
    }

    return (
        <Card onPress={() => props?.onPress?.()} mode="contained" style={{
            ...styles.container,
            ...props.style
        }}>
            <Image
                style={styles.cover}
                source={{ uri: thumbnail }}
                defaultSource={require("../../../assets/images/restaurant_placeholder.png")}
            />

            <Card.Content style={styles.infoContainer}>

                <Text
                    style={styles.header}
                    variant="headingBlack"
                >
                    {props?.restaurant?.name}
                </Text>

                {props?.restaurant?.numberOfReviews > 0 && <View style={styles.infoRow}>
                    <Icon
                        name="gamification_outline_star"
                        size={24}
                        color="black"
                        style={styles.icon}
                    />

                    <View style={styles.reviewRow}>
                        <Text variant="headingBold" style={styles.infoTitle}>{getRatingLevel(props?.restaurant?.rating)}</Text>

                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: "center",
                            gap: 4
                        }}>
                            <Rating
                                size="large"
                                value={props?.restaurant?.rating}
                            />
                            <Text variant="paragraphMedium" style={{ ...styles.ratingTitle, color: colors.black, fontWeight: "500" as "500" }}>{`${props?.restaurant?.rating} / 5`}</Text>
                            <Text variant="paragraphRegular" style={{ ...styles.ratingTitle, color: colors.greyMedium }}>{`(${props?.restaurant?.numberOfReviews}) ${t('restaurantScreen.info.review.subtitle1')}`}</Text>
                        </View>
                    </View>
                </View>}

                {props?.restaurant?.numberOfReviews === 0 && <View style={styles.infoRow}>
                    <Icon
                        name="gamification_outline_star"
                        size={24}
                        color="black"
                        style={styles.icon}
                    />
                    <View style={styles.reviewRow}>
                        <Text variant="paragraphRegular" style={styles.infoSubtitle}>{t('restaurantScreen.info.review.subtitle2')}</Text>
                    </View>
                </View>}

                <View
                    style={styles.locationRow}
                >
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: "center",
                        gap: 16
                    }}>
                        {props?.restaurant && <Icon
                            name="general_outline_location"
                            size={24}
                            color="black"
                            style={styles.icon}
                        />}
                        <View style={styles.reviewRow}>
                            <Text variant="headingBold" style={styles.infoTitle}>{getLocation()}</Text>
                            <Text variant="paragraphRegular" style={styles.infoSubtitle}>{props?.restaurant?.name}</Text>
                        </View>
                    </View>

                    <Button
                        color="greyLight"
                        mode="contained"
                        text={t('restaurantScreen.info.action')}
                        size="small"
                        onPress={() => { }}
                    ></Button>
                </View>

            </Card.Content>
        </Card >
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 0
    },
    locationRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        marginBottom: 16,
        gap: 16
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: "center",
        marginBottom: 16,
        gap: 16
    },
    reviewRow: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: "flex-start",
        // margin: 8,
        // gap: 8
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    infoContainer: {
        // marginTop: 24,
        // marginLeft: 8
    },
    dataSaveContainer: {
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: colors.greyLight,
        backgroundColor: colors.white
    },
    header: {
        lineHeight: 42,
        fontSize: 28,
        marginTop: 16,
        marginBottom: 16
    },
    infoTitle: {
        lineHeight: 24,
        fontSize: 16
    },
    infoSubtitle: {
        lineHeight: 24,
        fontSize: 14,
        color: colors.greyMedium
    },
    ratingTitle: {
        lineHeight: 21,
        fontSize: 14
    },
    textBold: {
        fontWeight: 'bold'
    },
    cover: {
        width: '100%',
        height: 230
    }
});
