
import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Checkbox, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Button from "../Button/Button";
import { RootState } from "../../core/store/store";
import { useSelector } from "react-redux";
import { Menuitem } from "../../core/interfaces/menuitem.interface";
import { useTranslation } from "react-i18next";
import IconButton from "../IconButton/IconButton";


export interface MenuFilterProps {
    style?: any;
    menuitems: Menuitem[];
    onChange?: (e: any) => void;
    onClosePress?: () => void;
    onConfirmPress?: (e: any) => void;
}

export default function MenuFilter(props: MenuFilterProps) {

    const { colors } = useTheme<AppTheme>();

    const { t } = useTranslation();
    const styles = makeStyles(colors);
    const menuFilters = useSelector((state: RootState) => state.ui.menuFilters);

    const [checkedItemsMap, setCheckedItemsMap] = useState<any>({});
    const [filteredMenuitems, setFilteredMenuitems] = useState<any>(props?.menuitems);


    const filter = [{
        id: '3f61786b-4870-4329-9d9e-e28bd02a6bbc',
        name: t('bottomDrawer.menuFilter.filters.vegan'),
        diet: 0
    }, {
        id: '4296d1fc-8c89-4922-9b2e-a8e64cdbe47b',
        name: t('bottomDrawer.menuFilter.filters.vegetarian'),
        diet: 1
    }, {
        id: 'bfbb7507-120c-4be2-a15a-9d958b35a0ea',
        name: t('bottomDrawer.menuFilter.filters.glutenFree'),
        diet: 2
    }];


    useEffect(() => {
        buildCheckedItemsMap();
    }, []);


    function buildCheckedItemsMap() {
        let _map: any = {};

        if (menuFilters && menuFilters?.length > 0) {
            menuFilters.reduce(function (result: any, currentObject: any) {
                _map[currentObject.id] = {
                    filter: {
                        id: currentObject.id,
                        name: currentObject.name,
                        diet: currentObject.diet
                    },
                    checked: currentObject.checked
                };
                return result;
            }, {});
        } else {
            filter.reduce(function (result: any, currentObject: any) {
                _map[currentObject.id] = {
                    filter: currentObject,
                    checked: false
                };
                return result;
            }, {});
        }

        setCheckedItemsMap(_map);
    }

    function handleFilterItemPress(filter: any) {
        checkedItemsMap[filter.id]["checked"] = !checkedItemsMap[filter.id]["checked"]
        setCheckedItemsMap({ ...checkedItemsMap });
        props?.onChange?.(checkedItemsMap);

        let diets = Object.entries(checkedItemsMap)
            .filter(([key, value]: [string, any]) => value?.checked)
            .map(([key, value]: [string, any]) => value?.filter?.diet);

        // filter menuitems by diet
        if (diets && diets?.length > 0) {
            setFilteredMenuitems(props?.menuitems.filter((m: Menuitem) => diets.indexOf(m.diet) > -1));
        } else {
            setFilteredMenuitems(props?.menuitems);
        }
    }
    
    function handleResetPress() {
        let _map: any = {};

        filter.reduce(function (result: any, currentObject: any) {
            _map[currentObject.id] = {
                filter: currentObject,
                checked: false
            };
            return result;
        }, {});

        setCheckedItemsMap({ ..._map });
        props?.onChange?.(_map);

        let diets = Object.entries(_map)
            .filter(([key, value]: [string, any]) => value?.checked)
            .map(([key, value]: [string, any]) => value?.filter?.diet);

        // filter menuitems by diet
        if (diets && diets?.length > 0) {
            setFilteredMenuitems(filteredMenuitems.filter((m: Menuitem) => diets.indexOf(m.diet) > -1));
        } else {
            setFilteredMenuitems(props?.menuitems);
        }
    }

    function handleSavePress() {
        props?.onConfirmPress?.(checkedItemsMap);
    }

    function handleClosePress() {
        props?.onClosePress?.();
    }

    return (
        <>
            <IconButton
                icon='general_fill_close'
                color="white"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}
                onPress={handleClosePress}
            />

            <View style={{
                marginTop: 12,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Text variant="headingBold"
                    style={{
                        fontSize: 18,
                        lineHeight: 27
                    }}
                >{t('bottomDrawer.menuFilter.title')}</Text>

            </View>

            <Button
                text='Reset'
                color="white"
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 1
                }}
                onPress={handleResetPress}
            />

            <ScrollView>

                {filter.map((filterItem, index) => (
                    <TouchableRipple
                        key={index}
                        onPress={() => handleFilterItemPress(filterItem)}
                        style={{
                            padding: 16,
                            borderBottomColor: index !== filter.length - 1 ? colors.greyLight : 'unset',
                            borderBottomWidth: index !== filter.length - 1 ? 1 : 0
                        }}
                    >
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 8
                        }}>
                            <View>
                                <Text variant="headingBold" style={styles.name}>
                                    {filterItem.name}
                                </Text>
                            </View>

                            <Checkbox
                                status={checkedItemsMap[filterItem.id]?.["checked"] ? 'checked' : 'unchecked'}
                                onPress={() => handleFilterItemPress(filterItem)}
                            />
                        </View>

                    </TouchableRipple>
                ))}

                <Button
                    color="ketchup"
                    mode="contained"
                    disabled={(filteredMenuitems?.length || 0) === 0}
                    text={t('bottomDrawer.menuFilter.action') + ' (' + (filteredMenuitems?.length || 0) + ')'}
                    size="normal"
                    style={{
                        margin: 16,
                        // width: '100%'
                    }}
                    onPress={handleSavePress}
                ></Button>
            </ScrollView>
        </>
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    name: {
        fontSize: 16,
        lineHeight: 24
    }
});
