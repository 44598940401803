import React, { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements,
    Elements
} from "@stripe/react-stripe-js";
import { ActivityIndicator, View } from "react-native";
import { AppTheme } from "../../../../App";
import { useTheme, Text } from "react-native-paper";
import useAxiosSecure from "../../../hooks/useAxiosSecure";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import { setCurrentOrder, setCurrentOrderPayment } from "../../../core/store/features/currentOrder/currentOrderSlice";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button/Button";
import { StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import { useIsFocused } from "@react-navigation/native";


const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_51Mqt9LHoyFfPnFoEhQbKPtxuC18kXPoUZrKWOtkEWUKvCMUs5qPsMJ3cZ4gQLjO9KI2ea09rlmUVBtZsobiWI855007B2WGlMo';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { colors } = useTheme<AppTheme>();

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [serviceFee, setServiceFee] = useState<number>(0.15);
    const [ready, setReady] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const isFocused = useIsFocused();

    const orderItems = useSelector((state: RootState) => state.shoppingCart.items);


    useEffect(() => {
        if (isFocused) {
            setTotalPrice((Math.round((orderItems.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0) + Number.EPSILON + serviceFee) * 100) / 100));
        }
    }, [JSON.stringify(orderItems)]);

    const styles = makeStyles(colors);
    const { t } = useTranslation();

    const handleSubmit = async () => {
        if (processing) return;
        setError('');
        setProcessing(true);
        var current_url = window.location.href;
        const result = await stripe?.confirmPayment({
            elements: elements!,
            confirmParams: {
                return_url: current_url,
            }
        });
        setProcessing(false);
        if (result?.error) {
            setError(result.error.message || '');
        }
    };

    return (
        <View>
            <form>
                <PaymentElement
                    onReady={() => setReady(true)}
                />
                
                {error && <Text
                    style={{
                        color: colors.ketchup
                    }}
                >
                    Error: {error}
                </Text>}

                <Button
                    mode="contained"
                    text={t('paymentScreen.payStep.action') + ' • ' + totalPrice + ' €'}
                    disabled={!stripe || !elements || !ready || processing}
                    color="ketchup"
                    onPress={() => handleSubmit()}
                    style={{
                        marginTop: 48,
                        width: '100%'
                    }}
                />
                {/* </View> */}
            </form>

        </View>
    );
}

const StripeElement = () => {
    const [clientSecret, setClientSecret] = useState<string>('');

    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);
    const axiosSecure = useAxiosSecure();
    const dispatch = useDispatch();

    const currentOrder = useSelector((state: RootState) => state.currentOrder);

    useEffect(() => {
        // fetch client secret
        fetchOrderPaymentIntent().then((clientSecret) => {
            setClientSecret(clientSecret);
        });
    }, []);

    async function fetchOrderPaymentIntent() {
        const paymentAmount = currentOrder.paymentAmount;

        let response = await axiosSecure.get(`/api/order_payment?paymentAmount=${(paymentAmount * 100).toFixed(0)}`);

        if (response.status === 200) {
            if (response.data?.paymentStatus === 'SUCCEEDED') {
                // TODO: show success screen
            }
            if (response.data?.clientSecret) {
                dispatch(setCurrentOrderPayment(response.data));
                return response.data.clientSecret;
            }
        }
    }

    const appearance = {
        theme: 'flat' as 'flat',
        variables: {
            fontFamily: 'Inter',
            borderRadius: '4px',
            spacingUnit: '8px',
            colorPrimary: colors.ketchup,
            colorBackground: colors.greyLight,
            colorText: colors.black,
            accessibleColorOnColorPrimary: colors.black
        },
        rules: {
            '.Block': {
                backgroundColor: 'var(--colorBackground)',
                boxShadow: 'none',
                padding: '12px'
            },
            '.Label': {
                fontFamily: 'var(--fontFamily)',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '24px',
                color: colors.black
            },
            '.Input': {
                padding: '12px 16px'
            },
            '.Input:disabled, .Input--invalid:disabled': {
                color: colors.grey
            },
            '.Input--empty': {
                color: colors.grey
            },
            '.Tab': {
                padding: '10px 12px 8px 12px',
                border: 'none'
            },
            '.Tab:hover': {
                border: 'none',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
                border: 'none',
                backgroundColor: '#fff',
                boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            }
        }
    };

    return (
        <>
            <View>
                {clientSecret && (
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret,
                            appearance
                        }}
                    >
                        <CheckoutForm />
                    </Elements>
                )}
                {!clientSecret && (
                    <View
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ActivityIndicator animating={true} size={'large'} color={colors.ketchup} />
                    </View>
                )}
            </View>
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        width: '100%',
        marginTop: 48
    }
});

export default StripeElement;