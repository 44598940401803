
import React, { } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Text, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Button from "../Button/Button";
import { Menuitem } from "../../core/interfaces/menuitem.interface";
import { useTranslation } from "react-i18next";
import IconButton from "../IconButton/IconButton";


export interface AllergensProps {
    style?: any;
    menuitem: Menuitem;
    onConfirmPress?: () => void;
    onClosePress?: () => void;
}

export default function Allergens(props: AllergensProps) {

    const { colors } = useTheme<AppTheme>();

    const { t } = useTranslation();

    function handleConfirmPress() {
        props?.onConfirmPress?.();
    }

    function handleClosePress() {
        props?.onClosePress?.();
    }

    return (
        <>
            <IconButton
                icon='general_fill_close'
                color="white"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}
                onPress={handleClosePress}
            />

            <View style={{
                marginTop: 12,
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Text variant="headingBold"
                    style={{
                        fontSize: 18,
                        lineHeight: 27
                    }}
                >{t('bottomDrawer.allergens.title')}</Text>

            </View>

            <View
                style={{
                    margin: 16,
                    gap: 32,
                    ...props?.style
                }}
            >
                <ScrollView
                    contentContainerStyle={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: 8
                    }}>
                    {props?.menuitem?.allergens?.split(',')?.filter(Boolean)?.map((a: string) => (
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            gap: 8
                        }}
                            key={a}>
                            <Text>{a}.</Text>
                            <Text>{t(`bottomDrawer.allergens.allergens.${a}`)}</Text>
                        </View>
                    ))}
                </ScrollView>

                <Button
                    style={{
                        width: '100%'
                    }}
                    mode='contained'
                    color="ketchup"
                    onPress={handleConfirmPress}
                    text={t(`bottomDrawer.allergens.action`)}
                />
            </View>
        </>
    );
}
