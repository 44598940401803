
import React from "react";
import { View, StyleSheet, Image } from "react-native";
import { Button as _Button, IconButton, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { BrambleColor, Severity } from "../../types/theme";
import { capitalize, margin } from "../../utils/utilities";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";


export interface ActionCardProps {
    title: string;
    subTitle: string;
    image?: any;
    size?: 'big' | 'small';
    backgroundColor: BrambleColor;
    circleColor?: BrambleColor | string;
    style?: any;
    containerStyle?: any;
    imageStyle?: any;
    onPress?: () => void;
}

export default function ActionCard({
    title,
    subTitle,
    image,
    size = 'big',
    backgroundColor,
    circleColor,
    style,
    containerStyle,
    imageStyle,
    onPress
}: ActionCardProps) {

    const theme = useTheme<AppTheme>();
    const { t } = useTranslation();
    const styles = makeStyles(theme);

    // let _on: any = "on" + capitalize(color).toString();
    // let _dark: any = color + "Dark";
    let _backgroundColor = theme.colors[backgroundColor];

    return (
        <TouchableRipple
            style={{
                ...styles.container,
                backgroundColor: _backgroundColor,
                ...containerStyle
            }}
            onPress={() => onPress?.()}
        >
            <View
                style={size === "big" ? styles.contentBig : styles.contentSmall}
            >
                <View style={{
                    width: size === "small" ? "50%" : undefined
                }}>
                    <Text variant="headingBold" style={styles.title} numberOfLines={1}>{title}</Text>
                    <Text variant="paragraphMedium" style={styles.subtitle} numberOfLines={2}>{subTitle}</Text>
                </View>

                <Image
                    style={{
                        width: size === "big" ? 108 : 54,
                        height: size === "big" ? 106 : 70,
                        ...styles.imageStyle,
                        ...imageStyle
                    }}
                    source={{ uri: image }}
                />

                {circleColor && <View style={{
                    backgroundColor: circleColor,
                    borderRadius: '50%',
                    width: '100%',
                    height: '60%',
                    zIndex: -1,
                    position: 'absolute',
                    bottom: '-35%'
                }} />}
            </View>
        </TouchableRipple >
    );
}



const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        zIndex: 1,
        flex: 1,
        position: 'relative',
        borderRadius: 4
    },
    contentBig: {
        overflow: 'hidden',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        paddingHorizontal: 16,
        paddingTop: 16
    },
    contentSmall: {
        overflow: 'hidden',
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 16
        // gap: 8
    },
    imageStyle: {
        // position: 'absolute',
        bottom: 0
    },
    title: {
        fontSize: 18,
        lineHeight: 27
    },
    subtitle: {
        fontSize: 12,
        lineHeight: 18
    }
});