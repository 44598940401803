import * as React from 'react';
import { View, Image, ImageBackground, useWindowDimensions, StyleSheet, Linking } from "react-native";
import { Modal, Portal, Text, useTheme } from 'react-native-paper';
import { AppTheme } from '../../../App';
import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import { BlurView } from 'expo-blur';


export default function QrCodeOverlay() {

    const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= 1400);
    const dimension = useWindowDimensions();

    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors)

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setIsDesktop(window.innerWidth >= 1400);
    }

    return (
        <Portal>
            <Modal visible={isDesktop} dismissable={false}>
                <ImageBackground
                    resizeMode={'cover'}
                    style={{
                        ...styles.backgroundImage,
                        width: dimension.width - 1,
                        height: dimension.height
                    }}
                    source={require("../../../assets/images/desktop_background.jpg")}
                >
                    <View
                        style={styles.headerContainer}
                    >
                        <Image style={{
                            width: 180,
                            height: 40
                        }} source={require('../../../assets/images/logo_white.png')} />

                        <Button
                            color="white"
                            mode="contained"
                            text={"Learn more about Bramble"}
                            size="medium"
                            endIcon="arrows_outline_arrow_up_right"
                            onPress={() => Linking.openURL('https://bramble.sk')}
                        ></Button>
                    </View>

                    <BlurView
                        intensity={70}
                        tint="light"
                        style={styles.container}
                    >
                        <Image
                            style={{
                                width: 140,
                                height: 140,
                            }}
                            source={require("../../../assets/images/qr-code-2.svg")}
                        />

                        <Text variant="headingBlack" style={styles.title}>Use your phone for the best experience</Text>
                        <Text variant="paragraphRegular" style={styles.subtitle}>Scan the QR code above to unlock Bramble's full potential.</Text>
                        <Text variant="paragraphRegular" style={styles.subquestion}>Seeing this message on mobile device? Open in mobile view</Text>
                    </BlurView >
                </ImageBackground>
            </Modal>
        </Portal>
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    backgroundImage: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        width: '640px',
        height: '470px',
        backgroundColor: colors.white,
        borderRadius: 4,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 48,
        gap: 32
    },
    headerContainer: {
        position: 'absolute',
        top: 0,
        // left: '50%'
        paddingVertical: 16,
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: 40,
        lineHeight: 50,
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 18,
        lineHeight: 27
    },
    subquestion: {
        fontSize: 12,
        lineHeight: 18,
        color: colors.greyMedium
    }
});