import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export const festivalSlice = createSlice({
    name: 'festival',
    initialState: {
        festivals: [] as any[]
    },
    reducers: {
        addFestivals: (state, action: PayloadAction<any[]>) => {
            if (state.festivals.length > 0) {
                let newFestivals = action.payload.filter(c => state.festivals.map(i => i.id).indexOf(c.id) === -1);
                if (newFestivals && newFestivals.length > 0) {
                    state.festivals = [...state.festivals, ...newFestivals];
                }
            } else {
                state.festivals = action.payload;
            }
        },
        addFestival: (state, action: PayloadAction<any>) => {
            state.festivals.push(action.payload);
        },
        updateFestival: (state, action: PayloadAction<any>) => {
            const festival = state.festivals.find(c => c.id === action.payload.id) as any;
            Object.assign(festival, action.payload);
        },
        updateFestivals: (state, action: PayloadAction<Partial<any>[]>) => {
            for (let change of action.payload) {
                const festival = state.festivals.find(c => c.id === change.id) as any;
                Object.assign(festival, change);
            }
        },
        deleteFestival: (state, action: PayloadAction<string>) => {
            state.festivals = [...state.festivals.filter(c => c.id !== action.payload)];
        },
        deleteFestivals: (state, action: PayloadAction<string[]>) => {
            state.festivals = [...state.festivals.filter(c => action.payload.indexOf(c.id) === -1)];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    addFestivals,
    addFestival,
    updateFestival,
    updateFestivals,
    deleteFestival,
    deleteFestivals
} = festivalSlice.actions

export default { festivalReducer: festivalSlice.reducer }
