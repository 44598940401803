import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Cuisine } from "../interfaces/cuisine.interface";



export const dataSaverSelector = createSelector((state: RootState) => state.ui.dataSaver, (dataSaver) => dataSaver)
export const menuFiltersSelector = createSelector((state: RootState) => state.ui.menuFilters, (menuFilters) => menuFilters)


export const selectedFestivalCodeNameSelector = createSelector((state: RootState) => state.ui.selectedFestivalCodeName, (selectedFestivalCodeName) => selectedFestivalCodeName)

export const orderItemsSelector = createSelector((state: RootState) => state.shoppingCart.items, (orderItems) => orderItems)

const selectFestivals = (state: RootState) => state.festival.festivals;

export const festivalsSelector = createSelector((state: RootState) => state.festival.festivals, (festivals) => festivals)

export const selectedFestivalSelector = createSelector(
    [selectFestivals, selectedFestivalCodeNameSelector],
    (festivals, selectedFestivalCodeName) => festivals.find(i => i.codeName === selectedFestivalCodeName)
)

export const festivalByCodeNameSelector = createSelector(
    [selectFestivals, (state, codeName) => codeName],
    (festivals, codeName) => festivals.find(i => i.codeName === codeName)
)

export const currentOrderSelector = createSelector((state: RootState) => state.currentOrder.currentOrder, (currentOrder) => currentOrder)
export const currentOrderPaymentSelector = createSelector((state: RootState) => state.currentOrder.currentOrderPayment, (currentOrderPayment) => currentOrderPayment)

const selectRestaurants = (state: RootState) => state.restaurant.restaurants;
const selectUiFavouriteRestaurants = (state: RootState) => state.ui.favouriteRestaurants;
const selectFestivalCodeName = (state: RootState) => state.ui.selectedFestivalCodeName;

export const restaurantsSelector = createSelector(
    [selectRestaurants, selectUiFavouriteRestaurants],
    (restaurants, uiFavouriteRestaurants) => restaurants.map(restaurant => {
        if ((uiFavouriteRestaurants || []).indexOf(restaurant.id) > -1) {
            return ({ ...restaurant, _isFavourite: true })
        } {
            return ({ ...restaurant, _isFavourite: false })
        }
    })
)

export const foodRestaurantsSelector = createSelector(
    [selectRestaurants, selectUiFavouriteRestaurants],
    (restaurants, uiFavouriteRestaurants) => restaurants.filter(r => r.isFood).map(restaurant => {
        if ((uiFavouriteRestaurants || []).indexOf(restaurant.id) > -1) {
            return ({ ...restaurant, _isFavourite: true })
        } {
            return ({ ...restaurant, _isFavourite: false })
        }
    })
)


export const recomendedRestaurantsSelector = createSelector(
    [restaurantsSelector, selectFestivalCodeName],
    (restaurants, selectFestivalCodeName,) => {
        if (selectFestivalCodeName) {
            return restaurants.filter((fr: any) => fr.isFood)?.filter(r => r.festivalRestaurants?.filter((fr: any) => fr.festivalCodeName === selectFestivalCodeName && fr.isRecommended)?.length > 0);
        } else {
            return restaurants.filter((fr: any) => fr.isFood);
        }
    }
)

export const favouriteRestaurantsSelector = createSelector(
    [restaurantsSelector],
    (restaurants) => restaurants.filter(i => i.isFood).filter(i => i._isFavourite === true)
)

export const restaurantByCodeNameSelector = createSelector(
    [restaurantsSelector, (state, codeName) => codeName],
    (restaurants, codeName) => restaurants.find(i => i.codeName === codeName)
)

export const restaurantByCuisineCodeNameSelector = createSelector(
    [restaurantsSelector, (state, codeName) => codeName],
    (restaurants, codeName) => restaurants.filter(r => r.cuisines?.map((c: Cuisine) => c.codeName)?.indexOf(codeName) > -1)
)