import uuid from 'react-native-uuid';

export function newGuid() {
  return uuid.v4().toString();
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function isArraySame(a: any[], b: any[]) {
  const array2Sorted = b.slice().sort();
  return a.length === b.length && a.slice().sort().every(function (value, index) {
      return value === array2Sorted[index];
  });
}

export function validateEmail(email: string) {
  return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const colorShade = (col: any, amt: number) => {
  col = col.replace(/^#/, '')
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

  let [r, g, b] = col.match(/.{2}/g);
  ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

  r = Math.max(Math.min(255, r), 0).toString(16)
  g = Math.max(Math.min(255, g), 0).toString(16)
  b = Math.max(Math.min(255, b), 0).toString(16)

  const rr = (r.length < 2 ? '0' : '') + r
  const gg = (g.length < 2 ? '0' : '') + g
  const bb = (b.length < 2 ? '0' : '') + b

  return `#${rr}${gg}${bb}`
}

export function contrastColor(bgColor: string, lightColor: string, darkColor: string) {
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return (L > 0.179) ? darkColor : lightColor;
}

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1);
}

const getShortHand = (style: string, ...values: any) => {
  if (values.length === 1) {
    return { [style]: values[0] }
  }
  const _genCss = (...values: any) => ({
    [style + 'Top']: values[0],
    [style + 'Right']: values[1],
    [style + 'Bottom']: values[2],
    [style + 'Left']: values[3],
  })
  if (values.length === 2) {
    return _genCss(values[0], values[1], values[0], values[1])
  }
  if (values.length === 3) {
    return _genCss(values[0], values[1], values[2], values[1])
  }
  return _genCss(values[0], values[1], values[2], values[3])
}

export const padding = (...values: Array<number | string>) => getShortHand('padding', ...values)
export const margin = (...values: Array<number | string>) => getShortHand('margin', ...values)