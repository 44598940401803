import React, { useEffect, useState } from "react";
import { ScrollView, InteractionManager, Platform } from "react-native";
import { RootStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useTheme } from "react-native-paper";
import useAxiosSecure from "../hooks/useAxiosSecure";
import AppHeader from "../components/AppHeader/AppHeader";
import { AppTheme } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../core/store/store";
import { DrawerActions, useFocusEffect } from "@react-navigation/native";
import { RefreshControl } from 'react-native-web-refresh-control';
import { addFestivals } from "../core/store/features/festival/festivalSlice";
import { setSelectedFestivalCodeName } from "../core/store/features/ui/uiSlice";
import FestivalsList from "../components/FestivalsList/FestivalsList";
import { festivalsSelector } from "../core/store/selectors";


export default function ({
  navigation, route
}: NativeStackScreenProps<RootStackParamList, "home">) {

  const festivals = useSelector(festivalsSelector);

  const [refreshing, setRefreshing] = useState(false);

  const axiosSecure = useAxiosSecure();
  const { colors } = useTheme<AppTheme>();
  const dispatch = useDispatch();

  async function apiLoadFestivalsCall() {
    let festival_response = await axiosSecure.get(`/api/festival`, false);
    dispatch(addFestivals(festival_response.data));
  }

  // useEffect(() => {
  //   apiLoadFestivalsCall();
  // }, [])

  // load after animation end
  useFocusEffect(
    React.useCallback(() => {
      const task = InteractionManager.runAfterInteractions(() => {
        apiLoadFestivalsCall();
      });

      return () => task.cancel();
    }, [])
  );

  async function onRefresh() {
    setRefreshing(true);
    try {
      await apiLoadFestivalsCall();
    } catch { }
    setRefreshing(false);
  };

  function handleFestivalPress(e: any) {
    dispatch(setSelectedFestivalCodeName(e));
    navigation.navigate('festival', { festivalCodeName: e })
  }

  function handOnMenuPress() {
    navigation.dispatch(DrawerActions.openDrawer());
  }

  return (
    <>

      <AppHeader
        onMenuPress={handOnMenuPress}
        onSearchPress={() => { }}
      />

      <ScrollView
        bounces={false} // iOS only - prevent drag whole view if it reach bottom
        refreshControl={
          <RefreshControl refreshing={refreshing}
            onRefresh={onRefresh} />
        }
        style={{
          backgroundColor: 'white',
          height: Platform.OS === 'web' ? 10 : undefined
        }}>

        <FestivalsList
          festivals={festivals}
          onPress={handleFestivalPress}
        ></FestivalsList>

      </ScrollView>
    </>
  );
}