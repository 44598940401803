import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, Platform, ActivityIndicator, useWindowDimensions } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, useTheme } from "react-native-paper";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import Button from "../components/Button/Button";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { ShoppingCartItem, addItemToShoppingCart, clearShoppingCart, updateItemInShoppingCart } from "../core/store/features/shoppingCart/shoppingCartSlice";
import TextField from "../components/TextField/TextField";
import MenuItemInfo from "../components/MenuItemInfo/MenuItemInfo";
import ExtraCategory from "../components/ExtraCategory/ExtraCategory";
import { sleep } from "../utils/utilities";
import { useIsFocused } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { dataSaverSelector, orderItemsSelector, selectedFestivalCodeNameSelector } from "../core/store/selectors";
import { MENUITEM_SCREEN_LOADING_TIME } from "../core/constants";
import { openDialog } from "../core/store/features/dialog/dialogSlice";
import { setBottomSheetContext, setBottomSheetOpen } from "../core/store/features/bottomSheet/bottomSheetSlice";
import Allergens from "../components/Allergens/Allergens";
import OrderInProgress from "../components/OrderInProgress/OrderInProgress";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "menuitem">) {

    const axiosSecure = useAxiosSecure();
    const theme = useTheme<AppTheme>();
    const dispatch = useDispatch();

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [savedMoney, setSavedMoney] = useState<number>(0);
    const [checkedExtraMenuItemsMap, setCheckedExtraMenuItemsMap] = useState<any>({});
    const [note, setNote] = useState<string | null>();
    const [amout, setAmout] = useState<number>(1);
    const [menuitem, setMenuitem] = useState<Menuitem>();
    const [orderItem, setOrderItem] = useState<ShoppingCartItem>();
    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);
    const dataSaver = useSelector(dataSaverSelector);
    const orderItems = useSelector(orderItemsSelector);
    const [loaded, setLoaded] = useState<boolean>(false);

    const { t } = useTranslation();
    const isFocused = useIsFocused();
    const dimension = useWindowDimensions();
    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);


    async function apiCall() {

        // if (Platform.OS === 'web') {
        //     await sleep(100);
        // }

        if (Platform.OS === 'web' && !loaded && dataSaver) {
            await sleep(100);
        }

        // TODO: optimalize if need
        let filter = `id=${route.params.menuitemId}`;
        // if menuitemId is provided, load item for "add" into shopping cart
        if (route?.params?.menuitemId && route?.params?.menuitemId != 'undefined') {
            filter = `id=${route.params.menuitemId}`;
        } else if (route?.params?.orderItemId) {
            // if orderItemId is provided, set screen for "update" shopping cart
            let item = orderItems.find(i => i.id === route.params.orderItemId);
            setOrderItem(item);
            setAmout(item?.amout || 1);
            setNote(item?.note);
            filter = `id=${item?.menuitem.id}`;
        }

        let response = await axiosSecure.get(`/api/menuitem?${filter}`, false);

        setMenuitem(response.data?.[0]);

        if (Platform.OS === 'web' && !loaded && dataSaver) {
            setLoaded(true);
        }
        // setLoaded(true);
    }

    useEffect(() => {
        if (isFocused) {
            apiCall();
            if (Platform.OS === 'web' && !loaded && !dataSaver) {
                setInterval(() => {
                    setLoaded(!loaded);
                }, MENUITEM_SCREEN_LOADING_TIME);
            }
        }

    }, [route?.params?.menuitemId]);

    useEffect(() => {
        if (isFocused) {
            computeTotalPrice();
        }

    }, [menuitem, checkedExtraMenuItemsMap, amout]);

    function handleExtraCategoryCheckedItemsChange(event: any) {
        setCheckedExtraMenuItemsMap({ ...checkedExtraMenuItemsMap, ...event });
    }

    function computeTotalPrice() {
        let _totalPrice = 0;
        
        if (menuitem?.discountPrice && menuitem?.discountPrice > 0) {
            _totalPrice = menuitem?.discountPrice || 0;
        } else {
            _totalPrice = menuitem?.price || 0;
        }

        for (const [key, value] of Object.entries(checkedExtraMenuItemsMap)) {
            if ((value as any).checked) {
                _totalPrice += (value as any).extraMenuitem.price;
            }
        }

        setTotalPrice(Math.round(((_totalPrice * amout) + Number.EPSILON) * 100) / 100);
    }

    function handleAddItemIntoCart() {
        function addItem() {
            let extraMenuitems = [];

            for (const [key, value] of Object.entries(checkedExtraMenuItemsMap)) {
                if ((value as any).checked) {
                    extraMenuitems.push((value as any).extraMenuitem)
                }
            }

            let item: ShoppingCartItem = {
                menuitem: menuitem!,
                amout: amout,
                note: note,
                extraMenuitems: extraMenuitems,
                totalPrice: totalPrice
            }

            if (orderItem) {
                dispatch(updateItemInShoppingCart({ ...item, id: orderItem.id }));
            } else {
                dispatch(addItemToShoppingCart(item));
            }

            navigation.goBack();
        }

        if (orderItems?.length > 0) {
            let cartContainsItemsFromAnotherRestaurant = orderItems?.map(i => i.menuitem?.restaurantId)?.indexOf(menuitem?.restaurantId) === -1;

            if (cartContainsItemsFromAnotherRestaurant) {
                let restaurantName = "";
                try {
                    restaurantName = orderItems?.map(i => i.menuitem?.restaurantName)[0] || "";
                } catch { }

                dispatch(setBottomSheetContext({
                    isOpen: true,
                    initialHeight: dimension.height * 0.77,
                    content: (<OrderInProgress
                        imageStyle={{
                            resizeMode: "contain",
                            minHeight: (dimension.height * 0.7) / 2,
                            maxHeight: 300,
                            width: '100%',
                            backgroundColor: '#FFECFF'
                        }}
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: dimension.height * 0.77
                        }}
                        subtitle={t(`bottomDrawer.orderInProgress.subtitle1`) + restaurantName + t(`bottomDrawer.orderInProgress.subtitle2`)}
                        onCancelPress={() => {
                            let restaurantCodeNames = orderItems?.map(i => i.menuitem?.restaurantCodeName)
                            if (restaurantCodeNames && restaurantCodeNames?.length > 0) {
                                navigation.navigate('restaurant', { restaurantCodeName: restaurantCodeNames[0] as string, festivalCodeName: selectedFestivalCodeName as string });
                            }
                            dispatch(setBottomSheetOpen(false))
                        }}
                        onConfirmPress={() => {
                            dispatch(clearShoppingCart());
                            addItem();
                            dispatch(setBottomSheetOpen(false))
                        }}
                    />),
                }));
            } else {
                addItem();
            }
        } else {
            addItem();
        }
    }

    function handleNoteChangeText(event: any) {
        setNote(event);
    }

    function handleAmoutPlus() {
        setAmout(amout + 1);
    }

    function handleAmoutMinus() {
        if (amout - 1 < 1) {
            setAmout(1);
        }
        else {
            setAmout(amout - 1);
        }
    }

    function handleGoBack() {
        navigation.goBack();
    }

    function handleAllergensPress() {

        dispatch(setBottomSheetContext({
            isOpen: true,
            initialHeight: dimension.height * 0.5,
            content: (
                <Allergens
                    style={{ height: (dimension.height * 0.5) - 70 }}
                    menuitem={menuitem as Menuitem}
                    onClosePress={() => dispatch(setBottomSheetOpen(false))}
                    onConfirmPress={() => dispatch(setBottomSheetOpen(false))}
                />
            )
        }));
    }

    function canAdd() {
        if (!menuitem?.restaurantIsOpen) {
            return true;
        }

        let satisfied: any = {};
        let mandatory: any = {};

        menuitem?.menuitemExtraCategories.forEach((menuitemExtraCategory, index) => {
            if (!(menuitemExtraCategory.extraCategoryId in mandatory)) {
                mandatory[menuitemExtraCategory.extraCategoryId] = false;
            }
            if (!(menuitemExtraCategory.extraCategoryId in satisfied)) {
                satisfied[menuitemExtraCategory.extraCategoryId] = true;
            }
            if (menuitemExtraCategory.extraCategory.isMandatory) {
                mandatory[menuitemExtraCategory.extraCategoryId] = true;
                satisfied[menuitemExtraCategory.extraCategoryId] = false; // if category is mandatory, mark it as unsatisfied
            }
        })


        for (const [key, value] of Object.entries(mandatory)) {
            if (value) {
                for (const [key2, value2] of Object.entries(checkedExtraMenuItemsMap)) {
                    if (key === (value2 as any).extraCategoryId) {
                        if ((value2 as any).checked) {
                            satisfied[(value2 as any).extraCategoryId] = true;
                        }
                    }
                }
            }
        }

        return !Object.values(satisfied).every(Boolean);
    }

    return (
        <>
            {loaded && <View
                style={styles.stickyActionContainer}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        gap: 8
                    }}
                >
                    <IconButton
                        icon='ecommerce_fill_minus'
                        variant="primary"
                        color={amout === 1 ? "grey" : "black"}
                        size="medium"
                        onPress={handleAmoutMinus}
                    />

                    <View style={{
                        width: 32,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Text style={styles.amout}>{amout}</Text>
                    </View>

                    <IconButton
                        icon='ecommerce_fill_plus'
                        variant="primary"
                        color="black"
                        size="medium"
                        onPress={handleAmoutPlus}
                    />
                </View>

                <Button
                    color="ketchup"
                    mode="contained"
                    disabled={canAdd()}
                    text={t('menuItemScreen.action') + ' • ' + totalPrice + ' €'}
                    size="normal"
                    style={{
                        flex: 1
                    }}
                    onPress={handleAddItemIntoCart}
                ></Button>
            </View>}

            <IconButton
                icon='arrows_outline_arrow_left'
                color="white"
                style={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    zIndex: 1
                }}
                onPress={handleGoBack}
            />

            {!loaded &&
                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        marginBottom: 48,
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    {/* <Skeleton colors={colors} /> */}
                    <ActivityIndicator animating={true} size={'large'} color={theme.colors.ketchup} />
                </View>
            }

            {loaded && <ScrollView
                bounces={false} // iOS only - prevent drag whole view if it reach bottom
                style={{
                    backgroundColor: theme.colors.white,
                    marginBottom: 48,
                    height: Platform.OS === 'web' ? 10 : undefined
                }}>

                <MenuItemInfo
                    saveDataMode={dataSaver}
                    menuitem={menuitem}
                    onAllergensPress={handleAllergensPress}
                />

                {menuitem?.menuitemExtraCategories.map((menuitemExtraCategory, index) => (
                    <View key={menuitemExtraCategory.extraCategory.id}>
                        <View style={styles.divider}></View>
                        <ExtraCategory
                            checkedIds={orderItem?.extraMenuitems?.map(i => i?.id)}
                            extraCategory={menuitemExtraCategory.extraCategory}
                            onCheckedItemChange={handleExtraCategoryCheckedItemsChange}
                        />
                    </View>
                ))}

                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        padding: 16,
                        paddingBottom: 48
                    }}>
                    <TextField
                        numberOfLines={2}
                        multiline={true}
                        maxLength={150}
                        label={t('menuItemScreen.placeholder')}
                        borderRadius={4}
                        value={note as string}
                        onChangeText={handleNoteChangeText}
                    ></TextField>
                </View>

            </ScrollView>}
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    stickyActionContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        gap: 16,
        backgroundColor: colors.white,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    container: {
        backgroundColor: 'white',
        borderRadius: 0
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    infoContainer: {
        marginTop: 16,
        // marginLeft: 8
    },
    header: {
        lineHeight: 42,
        fontSize: 28,
        marginTop: 16,
        marginBottom: 16
    },
    descriptionRow: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: "flex-start",
        // margin: 8,
        gap: 8
    },
    description: {
        lineHeight: 24,
        fontSize: 16
    },
    amout: {
        lineHeight: 24,
        fontSize: 16,
        fontWeight: "700" as "700"
    },
    textBold: {
        fontWeight: 'bold'
    },
    cover: {
        width: '100%',
        height: 230
    },
    divider: {
        height: 16,
        backgroundColor: colors.offWhite
    }
});
