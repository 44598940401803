import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface DialogState {
    dismissable?: boolean | null | undefined;
    visible?: boolean | null | undefined;
    style?: any | null | undefined;
    title: string | null | undefined;
    content: string | null | undefined | JSX.Element;
    yesActionLabel?: string | null | undefined;
    noActionLabel?: string | null | undefined;
    showNo?: boolean | null | undefined;
    onYes?: () => void;
    onNo?: () => void;
}

const initialState: DialogState = {
    dismissable: true,
    visible: false,
    style: null,
    title: null,
    content: null,
    yesActionLabel: null,
    noActionLabel: null,
}

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog: (state, action: PayloadAction<DialogState>) => {
            state.dismissable = action.payload.dismissable;
            state.visible = action.payload.visible;
            state.style = action.payload.style;
            state.title = action.payload.title;
            state.content = action.payload.content;
            state.yesActionLabel = action.payload.yesActionLabel || 'Yes';
            state.noActionLabel = action.payload.noActionLabel || 'No';
            state.showNo = action.payload.showNo != null ? action.payload.showNo : true;
            state.onYes = action.payload.onYes;
            state.onNo = action.payload.onNo;
        },
        closeDialog: (state) => {
            state.visible = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const { openDialog, closeDialog } = dialogSlice.actions

export default { dialogReducer: dialogSlice.reducer } 
