import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView, useWindowDimensions, Platform, ActivityIndicator } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Checkbox, Text, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import Button from "../components/Button/Button";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { RootState } from "../core/store/store";
import { useTranslation } from "react-i18next";
import TextField from "../components/TextField/TextField";
import { HelpEnum } from "../types/enums";
import { sleep, validateEmail } from "../utils/utilities";
import { selectedFestivalCodeNameSelector } from "../core/store/selectors";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "helpOrder">) {

    const dispatch = useDispatch();

    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);

    const [email, setEmail] = useState<string | null>("");
    const [note, setNote] = useState<string | null>("");
    const [phoneNumber, setPhoneNumber] = useState<string | null>("");
    const [processing, setProcessing] = useState<boolean>(true);
    const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);

    const axiosSecure = useAxiosSecure();
    const dimension = useWindowDimensions();
    const { t } = useTranslation();
    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);

    useEffect(() => {
        async function processAnimation() {
            // only for testing
            setProcessing(true);
            // await sleep(500);

            let subject = "Other";

            switch(route.params.type){
                case 1:
                    subject = "Payment";
                    break;                    
                case 2:
                    subject = "Order";
                    break;
                case 3:
                    subject = "Other";
                    break;
            }

            let response = await axiosSecure.post(`/api/help/send`, {
                "email": email,
                "subject": subject,
                "phone": phoneNumber,
                "message": note,
                "orderId": route.params.orderId
            }, false);

            // TODO: send mail
            setProcessing(false);
            setStep(1);
        }

        if (step === 1) {
            processAnimation();
        }

    }, [step]);

    function handleEmailChangeText(event: any) {
        setEmail(event);
    }

    function handleNoteChangeText(event: any) {
        setNote(event);
    }

    function handlePhoneNumberChangeText(event: any) {
        setPhoneNumber(event);
    }

    function canSend() {
        if (privacyPolicy && email && validateEmail(email) && note?.length || 0 > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            <View
                style={styles.headerRow}
            >
                {step === 0 && <IconButton
                    icon='arrows_outline_arrow_left'
                    color="white"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1
                    }}
                    onPress={() =>
                        navigation.goBack()
                    }
                />}

                {route.params.type === HelpEnum.Payment &&
                    <Text variant="headingBlack" style={styles.header}>{t('helpOrderScreen.types.payment.header')}</Text>
                }

                {route.params.type === HelpEnum.Order &&
                    <Text variant="headingBlack" style={styles.header}>{t('helpOrderScreen.types.order.header')}</Text>
                }

                {route.params.type === HelpEnum.Other &&
                    <Text variant="headingBlack" style={styles.header}>{t('helpOrderScreen.types.other.header')}</Text>
                }

            </View>

            <View
                style={styles.container}
            >
                <View
                    style={{
                        backgroundColor: colors.white,
                        padding: 16,
                        gap: 16,
                        height: dimension.height - 56,
                        justifyContent: step === 1 ? 'center' : 'flex-start'
                    }}
                >
                    {step === 0 && <>
                        {route.params.type === HelpEnum.Payment &&
                            <>
                                <Text variant="paragraphRegular" style={styles.paragraph16}>{t('helpOrderScreen.types.payment.title')}</Text>
                                <Text variant="paragraphRegular" style={styles.paragraph16}>{t('helpOrderScreen.steps.step1.subtitle')}</Text>
                            </>
                        }

                        {route.params.type === HelpEnum.Order &&
                            <>
                                <Text variant="paragraphRegular" style={styles.paragraph16}>{t('helpOrderScreen.types.order.title')}</Text>
                                <Text variant="paragraphRegular" style={styles.paragraph16}>{t('helpOrderScreen.steps.step1.subtitle')}</Text>
                            </>
                        }

                        {route.params.type === HelpEnum.Other &&
                            <>
                                <Text variant="paragraphRegular" style={styles.paragraph16}>{t('helpOrderScreen.types.other.title')}</Text>
                                <Text variant="paragraphRegular" style={styles.paragraph16}>{t('helpOrderScreen.steps.step1.subtitle')}</Text>
                            </>
                        }

                        <View>
                            <Text variant="labelMedium" style={styles.paragraph16}>Email
                                <Text variant="paragraphBold" style={{
                                    ...styles.paragraph16,
                                    color: colors.ketchup
                                }}>
                                    *
                                </Text>
                            </Text>

                            <TextField
                                value={email as string}
                                label={t('helpOrderScreen.steps.step1.form.email')}
                                borderRadius={4}
                                onChangeText={handleEmailChangeText}
                            ></TextField>
                        </View>

                        <View>
                            <Text variant="labelMedium" style={styles.paragraph16}>{t('helpOrderScreen.steps.step1.form.phoneNumber.placeholder')}</Text>

                            <TextField
                                value={phoneNumber as string}
                                label={t('helpOrderScreen.steps.step1.form.phoneNumber.label')}
                                borderRadius={4}
                                onChangeText={handlePhoneNumberChangeText}
                            ></TextField>
                        </View>

                        <View>
                            <Text variant="labelMedium" style={styles.paragraph16}>{t('helpOrderScreen.steps.step1.form.note.placeholder')}
                                <Text variant="paragraphBold" style={{
                                    ...styles.paragraph16,
                                    color: colors.ketchup
                                }}>
                                    *
                                </Text>
                            </Text>

                            <TextField
                                value={note as string}
                                numberOfLines={2}
                                multiline={true}
                                label={t('helpOrderScreen.steps.step1.form.note.label')}
                                borderRadius={4}
                                onChangeText={handleNoteChangeText}
                            ></TextField>
                        </View>

                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Text variant="headingBold" style={styles.paragraph16}>{t('helpOrderScreen.steps.step1.form.privacyPolicy')}</Text>
                            <Checkbox
                                status={privacyPolicy ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setPrivacyPolicy(!privacyPolicy);
                                }}
                            />
                        </View>

                        <Button
                            disabled={!canSend()}
                            color="black"
                            mode="contained"
                            text={t('helpOrderScreen.steps.step1.form.actions.send')}
                            onPress={() => { setStep(1) }}
                        ></Button>
                    </>}

                    {step === 1 && <View style={{
                        width: '100%',
                        padding: 16
                    }}>

                        {processing && <ActivityIndicator animating={true} size={'large'} color={colors.ketchup} />}

                        {!processing && <View style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 16,
                            gap: 16
                        }}>
                            <Image
                                style={{
                                    width: 96,
                                    height: 96
                                }}
                                source={require("../../assets/images/animation/success.gif")}
                            />

                            <Text variant="headingBlack" style={styles.paragraph24}>{t('helpOrderScreen.steps.step2.title')}</Text>

                            <View style={{
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Text variant="paragraphMedium" style={styles.paragraph16}>{t('helpOrderScreen.steps.step2.subtitle')}</Text>
                            </View>

                            <Button
                                color="ketchup"
                                mode="contained"
                                size="medium"
                                text={t('helpOrderScreen.steps.step2.actions.back')}
                                style={{
                                    margin: 16,
                                    // width: '100%'
                                }}
                                onPress={() => navigation.navigate("festival", {
                                    festivalCodeName: selectedFestivalCodeName as string
                                })}
                            ></Button>
                        </View>}
                    </View>}
                </View>

            </View>
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: colors.white
    },
    paragraph24: {
        lineHeight: 30,
        fontSize: 24,
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
    headerRow: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        width: '100%',
        backgroundColor: colors.white
    },
    header: {
        lineHeight: 27,
        fontSize: 18
    }
});
