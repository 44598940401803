
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, TouchableHighlight } from "react-native";
import { Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Badge from "../Badge/Badge";
import { Menuitem } from "../../core/interfaces/menuitem.interface";
import axios, { MINIO_URL } from "../../core/api/axios";
import { useTranslation } from "react-i18next";


export interface MenuItemProps {
    menuitem: Menuitem;
    styles?: any;
    saveDataMode?: boolean;
    onPress?: () => void;
    onLayout?: (event: any) => void;
}

export default function MenuItem(props: MenuItemProps) {

    const { colors } = useTheme<AppTheme>();
    const [thumbnail, setThumbnail] = useState<string>("../../../assets/images/menuitem.png");

    const { t } = useTranslation();
    const styles = makeStyles(colors);


    async function loadThumbnail() {
        try {
            let response = await axios.get(`${MINIO_URL}/menuitems/${props?.menuitem?.id}`);
            setThumbnail(`${MINIO_URL}/menuitems/${props?.menuitem?.id}`);
        } catch (e) {

        }
    }

    useEffect(() => {
        setThumbnail(`${MINIO_URL}/menuitems/${props?.menuitem?.id}-sm`);
        // loadThumbnail();
    }, [])


    return (
        <TouchableRipple
            onLayout={props?.onLayout}
            onPress={() => props?.onPress?.()}
            style={styles.container}
        >
            <View style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: 16
            }}>
                <View style={{
                    flex: 0.9,
                    gap: 4
                }}>
                    <Text variant="paragraphBold" style={styles.title} numberOfLines={1} ellipsizeMode="tail">{props.menuitem.name}</Text>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 8
                    }}>
                        {props.menuitem.discountPrice && props.menuitem.discountPrice > 0 && <Badge
                            color="ketchup"
                            variant="primary"
                            size="small"
                            dimension="rounded"
                            text={props.menuitem.discountPrice + " €"}
                        />}
                        <Text variant="paragraphMedium" style={
                            props.menuitem.discountPrice && props.menuitem.discountPrice > 0 ? styles.priceStriked : styles.price
                        }>{props.menuitem.price} €</Text>
                    </View>

                    <Text variant="paragraphRegular" style={styles.description} numberOfLines={2} ellipsizeMode="tail">{props.menuitem.weight}, {props.menuitem.description}</Text>

                    <View style={{
                        flexDirection: "row",
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 4
                    }}>
                        {props.menuitem.isPopular && <View style={{
                            flexDirection: "row",
                            justifyContent: 'space-between',
                            alignItems: 'flex-start'
                        }}>
                            <Badge
                                text={t('menuItemScreen.info.isPopular')}
                                color="green"
                                variant="secondary"
                                size="small"
                                dimension="rounded"
                            />
                        </View>}

                        {props.menuitem.isRecommended && <View style={{
                            flexDirection: "row",
                            justifyContent: 'space-between',
                            alignItems: 'flex-start'
                        }}>
                            <Badge
                                text={t('menuItemScreen.info.isRecommended')}
                                color="mustardLight"
                                variant="primary"
                                size="small"
                                dimension="rounded"
                            />
                        </View>}
                    </View>
                </View>

                {!props?.saveDataMode && <Image
                    style={
                        styles.thumbnail
                    }
                    defaultSource={require("../../../assets/images/menuitem.svg")}
                    source={{ uri: thumbnail }}
                />}

            </View>
        </TouchableRipple>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        minHeight: 134,
        backgroundColor: colors.white,
        justifyContent: 'center',
        padding: 16
    },
    title: {
        fontSize: 16,
        lineHeight: 24
    },
    price: {
        fontSize: 14,
        lineHeight: 21
    },
    priceStriked: {
        fontSize: 14,
        lineHeight: 21,
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid'
    },
    description: {
        fontSize: 14,
        lineHeight: 21,
        color: colors.greyMedium
    },
    thumbnail: {
        width: 96,
        height: 96,
        borderRadius: 4,
        resizeMode: "cover"
    }
});
