import React, { useEffect, useState } from "react";
import { ScrollView, View, Image, StyleSheet, Platform, Pressable, useWindowDimensions } from "react-native";
import { RootStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ActivityIndicator, Text, useTheme } from "react-native-paper";
import RestaurantCard from "../components/RestaurantCard/RestaurantCard";
import { Restaurant } from "../core/interfaces/restaurant.interface";
import useAxiosSecure from "../hooks/useAxiosSecure";
import AppHeader from "../components/AppHeader/AppHeader";
import { AppTheme } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { setRestaurants } from "../core/store/features/restaurant/restaurantSlice";
import { DrawerActions } from "@react-navigation/native";
import { addFestivals } from "../core/store/features/festival/festivalSlice";
import { addFavouriteRestaurants, removeFavouriteRestaurants, setSelectedFestivalCodeName } from "../core/store/features/ui/uiSlice";
import { useTranslation } from "react-i18next";
import { dataSaverSelector, festivalByCodeNameSelector, selectedFestivalCodeNameSelector, restaurantsSelector, favouriteRestaurantsSelector, recomendedRestaurantsSelector, foodRestaurantsSelector } from "../core/store/selectors";
import { RefreshControl } from 'react-native-web-refresh-control';
import Icon from "../components/Icon/Icon";
import { RootState } from "../core/store/store";
import { setCuisines } from "../core/store/features/cuisine/cuisineSlice";
import Button from "../components/Button/Button";
import ActionCard from "../components/ActionCard/ActionCard";

export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "festival">) {

    const restaurants = useSelector(foodRestaurantsSelector);
    const cuisines = useSelector((state: RootState) => state.cuisine.cuisines);
    const recomendedRestaurants = useSelector(recomendedRestaurantsSelector);

    const favouriteRestaurants = useSelector(favouriteRestaurantsSelector);

    const dataSaver = useSelector(dataSaverSelector)
    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector)
    const festival = useSelector((state) => festivalByCodeNameSelector(state, route?.params?.festivalCodeName))

    const [refreshing, setRefreshing] = useState(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dimension = useWindowDimensions();
    const styles = makeStyles(colors);


    async function apiLoadFestivalsCall() {
        let festival_response = await axiosSecure.get(`/api/festival`, false);
        dispatch(addFestivals(festival_response.data));
    }

    async function apiLoadRestaurantsCall(festivalCodeName: string) {
        try {
            if (festivalCodeName == null) {
                // navigation.navigate("home");
            } else {
                setLoaded(false);
                // let response = await axiosSecure.get(`/api/restaurant?festivalCodeName=${festivalCodeName}`, false);
                try {
                    let response = await axiosSecure.get(`/api/festival/${festivalCodeName}/screen`, false);

                    dispatch(setRestaurants(response.data.restaurants));
                    dispatch(setCuisines(response.data.cuisines.map((c: any) => c.cuisine)));
                    navigation.setParams({ festivalCodeName: festivalCodeName as string });

                    setInterval(() => {
                        setLoaded(true);
                    }, 50);

                } catch (err) {
                    setLoaded(true);
                }
            }
        } catch (err) {
            navigation.navigate("home");
        }
    }

    useEffect(() => {
        apiLoadFestivalsCall();
    }, [])

    useEffect(() => {
        // if festivalCodeName param is null/undefined/"undefined" -> navigate to home screen
        if ((route?.params?.festivalCodeName == null || route?.params?.festivalCodeName == "undefined")
            && (selectedFestivalCodeName == null || selectedFestivalCodeName == "undefined")) {
            dispatch(setSelectedFestivalCodeName(selectedFestivalCodeName));
            navigation.navigate("home");
        } else {
            // else load restaurants for festival from param
            if (route?.params?.festivalCodeName == null) {
                apiLoadRestaurantsCall(selectedFestivalCodeName as string);
            } else {
                dispatch(setSelectedFestivalCodeName(route?.params?.festivalCodeName));
                apiLoadRestaurantsCall(route?.params?.festivalCodeName);
            }
        }
    }, [route?.params?.festivalCodeName])

    // useEffect(() => {
    //     // on festival change
    //     if (selectedFestivalCodeName != null && selectedFestivalCodeName != "undefined") {
    //         apiLoadRestaurantsCall(selectedFestivalCodeName);
    //     }
    // }, [selectedFestivalCodeName])


    async function onRefresh() {
        setRefreshing(true);
        try {
            if (selectedFestivalCodeName) {
                await apiLoadRestaurantsCall(selectedFestivalCodeName);
            }
        } catch { }
        setRefreshing(false);
    };

    function handOnMenuPress() {
        navigation.dispatch(DrawerActions.openDrawer());
    }

    function handRestaurantPress(restaurant: Restaurant) {
        navigation.navigate('restaurant', { restaurantCodeName: restaurant.codeName, festivalCodeName: selectedFestivalCodeName as string });
    }

    function handleOnFavouritePress(restaurant: Restaurant) {
        if (restaurant._isFavourite) {
            dispatch(removeFavouriteRestaurants([restaurant.id]));
        } else {
            dispatch(addFavouriteRestaurants([restaurant.id]));
        }
    }

    function handleCategoryItemPress(event: any) {
        navigation.navigate("cuisine", { cuisine: event });
    }

    function handOnBannerPress() {
        navigation.navigate("home");
    }

    return (
        <>
            <AppHeader
                header={`${t('festivalScreen.thisIs')} ${festival?.name}`}
                onMenuPress={handOnMenuPress}
                onBannerPress={handOnBannerPress}
                onSearchPress={() => navigation.navigate("search")}
            />

            {/* <Usage/> */}
            <ScrollView
                bounces={false} // iOS only - prevent drag whole view if it reach bottom
                refreshControl={
                    <RefreshControl refreshing={refreshing}
                        onRefresh={onRefresh} />
                }
                contentContainerStyle={{
                    justifyContent: 'space-between',
                    gap: 16
                }}
                style={{
                    backgroundColor: colors.offWhite,
                    height: Platform.OS === 'web' ? 10 : undefined
                }}
            >
                <View>
                    <View
                        style={{
                            backgroundColor: colors.white
                        }}
                    >
                        <Pressable
                            onPress={() => navigation.navigate("search")}
                            style={{
                                backgroundColor: colors.greyLight,
                                borderRadius: 4,
                                height: 48,
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 16,
                                paddingRight: 16,
                                marginHorizontal: 16,
                                marginTop: 16,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Text style={{
                                color: colors.grey,
                                fontSize: 16,
                                fontWeight: "400" as "400"
                            }}>{t("festivalScreen.searchPlaceholder")}</Text>
                            <Icon
                                name="ecommerce_fill_search"
                                color={colors.black}
                                size={24}
                            />
                        </Pressable>

                        {!loaded &&
                            <View
                                style={{
                                    marginTop: 0,
                                    padding: 16,
                                    // backgroundColor: colors.white,
                                    height: 72,
                                    width: dimension.width,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <ActivityIndicator animating={true} size={'small'} color={colors.ketchup} />
                            </View>
                        }

                        {loaded && <ScrollView horizontal
                            style={{
                                marginTop: 0,
                                padding: 16
                            }}
                            contentContainerStyle={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 8
                            }}
                        >
                            {cuisines?.map((cuisine, index) => <Button
                                key={cuisine.id}
                                color="greyLight"
                                mode="contained"
                                text={t(`festivalScreen.${cuisine.codeName}`)}
                                size="medium"
                                onPress={() => handleCategoryItemPress(cuisine.codeName)}
                            ></Button>)}
                        </ScrollView>}

                        {/* Mess as fuck */}
                        {(festival?.isMapEnabled || festival?.isMerchEnabled || festival?.isScheduleEnabled) && <View style={{
                            ...styles.actionsContainer
                        }}>
                            {festival?.isMapEnabled && <ActionCard
                                title={t("festivalScreen.actions.map.title")}
                                subTitle={t("festivalScreen.actions.map.subtitle")}
                                backgroundColor={"ketchupLight"}
                                containerStyle={{
                                    width: '50%',
                                    height: '100%'
                                }}
                                image={require("../../assets/images/map_card.svg")}
                                onPress={() => {
                                    navigation.navigate("map");
                                }}
                            />}

                            {/* merch is not enabled */}
                            {!festival?.isMerchEnabled && festival?.isScheduleEnabled && <ActionCard
                                title={t("festivalScreen.actions.program.title")}
                                subTitle={t("festivalScreen.actions.program.subtitle")}
                                backgroundColor={"mustardLight"}
                                containerStyle={{
                                    width: '50%',
                                    height: '100%'
                                }}
                                image={require("../../assets/images/program_card.svg")}
                                onPress={() => {
                                    navigation.navigate("program", {
                                        festivalCodeName: selectedFestivalCodeName as string
                                    });
                                }}
                            />}

                            {/* merch enabled, schedule disabled */}
                            {festival?.isMerchEnabled && !festival?.isScheduleEnabled && <ActionCard
                                title={t("festivalScreen.actions.merch.title")}
                                subTitle={t("festivalScreen.actions.merch.subtitle")}
                                backgroundColor={"greenLight"}
                                containerStyle={{
                                    width: '50%',
                                    height: '100%'
                                }}
                                imageStyle={{
                                    width: 70,
                                    // height: 106
                                }}
                                image={require("../../assets/images/merch_card.svg")}
                                onPress={() => {
                                    navigation.navigate('restaurant', {
                                        restaurantCodeName: `${selectedFestivalCodeName}_merch`,
                                        festivalCodeName: selectedFestivalCodeName as string
                                    });
                                }}
                            />}

                            {/* merch and schedule and map enabled */}
                            {(festival?.isMerchEnabled && festival?.isScheduleEnabled && festival?.isMapEnabled) && <View style={{
                                ...styles.actionsContainerMerch
                            }}>
                                {festival?.isScheduleEnabled && <ActionCard
                                    title={t("festivalScreen.actions.program.title")}
                                    subTitle={t("festivalScreen.actions.program.subtitle")}
                                    size="small"
                                    backgroundColor={"mustardLight"}
                                    containerStyle={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    image={require("../../assets/images/program_card.svg")}
                                    onPress={() => {
                                        navigation.navigate("program", {
                                            festivalCodeName: selectedFestivalCodeName as string
                                        });
                                    }}
                                />}

                                {festival?.isMerchEnabled && <ActionCard
                                    title={t("festivalScreen.actions.merch.title")}
                                    subTitle={t("festivalScreen.actions.merch.subtitle")}
                                    size="small"
                                    backgroundColor={"greenLight"}
                                    containerStyle={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    image={require("../../assets/images/merch_card.svg")}
                                    onPress={() => {
                                        navigation.navigate('restaurant', {
                                            restaurantCodeName: `${selectedFestivalCodeName}_merch`,
                                            festivalCodeName: selectedFestivalCodeName as string
                                        });
                                    }}
                                />}
                            </View>}

                            {/* merch and schedule and map disabled */}
                            {(festival?.isMerchEnabled && festival?.isScheduleEnabled && !festival?.isMapEnabled) && <>
                                {festival?.isScheduleEnabled && <ActionCard
                                    title={t("festivalScreen.actions.program.title")}
                                    subTitle={t("festivalScreen.actions.program.subtitle")}
                                    backgroundColor={"mustardLight"}
                                    containerStyle={{
                                        width: '50%',
                                        height: '100%'
                                    }}
                                    image={require("../../assets/images/program_card.svg")}
                                    onPress={() => {
                                        navigation.navigate("program", {
                                            festivalCodeName: selectedFestivalCodeName as string
                                        });
                                    }}
                                />}

                                {festival?.isMerchEnabled && <ActionCard
                                    title={t("festivalScreen.actions.merch.title")}
                                    subTitle={t("festivalScreen.actions.merch.subtitle")}
                                    backgroundColor={"greenLight"}
                                    containerStyle={{
                                        width: '50%',
                                        height: '100%'
                                    }}
                                    imageStyle={{
                                        width: 70,
                                        // height: 106
                                    }}
                                    image={require("../../assets/images/merch_card.svg")}
                                    onPress={() => {
                                        navigation.navigate('restaurant', {
                                            restaurantCodeName: `${selectedFestivalCodeName}_merch`,
                                            festivalCodeName: selectedFestivalCodeName as string
                                        });
                                    }}
                                />}
                            </>}

                        </View>}
                    </View>

                    {favouriteRestaurants && favouriteRestaurants?.length > 0 &&
                        <>
                            <View style={{
                                ...styles.whiteContainer,
                                padding: 16
                            }}>
                                <Text variant="headingBlack" style={styles.titleBold}>{t("festivalScreen.favourite.title")}</Text>
                                <Text variant="paragraphRegular" style={styles.subtitle}>{t("festivalScreen.favourite.subtitle")}</Text>
                            </View>

                            <ScrollView horizontal style={{
                                backgroundColor: colors.white,
                                padding: 8
                            }}>

                                {favouriteRestaurants.map((restaurant, index) => (
                                    <RestaurantCard
                                        key={restaurant.id}
                                        style={{
                                            width: 230,
                                            margin: 4
                                        }}
                                        festivalCodeName={selectedFestivalCodeName as string}
                                        saveDataMode={dataSaver}
                                        isPopular={restaurant.isPopular}
                                        restaurant={restaurant}
                                        onPress={() => handRestaurantPress(restaurant)}
                                        onFavouritePress={() => handleOnFavouritePress(restaurant)}
                                    />
                                ))}

                            </ScrollView>
                        </>}

                    {recomendedRestaurants && recomendedRestaurants?.length > 0 && <View style={styles.whiteContainer}>
                        <View style={{
                            padding: 16
                        }}>
                            <Text variant="headingBlack" style={styles.titleBold}>{t("festivalScreen.ourChoose.title")}</Text>
                            <Text variant="paragraphRegular" style={styles.subtitle}>{t("festivalScreen.ourChoose.title")}</Text>
                        </View>

                        <ScrollView horizontal style={{
                            // marginTop: 8,
                            padding: 8
                        }}>
                            {recomendedRestaurants.map((restaurant, index) => (
                                <RestaurantCard
                                    key={restaurant.id}
                                    style={{
                                        width: 230,
                                        margin: 4
                                    }}
                                    festivalCodeName={selectedFestivalCodeName as string}
                                    saveDataMode={dataSaver}
                                    isPopular={restaurant.isPopular}
                                    restaurant={restaurant}
                                    onPress={() => handRestaurantPress(restaurant)}
                                    onFavouritePress={() => handleOnFavouritePress(restaurant)}
                                />
                            ))}
                        </ScrollView>
                    </View>}

                    <View style={{
                        ...styles.whiteContainer,
                        padding: 16
                    }}>
                        <View style={styles.brambleContainer}>
                            <View style={{
                                width: '50%'
                            }}>
                                <Text variant="headingBlack" style={styles.brambleTitle}>{t("festivalScreen.promo.title")}</Text>
                                <Text variant="paragraphMedium" style={styles.brambleTitle}>{t("festivalScreen.promo.subtitle")}</Text>
                            </View>
                            <Image
                                style={styles.brambleImageStyle}
                                source={{ uri: require("../../assets/images/order_card.svg") }}
                            />
                        </View>
                    </View>

                    <View style={styles.whiteContainer}>
                        <View style={{
                            padding: 16,
                            marginTop: 8
                        }}>
                            <Text variant="headingBlack" style={styles.titleBold}>{t("festivalScreen.all.title")}</Text>
                            <Text variant="paragraphRegular" style={styles.subtitle}>{t("festivalScreen.all.subtitle")}</Text>
                        </View>

                        {restaurants.map((restaurant, index) => (
                            // <View key={restaurant.id}>
                            <RestaurantCard
                                style={{
                                    padding: 8
                                }}
                                key={restaurant.id}
                                festivalCodeName={selectedFestivalCodeName as string}
                                saveDataMode={dataSaver}
                                isPopular={restaurant.isPopular}
                                restaurant={restaurant}
                                onPress={() => handRestaurantPress(restaurant)}
                                onFavouritePress={() => handleOnFavouritePress(restaurant)}
                            />
                            // </View>
                        ))}
                    </View>
                </View>

            </ScrollView >
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    actionsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        gap: 8,
        padding: 16,
        // height: 200
    },
    actionsContainerMerch: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        // padding: 16,
        gap: 8,
        width: '50%'
    },
    actionTitle: {
        fontSize: 18,
        lineHeight: 27
    },
    actionSubtitle: {
        fontSize: 12,
        lineHeight: 18
    },
    titleBold: {
        fontSize: 24,
        lineHeight: 36
    },
    brambleContainer: {
        padding: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.mustardLight
    },
    brambleTitle: {
        fontSize: 16,
        lineHeight: 24
    },
    brambleSubtitle: {
        fontSize: 14,
        lineHeight: 21
    },
    brambleImageStyle: {
        width: 133,
        height: 126
    },
    subtitle: {
        fontSize: 16,
        lineHeight: 24,
        color: colors.greyMedium
    },
    searchContainer: {
        padding: 16,
        backgroundColor: colors.white
    },
    whiteContainer: {
        marginTop: 16,
        backgroundColor: colors.white
    }
});