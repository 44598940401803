import React from "react";
import { Appbar, TouchableRipple, Text, Button as _Button, useTheme } from 'react-native-paper';
import { View, StyleSheet, Image } from "react-native";
import { AppTheme } from "../../../App";


export interface AppHeaderProps {
    style?: any;
    header?: string;
    onMenuPress?: () => void;
    onBannerPress?: () => void;
    onSearchPress?: () => void;
}

export default function AppHeader({
    header,
    onMenuPress,
    onSearchPress,
    onBannerPress,
    style,
}: AppHeaderProps) {

    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);

    return (
        <Appbar.Header style={{ ...styles.container, ...style }}>
            <Appbar.Action icon="menu" onPress={onMenuPress} />
            <TouchableRipple
                onPress={() => onBannerPress?.()}
            >
                <>
                    {header && <Text variant="headingBlack" style={styles.header}>{header}</Text>}
                    {!header && <Image style={styles.logo} source={require('../../../assets/images/logo.png')} />}
                </>
            </TouchableRipple>
            {/* <Appbar.Action icon="magnify" onPress={onSearchPress} /> */}
            <TouchableRipple
                onPress={() => onSearchPress?.()}
            >
                <Image style={styles.logoSmall} source={require('../../../assets/images/logo_small.png')} />
            </TouchableRipple>
        </Appbar.Header >
    );
}



const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.white
    },
    header: {
        fontSize: 18,
        lineHeight: 27,
        textTransform: 'uppercase'
    },
    logo: {
        width: 108.65,
        height: 24
    },
    logoSmall: {
        width: 21.664,
        height: 24,
        margin: 16
    }
});
