
import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { ShoppingCartItem } from "../../core/store/features/shoppingCart/shoppingCartSlice";
import Icon from "../Icon/Icon";
import { Order } from "../../core/interfaces/order.interface";
import { useTranslation } from "react-i18next";


export interface OrderDetailsProps {
    order: Order;
    styles?: any;
}

export default function OrderDetails(props: OrderDetailsProps) {

    const [totalPrice, setTotalPrice] = useState<number>(props?.order?.price);
    const [totalDiscount, setDiscountPrice] = useState<number>(props?.order?.discount);
    const [serviceFee, setServiceFee] = useState<number>(0.15);

    const { colors } = useTheme<AppTheme>();
    const { t } = useTranslation();
    const styles = makeStyles(colors);

    function getExtraMenuItemsText(orderMenuitem: any) {

        var extraMenuItemsText = orderMenuitem?.orderMenuitemExtraMenuitems?.map((orderMenuitemExtraMenuitem: any) => orderMenuitemExtraMenuitem.extraMenuitem.name).concat(props.order.note || "").filter(Boolean).join(" + ");

        if (extraMenuItemsText) {
            extraMenuItemsText = "+ " + extraMenuItemsText;
        }

        return extraMenuItemsText;
    }

    function getPrice(orderMenuitem: any) {
        let itemPrice = (orderMenuitem.discountPrice && orderMenuitem.discountPrice > 0) ? orderMenuitem.discountPrice : orderMenuitem.price;
        let price = (itemPrice * orderMenuitem.amount) + orderMenuitem.extraMenuitemsPrice;
        return price;
    }

    return (
        <>
            {props?.order?.orderMenuitems?.map(orderMenuitem => <TouchableRipple
                key={orderMenuitem?.id}
                onPress={() => { }}
                style={{
                    ...styles.orderItem,
                    ...props.styles
                }}
            >
                <>
                    <View style={{
                        ...styles.row,
                        gap: 16
                    }}>
                        <TouchableRipple style={{
                            ...styles.amout
                        }} onPress={() => { }}>
                            <Text variant="paragraphRegular" style={styles.amoutText}>{orderMenuitem.amount}</Text>
                        </TouchableRipple>

                        <View style={styles.orderDetail}>
                            <View>
                                <Text variant="headingBold" numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
                                    {orderMenuitem.menuitem.name}
                                </Text>

                                <Text
                                    variant="paragraphRegular"
                                    style={styles.extraMenuItems}>{getExtraMenuItemsText(orderMenuitem)}
                                </Text>
                            </View>

                        </View>
                    </View>
                    <Text variant="paragraphMedium" style={styles.price}>{getPrice(orderMenuitem)} €</Text>
                </>
            </TouchableRipple>)}

            <View style={{
                padding: 16,
                gap: 8
            }}>
                <View style={styles.row}>
                    <Text variant="paragraphBold" style={styles.paragraph14}>{t('orderScreen.footer.tax.title')}</Text>
                    <Text variant="paragraphMedium" style={styles.paragraph14}>{totalPrice} €</Text>
                </View>
                <View style={styles.row}>
                    <Text variant="paragraphBold" style={styles.paragraph14}>{t('orderScreen.footer.fee.title')}</Text>
                    <Text variant="paragraphMedium" style={styles.paragraph14}>{serviceFee} €</Text>
                </View>
                <View style={styles.row}>
                    <Text variant="paragraphBold" style={styles.paragraph14}>{t('orderScreen.footer.paid.title')}</Text>
                    <Text variant="paragraphMedium" style={styles.paragraph14}>{totalPrice + serviceFee} €</Text>
                </View>
                <View style={styles.row}>
                    <Text variant="paragraphBold" style={styles.paragraph14}>{t('orderScreen.footer.saved.title')}</Text>
                    <Text variant="paragraphMedium" style={styles.paragraph14}>{totalDiscount} €</Text>
                </View>
                <Text variant="paragraphRegular" style={styles.infoText}>{t('orderScreen.footer.title')}</Text>
            </View>
        </>
    );
}



const makeStyles = (colors: any) => StyleSheet.create({
    orderItem: {
        minHeight: 80,
        backgroundColor: colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginHorizontal: 16,
        borderBottomColor: colors.greyLight,
        borderBottomWidth: 1
    },
    price: {
        fontSize: 14,
        lineHeight: 21
    },
    name: {
        fontSize: 16,
        lineHeight: 24
    },
    amout: {
        backgroundColor: colors.white,
        borderColor: colors.greyLight,
        borderWidth: 1,
        borderRadius: 4,
        width: 48,
        height: 48,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paragraph14: {
        lineHeight: 21,
        fontSize: 14,
    },
    amoutText: {
        fontSize: 16,
        lineHeight: 24
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    infoText: {
        fontSize: 12,
        lineHeight: 18,
        color: colors.greyMedium
    },
    orderDetail: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8
    },
    extraMenuItems: {
        fontSize: 14,
        lineHeight: 21,
        color: colors.greyMedium
    }
});
