import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView, Dimensions, ImageBackground, InteractionManager, Platform, KeyboardAvoidingView } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Divider, Text, TouchableRipple, useTheme } from "react-native-paper";
import { Menuitem } from "../core/interfaces/menuitem.interface";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import Button from "../components/Button/Button";
import { Category as ICategory } from "../core/interfaces/category.interface";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { RootState } from "../core/store/store";
import { ShoppingCartItem, addItemToShoppingCart, clearShoppingCart, removeItemFromShoppingCart, updateItemInShoppingCart } from "../core/store/features/shoppingCart/shoppingCartSlice";
import Campaign from "../components/Campaign/Campaign";
import SearchField from "../components/SearchField/SearchField";
import RestaurantCard from "../components/RestaurantCard/RestaurantCard";
import { Restaurant } from "../core/interfaces/restaurant.interface";
import { updateRestaurant } from "../core/store/features/restaurant/restaurantSlice";
import MenuItemSmall from "../components/MenuItemSmall/MenuItemSmall";
import { useFocusEffect } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { dataSaverSelector, orderItemsSelector, selectedFestivalCodeNameSelector } from "../core/store/selectors";
import { openDialog } from "../core/store/features/dialog/dialogSlice";
import TextField from "../components/TextField/TextField";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "search">) {

    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState<string>("");
    const [seletedChip, setSeletedChip] = useState<string>("");
    const [allCategories, setAllCategories] = useState<ICategory[]>([]);
    const [filteredMenuItems, setFilteredMenuItems] = useState<Menuitem[]>([]);

    const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector);
    const dataSaver = useSelector(dataSaverSelector);


    const restaurants = useSelector((state: RootState) => state.restaurant.restaurants.map(restaurant => {
        if ((state.ui.favouriteRestaurants || []).indexOf(restaurant.id) > -1) {
            return ({ ...restaurant, _isFavourite: true })
        } {
            return ({ ...restaurant, _isFavourite: false })
        }
    }).filter(r => {
        let menuitems = _filterMenuItems(searchText);

        return r.name.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) ||
            r.description.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) ||
            menuitems?.map(m => m?.restaurantId)?.indexOf(r?.id) > -1 ||
            r.cuisines?.map((c: any) => c.name)?.some((c: string) => c.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()))
    }));

    const cuisines = useSelector((state: RootState) => state.cuisine.cuisines);

    const { t } = useTranslation();
    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);


    const chips = cuisines?.map(cuisine => t(`festivalScreen.${cuisine.codeName}`));

    // const chips = [
    //     t('searchScreen.chips.burger'),
    //     t('searchScreen.chips.pizza'),
    //     t('searchScreen.chips.alcohol'),
    //     t('searchScreen.chips.asia'),
    //     t('searchScreen.chips.drinks'),
    //     t('searchScreen.chips.icecream'),
    //     t('searchScreen.chips.sushi'),
    //     t('searchScreen.chips.soups'),
    //     t('searchScreen.chips.sweets')
    // ];

    async function apiCall() {

        let filter = `isStandingOffer=true`;
        let response = await axiosSecure.get(`/api/category?${filter}`, false);

        setAllCategories(response.data);
    }

    // load after animation end
    useFocusEffect(
        React.useCallback(() => {
            const task = InteractionManager.runAfterInteractions(() => {
                apiCall();
            });

            return () => task.cancel();
        }, [])
    );

    function _filterMenuItems(e: string) {
        let menuitems = allCategories.filter(c => c.name.toLocaleLowerCase()
            ?.includes(e?.toLocaleLowerCase())
            || c?.menuitems?.map((c: any) => c.name)
                ?.some((c: string) => c.toLocaleLowerCase()?.includes(e?.toLocaleLowerCase())))
            .flatMap(c => c?.menuitems);

        return menuitems;
    }

    function handleSearchChangeText(e: string) {
        setSearchText(e);
        setSeletedChip("");
        setFilteredMenuItems(_filterMenuItems(e));
    }

    function handleChipPress(e: any) {
        setSearchText(e);
        setSeletedChip(e);
        setFilteredMenuItems(_filterMenuItems(e));
    }

    function handRestaurantPress(restaurant: Restaurant): void {
        navigation.navigate('restaurant', { restaurantCodeName: restaurant.codeName, festivalCodeName: selectedFestivalCodeName as string });
    }

    function handleOnFavouritePress(restaurant: Restaurant) {
        dispatch(updateRestaurant({ ...restaurant, _isFavourite: !restaurant?._isFavourite }))
    }

    function handleMenuItemPress(restaurant: Restaurant, menuitem: Menuitem) {
        navigation.navigate('restaurant', {
            restaurantCodeName: restaurant.codeName,
            festivalCodeName: selectedFestivalCodeName as string,
            menuitemId: menuitem?.id
        });
    }

    return (
        <ScrollView
            bounces={false} // iOS only - prevent drag whole view if it reach bottom
            style={{
                ...styles.container,
                height: Platform.OS === 'web' ? 10 : undefined
            }}
            stickyHeaderIndices={[0]}
        >
            <View
                style={{
                    backgroundColor: colors.white
                }}
            >
                <KeyboardAvoidingView
                    behavior={"position"}
                    style={{ ...styles.headerContainer }}
                >
                    <IconButton
                        icon='arrows_outline_arrow_left'
                        color="white"
                        onPress={() =>
                            navigation.goBack()
                        }
                    />

                    <SearchField
                        style={{ flex: 1 }}
                        autoFocus={true}
                        value={searchText}
                        label={t("searchScreen.placeholder")}
                        onChangeText={handleSearchChangeText}
                    />
                </KeyboardAvoidingView>

                {!searchText && <Text variant="headingBlack" style={styles.header}>{t('searchScreen.header')}</Text>}

                <ScrollView
                    horizontal={searchText ? true : false}
                    contentContainerStyle={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: searchText ? undefined : 'wrap',
                        paddingHorizontal: 16,
                        gap: 8
                    }}>

                    {chips.map((chip, index) => (
                        <Button
                            key={index}
                            size="medium"
                            text={chip}
                            color={seletedChip === chip ? "black" : "greyLight"}
                            onPress={() => handleChipPress(chip)}
                        />
                    ))}
                </ScrollView>
            </View>

            {searchText?.length > 0 &&
                <View
                    style={styles.restaurantContainer}
                >
                    {restaurants.map((restaurant, index) => (
                        <View
                            key={restaurant.id}
                            style={{
                                borderBottomColor: index !== restaurants.length - 1 ? colors.greyLight : 'unset',
                                borderBottomWidth: index !== restaurants.length - 1 ? 1 : 0
                            }}
                        >
                            <RestaurantCard
                                saveDataMode={dataSaver}
                                isPopular={restaurant.isPopular}
                                festivalCodeName={selectedFestivalCodeName as string}
                                restaurant={restaurant}
                                onPress={() => handRestaurantPress(restaurant)}
                                onFavouritePress={() => handleOnFavouritePress(restaurant)}
                            />

                            <ScrollView
                                horizontal
                                contentContainerStyle={{
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                {filteredMenuItems.filter((m: any) => !m.isHidden)?.filter(m => m.restaurantId === restaurant?.id).map((menuitem, index) => (
                                    <MenuItemSmall
                                        key={menuitem.id}
                                        menuitem={menuitem}
                                        onPress={() => handleMenuItemPress(restaurant, menuitem)}
                                        styles={{
                                            paddingLeft: index === 0 ? 8 : 16
                                        }}
                                    />
                                ))}
                            </ScrollView>

                        </View>
                    ))}
                </View>
            }

        </ScrollView>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    headerContainer: {
        // position: 'absolute',
        // zIndex: 1,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 16,
        paddingLeft: 0,
        backgroundColor: colors.white,
    },
    container: {
        backgroundColor: colors.white
    },
    restaurantContainer: {
        padding: 8
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    header: {
        alignSelf: 'center',
        lineHeight: 24,
        fontSize: 16,
        marginTop: 16,
        marginBottom: 28
    }
});
