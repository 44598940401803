import React, {  } from "react";
import { View, Image, StyleSheet, useWindowDimensions } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { RootState } from "../core/store/store";
import { currentOrderSelector } from "../core/store/selectors";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "orderNumber">) {

    const currentOrder = useSelector(currentOrderSelector);

    const axiosSecure = useAxiosSecure();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);
    const dispatch = useDispatch();
    const dimension = useWindowDimensions();

    return (
        <View style={styles.container}>
            <IconButton
                icon='general_fill_close'
                color="white"
                style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1
                }}
                onPress={() =>
                    navigation.goBack()
                }
            />

            <View
                style={{
                    ...styles.orderNumberContainer,
                    height: dimension.height / 2
                }}
            >
                <Text variant="displayBlack" style={{ color: colors.white }}>{currentOrder?.orderNumber?.split("").join(" ")}</Text>
                <Image style={styles.logo} source={require('../../assets/images/logo_white.png')} />
            </View>
        </View>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: colors.ketchup,
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 16
    },
    orderNumberContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 80
    },
    logo: {
        width: 200,
        height: 44.177
    }
});
