import { combineReducers, configureStore } from '@reduxjs/toolkit'
// import storage from 'redux-persist/es/storage'
import storageSession from 'redux-persist/es/storage/session'
import AsyncStorage from '@react-native-async-storage/async-storage';
import restaurantReducer from './features/restaurant/restaurantSlice';
import cuisineReducer  from './features/cuisine/cuisineSlice';
import festivalReducer from './features/festival/festivalSlice';
import performanceReducer from './features/performance/performanceSlice';
import notificationReducer from './features/notification/notificationSlice';
import modalReducer from './features/modal/modalSlice';
import dialogReducer from './features/dialog/dialogSlice';
import loadingReducer from './features/loading/loadingSlice';
import shoppingCartReducer from './features/shoppingCart/shoppingCartSlice';
import currentOrderReducer from './features/currentOrder/currentOrderSlice';
import bottomSheetReducer from './features/bottomSheet/bottomSheetSlice';
import uiReducer from './features/ui/uiSlice';
import thunk from 'redux-thunk';
import { createTransform, persistReducer, persistStore } from 'redux-persist';

import { omit } from 'lodash';

let blacklistTransform = createTransform((inboundState, key) => {
  if (key === 'ui') {
    return omit(inboundState, ['selectedFestivalCodeName']);
  } else {
    return inboundState;
  }
});

const persistConfig: any = {
  key: 'root',
  storage: AsyncStorage,
  // whitelist: ['auth', 'ui'],
  whitelist: ['ui', 'shoppingCart', 'currentOrder'],
  // transforms: [blacklistTransform],
  // transforms: [encrypt]
}

const rootReducer = combineReducers({
  restaurant: restaurantReducer.restaurantReducer,
  festival: festivalReducer.festivalReducer,
  performance: performanceReducer.performanceReducer,
  cuisine: cuisineReducer.cuisineReducer,
  notification: notificationReducer.notificationReducer,
  modal: modalReducer.modalReducer,
  dialog: dialogReducer.dialogReducer,
  loading: loadingReducer.loadingReducer,
  shoppingCart: shoppingCartReducer.shoppingCartReducer,
  currentOrder: currentOrderReducer.currentOrderReducer,
  bottomSheet: bottomSheetReducer.bottomSheetReducer,
  ui: uiReducer.uiReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)