import React, { } from "react";
import { View, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useTheme } from "react-native-paper";
import { AppTheme } from "../../App";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import ListItem from "../components/ListItem/ListItem";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "language">) {

    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);
    const { i18n } = useTranslation(); //i18n instance

    const selectedStyle = {
        borderBottomColor: colors.ketchup,
        borderBottomWidth: 2
    }

    async function handleOnLanguageSelect(language: string) {
        await AsyncStorage.setItem('lang', language);
        i18n.changeLanguage(language);
        navigation.goBack();
    }

    return (
        <>
            <IconButton
                icon='arrows_outline_arrow_left'
                color="white"
                style={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    zIndex: 1
                }}
                onPress={() =>
                    navigation.goBack()
                }
            />

            <View
                style={styles.container}
            >
                <ListItem
                    label="Slovensky"
                    image={require("../../assets/images/flags/sk.svg")}
                    imageStyle={{
                        width: 20,
                        height: undefined,
                        aspectRatio: 4 / 3
                    }}
                    onPress={() => handleOnLanguageSelect("sk")}
                    style={i18n.language === 'sk' ? selectedStyle : undefined}
                />

                <ListItem
                    label="Česky"
                    image={require("../../assets/images/flags/cz.svg")}
                    imageStyle={{
                        width: 20,
                        height: undefined,
                        aspectRatio: 4 / 3
                    }}
                    onPress={() => handleOnLanguageSelect("cz")}
                    style={i18n.language === 'cz' ? selectedStyle : undefined}
                />

                <ListItem
                    label="English"
                    image={require("../../assets/images/flags/gb.svg")}
                    imageStyle={{
                        width: 20,
                        height: undefined,
                        aspectRatio: 4 / 3
                    }}
                    onPress={() => handleOnLanguageSelect("en")}
                    style={i18n.language === 'en' ? selectedStyle : undefined}
                />

                <ListItem
                    label="Deutsch"
                    image={require("../../assets/images/flags/de.svg")}
                    imageStyle={{
                        width: 20,
                        height: undefined,
                        aspectRatio: 4 / 3
                    }}
                    onPress={() => handleOnLanguageSelect("de")}
                    style={i18n.language === 'de' ? selectedStyle : undefined}
                />

                <ListItem
                    label="Polski"
                    image={require("../../assets/images/flags/pl.svg")}
                    imageStyle={{
                        width: 20,
                        height: undefined,
                        aspectRatio: 4 / 3
                    }}
                    onPress={() => handleOnLanguageSelect("pl")}
                    style={i18n.language === 'pl' ? selectedStyle : undefined}
                />

                <ListItem
                    label="Magyar"
                    image={require("../../assets/images/flags/hu.svg")}
                    imageStyle={{
                        width: 20,
                        height: undefined,
                        aspectRatio: 4 / 3
                    }}
                    onPress={() => handleOnLanguageSelect("hu")}
                    style={i18n.language === 'hu' ? selectedStyle : undefined}
                />
            </View>
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: colors.white,
        padding: 48,
        height: '100%',
        paddingTop: 64
    }
});
