
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, TouchableHighlight } from "react-native";
import { Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Badge from "../Badge/Badge";
import { Menuitem } from "../../core/interfaces/menuitem.interface";
import axios, { MINIO_URL } from "../../core/api/axios";


export interface MenuItemSmallProps {
    menuitem: Menuitem;
    styles?: any;
    saveDataMode?: boolean;
    onPress?: () => void;
}

export default function MenuItemSmall(props: MenuItemSmallProps) {

    const { colors } = useTheme<AppTheme>();
    const [thumbnail, setThumbnail] = useState<string>("../../../assets/images/menuitem.png");

    const styles = makeStyles(colors);


    async function loadThumbnail() {
        try {
            let response = await axios.get(`${MINIO_URL}/menuitems/${props?.menuitem?.id}`);
            setThumbnail(`${MINIO_URL}/menuitems/${props?.menuitem?.id}`);
        } catch (e) {

        }
    }

    useEffect(() => {
        setThumbnail(`${MINIO_URL}/menuitems/${props?.menuitem?.id}-sm`);
        // loadThumbnail();
    }, [])


    return (

        <TouchableRipple
            onPress={props?.onPress}
        >
            <View style={{
                ...styles.container,
                ...props?.styles
            }}>
                <Image
                    style={
                        styles.thumbnail
                    }
                    defaultSource={require("../../../assets/images/menuitem.svg")}
                    source={{ uri: thumbnail }}
                />
                <View style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                }}>
                    <Text variant="headingBold" style={styles.title} numberOfLines={1} ellipsizeMode="tail">{props?.menuitem.name}</Text>
                    <Text variant="labelMedium" style={styles.price}>{props?.menuitem.price}€</Text>
                </View>
            </View>
        </TouchableRipple>
    );
}



const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 16,
        paddingVertical: 16,
        paddingRight: 16,
        // paddingLeft: index === 0 ? 8 : 16
    },
    title: {
        fontSize: 16,
        lineHeight: 24
    },
    price: {
        fontSize: 14,
        lineHeight: 21
    },
    description: {
        fontSize: 14,
        lineHeight: 21,
        color: colors.greyMedium
    },
    thumbnail: {
        width: 48,
        height: 48,
        borderRadius: 4,
        resizeMode: "cover"
    }
});
