import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export const performanceSlice = createSlice({
    name: 'performance',
    initialState: {
        performances: [] as any[]
    },
    reducers: {
        addPerformances: (state, action: PayloadAction<any[]>) => {
            if (state.performances.length > 0) {
                let newPerformances = action.payload.filter(c => state.performances.map(i => i.id).indexOf(c.id) === -1);
                if (newPerformances && newPerformances.length > 0) {
                    state.performances = [...state.performances, ...newPerformances];
                }
            } else {
                state.performances = action.payload;
            }
        },
        addPerformance: (state, action: PayloadAction<any>) => {
            state.performances.push(action.payload);
        },
        updatePerformance: (state, action: PayloadAction<any>) => {
            const performance = state.performances.find(c => c.id === action.payload.id) as any;
            Object.assign(performance, action.payload);
        },
        updatePerformances: (state, action: PayloadAction<Partial<any>[]>) => {
            for (let change of action.payload) {
                const performance = state.performances.find(c => c.id === change.id) as any;
                Object.assign(performance, change);
            }
        },
        deletePerformance: (state, action: PayloadAction<string>) => {
            state.performances = [...state.performances.filter(c => c.id !== action.payload)];
        },
        deletePerformances: (state, action: PayloadAction<string[]>) => {
            state.performances = [...state.performances.filter(c => action.payload.indexOf(c.id) === -1)];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    addPerformances,
    addPerformance,
    updatePerformance,
    updatePerformances,
    deletePerformance,
    deletePerformances
} = performanceSlice.actions

export default { performanceReducer: performanceSlice.reducer }
