
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView } from "react-native";
import { Checkbox, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { MINIO_URL } from "../../core/api/axios";
import Icon from "../Icon/Icon";
import { BrambleColor } from "../../types/theme";


export interface ListItemProps {
    style?: any;
    label?: string;
    sublabel?: string;
    icon?: string;
    image?: any;
    imageStyle?: any;
    textColor?: BrambleColor;
    iconColor?: BrambleColor;
    onPress?: () => void;
}

export default function ListItem(props: ListItemProps) {

    const theme = useTheme<AppTheme>();

    let iconSize = 20;

    let textColor: string = theme.colors["black" as keyof object];

    if (props?.textColor) {
        let _color: string = theme.colors[props?.textColor as keyof object];
        if (_color) {
            textColor = _color;
        }
    }

    let iconColor: string = theme.colors["black" as keyof object];

    if (props?.iconColor) {
        let _color: string = theme.colors[props?.iconColor as keyof object];
        if (_color) {
            iconColor = _color;
        }
    }

    const styles = makeStyles(theme.colors);


    return (
        <TouchableRipple
            onPress={() => props?.onPress?.()}
            style={{
                ...styles.container,
                ...props?.style
            }}
        >
            <>
                {props?.icon &&
                    <Icon name={props?.icon} size={iconSize} color={iconColor} />
                }

                {props?.image &&
                    <Image
                        style={props?.imageStyle}
                        source={props.image}
                    />
                }


                <View
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text variant={props?.sublabel ? "headingBold" : "labelMedium"} style={{
                        ...styles.label,
                        color: textColor
                    }}>
                        {props?.label}
                    </Text>

                    {props?.sublabel && <Text variant="paragraphRegular" style={{
                        ...styles.sublabel
                    }}>
                        {props?.sublabel}
                    </Text>}
                </View>
            </>
        </TouchableRipple>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 16,
        padding: 16
    },
    label: {
        fontSize: 16,
        lineHeight: 24
    },
    sublabel: {
        fontSize: 14,
        lineHeight: 21,
        color: colors.greyMedium
    }
});