import { Platform } from 'react-native';
import { configureFonts, MD3LightTheme as DefaultTheme, MD3Theme } from 'react-native-paper';


const baseFont = {
    fontFamily: 'Inter-Regular',
} as const;

const baseVariants = configureFonts({ config: baseFont });

// Then, define custom fonts for different variants

const customVariants = {
    // Customize individual base variants:
    // Add own tokens if required:
    paragraphRegular: {
        fontFamily: Platform.select({
            web: 'Inter-Regular',
            ios: 'Inter-Regular',
            android: 'Inter-Regular',
            default: 'Inter-Regular',
        }),
        // fontWeight: 400,
        fontSize: 18
    },
    paragraphMedium: {
        fontFamily: Platform.select({
            web: 'Inter-Regular',
            ios: 'Inter-Regular',
            android: 'Inter-Regular',
            default: 'Inter-Regular',
        }),
        // fontWeight: 500,
        fontSize: 18
    },
    paragraphBold: {
        fontFamily: Platform.select({
            web: 'Inter-Bold',
            ios: 'Inter-Bold',
            android: 'Inter-Bold',
            default: 'Inter-Bold',
        }),        
        // fontWeight: 700,
        fontSize: 18
    },
    paragraphBlack: {
        fontFamily: Platform.select({
            web: 'Inter-Black',
            ios: 'Inter-Black',
            android: 'Inter-Black',
            default: 'Inter-Black',
        }),
        // fontWeight: 900,
        fontSize: 18
    },
    labelMedium: {
        fontFamily: Platform.select({
            web: 'Inter-Regular',
            ios: 'Inter-Regular',
            android: 'Inter-Regular',
            default: 'Inter-Regular',
        }),
        // fontWeight: 500,
        fontSize: 18
    },
    labelBlack: {
        fontFamily: Platform.select({
            web: 'Inter-Black',
            ios: 'Inter-Black',
            android: 'Inter-Black',
            default: 'Inter-Black',
        }),
        // fontWeight: 900,
        fontSize: 18
    },
    headingBold: {
        fontFamily: Platform.select({
            web: 'Inter-Bold',
            ios: 'Inter-Bold',
            android: 'Inter-Bold',
            default: 'Inter-Bold',
        }),
        // fontWeight: 700,
        fontSize: 40
    },
    headingBlack: {
        fontFamily: Platform.select({
            web: 'Inter-Black',
            ios: 'Inter-Black',
            android: 'Inter-Black',
            default: 'Inter-Black',
        }),
        // fontWeight: 900,
        fontSize: 40
    },
    displayBold: {
        fontFamily: Platform.select({
            web: 'Inter-Bold',
            ios: 'Inter-Bold',
            android: 'Inter-Bold',
            default: 'Inter-Bold',
        }),
        // fontWeight: 700,
        fontSize: 96
    },
    displayBlack: {
        fontFamily: Platform.select({
            web: 'Inter-Black',
            ios: 'Inter-Black',
            android: 'Inter-Black',
            default: 'Inter-Black',
        }),
        // fontWeight: 900,
        fontSize: 96
    }
} as const;

// Finally, merge base variants with your custom tokens
// and apply custom fonts to your theme.

const fontConfig = configureFonts({
    config: {
        ...baseVariants,
        ...customVariants,
    },
});


const defaultLightTheme: MD3Theme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        primary: '#AD191E', // ketchup
        secondary: 'white',
        onPrimary: 'white',
        onSecondary: 'black',
        surfaceVariant: '#FAFAFA'
    },
    fonts: configureFonts({ config: fontConfig }),
};


const black = '#000000';
const white = '#FFFFFF';
const ketchup = '#AD191E';
const ketchupDark = '#8A1418';
const ketchupLight = '#FBE9E9';
const mustard = '#F6BC2F';
const mustardDark = '#C59626';
const mustardLight = '#FEF8EA';
const blue = '#36C5F0';
const blueDark = '#2B9EC0';
const blueLight = '#EBF9FE';
const green = '#007A5A';
const greenDark = '#004936';
const greenLight = '#E9FFEF';
const grey = '#A0A0A2';
const greyDark = '#343434';
const greyMedium = '#5A585E';
const greyLight = '#F1F2F6';
const offWhite = '#FAFAFA';
const disabled = '#E8E8E8';


const BrambleLightTheme = {
    ...defaultLightTheme,
    // Specify a custom property in nested object
    colors: {
        ...defaultLightTheme.colors,

        ketchup,
        onKetchup: white,

        mustard,
        onMustard: black,

        black,
        onBlack: white,

        white,
        onWhite: black,

        blue,
        onBlue: black,

        green,
        onGreen: white,

        grey,
        onGrey: white,

        // DARK
        ketchupDark,
        onKetchupDark: white,

        mustardDark,
        onMustardDark: white,

        blueDark,
        onBlueDark: white,

        greenDark,
        onGreenDark: white,

        greyDark,
        onGreyDark: white,

        // MEDIUM
        greyMedium,
        onGreyMedium: white,

        // LIGHT

        ketchupLight,
        onKetchupLight: black,

        mustardLight,
        onMustardLight: mustardDark,

        blueLight,
        onBlueLight: black,

        greenLight,
        onGreenLight: greenDark,

        greyLight,
        onGreyLight: black,

        offWhite,
        onOffWhite: black,

        disabled: disabled,
        onDisabled: grey,

        positive: green,
        onPositive: white,

        warning: mustard,
        onWarning: black,

        info: blue,
        onInfo: black,

        negative: ketchup,
        onNegative: white,

        positiveDark: greenDark,
        onPositiveDark: white,

        warningDark: mustardDark,
        onWarningDark: white,

        infoDark: blueDark,
        onInfoDark: white,

        negativeDark: ketchupDark,
        onNegativeDark: white,


        positiveLight: greenLight,
        onPositiveLight: black,

        warningLight: mustardLight,
        onWarningLight: black,

        infoLight: blueLight,
        onInfoLight: black,

        negativeLight: ketchupLight,
        onNegativeLight: black

    },
};

export type BadgeColor =
    'black' |
    'white' |
    'grey' |
    'ketchup' |
    'mustard' |
    'green';

export type BrambleColor =
    'black' |
    'white' |
    'ketchup' |
    'ketchupDark' |
    'ketchupLight' |
    'mustard' |
    'mustardDark' |
    'mustardLight' |
    'blue' |
    'blueDark' |
    'blueLight' |
    'green' |
    'greenDark' |
    'greenLight' |
    'grey' |
    'greyDark' |
    'greyMedium' |
    'greyLight' |
    'disabled' |
    'positive' |
    'warning' |
    'info' |
    'negative' |
    'positiveDark' |
    'warningDark' |
    'infoDark' |
    'negativeDark' |
    'positiveLight' |
    'warningLight' |
    'infoLight' |
    'negativeLight';

export type Severity =
    'positive' |
    'warning' |
    'info' |
    'negative' |
    'positiveDark' |
    'warningDark' |
    'infoDark' |
    'negativeDark' |
    'positiveLight' |
    'warningLight' |
    'infoLight' |
    'negativeLight';

export type BorderColor =
    'positive' |
    'warning' |
    'negative' |
    'selected' |
    'grey' |
    'disabled';

export type BackgroundColor =
    'white' |
    'black' |
    'positive' |
    'warning' |
    'info' |
    'negative' |
    'negativeDark' |
    'disabled' |
    'offWhite' |
    'greyDark' |
    'greyLight' |
    'positiveLight' |
    'warningLight' |
    'infoLight' |
    'negativeLight';

export type ContentColor =
    'black' |
    'white' |
    'grey' |
    'disabled';


export default BrambleLightTheme;