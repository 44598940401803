import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, Dimensions } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { AppTheme } from "../../App";
import Button from "../components/Button/Button";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { RootState } from "../core/store/store";
import { ShoppingCartItem, addItemToShoppingCart, removeItemFromShoppingCart, updateItemInShoppingCart } from "../core/store/features/shoppingCart/shoppingCartSlice";
import { orderItemsSelector } from "../core/store/selectors";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "upsale">) {

    const dispatch = useDispatch();

    const orderItems = useSelector(orderItemsSelector);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [amout, setAmout] = useState<number>(0);

    const { colors } = useTheme<AppTheme>();
    const win = Dimensions.get('window');
    const styles = makeStyles(colors);
    const upsaleStyles = makeUpsaleStyles(colors);

    const waterItem: ShoppingCartItem = {
        id: "c56091bf-933a-4f15-93ee-3736da2f7c54",
        menuitem: {
            id: "efca8f81-aa35-4012-a9d9-4b0f97fad876",
            name: "Minerálka nesýtená",
            price: 1.9,
            weight: "500ml",
            allergens: "",
            description: "",
            categoryId: "",
            restaurantId: "",
            isHidden: false,
            menuitemExtraCategories: [],
            viewOrder: 0
        },
        amout: amout,
        note: "",
        extraMenuitems: [],
        totalPrice: totalPrice
    }

    useEffect(() => {
        let item = orderItems.find(o => o.id === "c56091bf-933a-4f15-93ee-3736da2f7c54");
        if (item) {
            setAmout(item.amout || 0);
        }
    }, [])

    useEffect(() => {
        setTotalPrice(Math.round((orderItems.reduce((sum, orderItem) => sum + orderItem.totalPrice, 0) + Number.EPSILON) * 100) / 100);
    }, [orderItems])

    function handleAmoutPlus() {
        setAmout(amout + 1);
        if (amout === 0) {
            dispatch(addItemToShoppingCart({ ...waterItem, amout: 1 }));
        } else {
            dispatch(updateItemInShoppingCart({ ...waterItem, amout: amout + 1 }));
        }
    }

    function handleAmoutMinus() {
        if (amout - 1 < 0) {
            setAmout(0);
        }
        else {
            setAmout(amout - 1);
        }
        dispatch(removeItemFromShoppingCart(waterItem));
    }

    function handlePaymentScreenPress() {
        navigation.navigate("payment")
    }

    return (
        <>
            <View
                style={styles.stickyActionContainer}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 24,
                        width: '100%'
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            gap: 8
                        }}
                    >
                        <Text variant="paragraphBold" style={styles.productLabel}>Minerálka nesýtená</Text>
                        <Text variant="paragraphRegular" style={styles.productPriceLabel}>0.5l • 1.90 €</Text>
                    </View>

                    {/* TODO: make stepper component */}
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            gap: 8
                        }}
                    >
                        <IconButton
                            icon='ecommerce_fill_minus'
                            variant="primary"
                            color={amout === 0 ? "grey" : "black"}
                            size="small"
                            onPress={handleAmoutMinus}
                        />

                        <View style={{
                            width: 32,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Text style={{
                                fontWeight: "700",
                                fontSize: 16,
                                lineHeight: 24
                            }}>{amout}</Text>
                        </View>

                        <IconButton
                            icon='ecommerce_fill_plus'
                            variant="primary"
                            color="black"
                            size="small"
                            onPress={handleAmoutPlus}
                        />
                    </View>

                </View>


                <Button
                    mode="contained"
                    text={amout === 0 ? 'Teraz nie, ďakujem' : 'Zaplatiť • Spolu ' + totalPrice + ' €'}
                    color="ketchup"
                    style={{
                        width: '100%'
                    }}
                    onPress={handlePaymentScreenPress}
                />
            </View>

            <IconButton
                icon='general_fill_close'
                color="white"
                style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    zIndex: 1
                }}
                onPress={() =>
                    navigation.goBack()
                }
            />

            <View style={upsaleStyles.container}>
                <Image
                    resizeMode={'cover'}
                    style={{
                        ...upsaleStyles.backgroundImage,
                        height: win.height - 150
                    }}
                    source={{
                        uri: require('../../assets/images/upsale.png')
                    }}
                />
                <View style={upsaleStyles.titleContent} >
                    <Text
                        variant="headingBlack"
                        style={upsaleStyles.titleText}
                    >
                        {"Fľaška s dobrým srdcom"}
                    </Text>
                </View>

                <View style={upsaleStyles.descriptionContent} >
                    <Text
                        variant="paragraphMedium"
                        style={upsaleStyles.descriptionText}
                    >
                        {"Za každú flašu minerálky, ktorú si objednáš, darujeme ďaľšiu ľuďom v núdzi"}
                    </Text>

                </View>
            </View>
        </>
    );
}

const makeUpsaleStyles = (colors: any) => StyleSheet.create({
    container: {
        height: '100%'
    },
    titleContent: {
        position: "absolute",
        top: 48,
        padding: 24,
        gap: 16
    },
    descriptionContent: {
        position: "absolute",
        bottom: 32 + 150,
        padding: 24,
        gap: 16
    },
    titleText: {
        color: colors.greenDark,
        fontSize: 36,
        lineHeight: 45
    },
    descriptionText: {
        color: colors.greenDark,
        fontSize: 16,
        lineHeight: 24
    },
    action: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    backgroundImage: {
        width: '100%',
        height: '100%'
    }
});

const makeStyles = (colors: any) => StyleSheet.create({
    stickyActionContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        gap: 16,
        backgroundColor: colors.white,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    container: {
        backgroundColor: colors.white,
        marginBottom: 48
    },
    icon: {
        height: 20,
        width: 20,
        margin: 0
    },
    header: {
        padding: 16,
        lineHeight: 45,
        fontSize: 36,
        marginTop: 32,
        marginBottom: 16
    },
    productLabel: {
        lineHeight: 24,
        fontSize: 16,
    },
    productPriceLabel: {
        lineHeight: 21,
        fontSize: 14,
    },
    divider: {
        height: 16,
        backgroundColor: colors.offWhite
    }
});
