import React from "react";
import RestaurantScreen from "../screens/RestaurantScreen";
import HomeScreen from "../screens/HomeScreen";
import MenuItemScreen from "../screens/MenuItemScreen";
import { DrawerContentScrollView, createDrawerNavigator } from "@react-navigation/drawer";
import { View, Image, StyleSheet, Linking } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../core/store/store";
import { setAnimations, setDataSaver } from "../core/store/features/ui/uiSlice";
import { Text, TouchableRipple, useTheme } from "react-native-paper";
import { AppTheme } from "../../App";
import OrderScreen from "../screens/OrderScreen";
import { TransitionPresets, createStackNavigator } from "@react-navigation/stack";
import UpsaleScreen from "../screens/UpsaleScreen";
import PaymentScreen from "../screens/PaymentScreen";
import Switch from "../components/Switch/Switch";
import CuisineFilterScreen from "../screens/CuisineFilterScreen";
import { enableFreeze } from "react-native-screens";
import SearchScreen from "../screens/SearchScreen";
import { CommonActions, DrawerActions } from "@react-navigation/native";
import FestivalScreen from "../screens/FestivalScreen";
import ListItem from "../components/ListItem/ListItem";
import IconButton from "../components/IconButton/IconButton";
import { version } from '../../package.json';
import MapScreen from "../screens/MapScreen";
import LanguageScreen from "../screens/LanguageScreen";
import { useTranslation } from "react-i18next";
import OrderStateScreen from "../screens/OrderStateScreen";
import OrderNumberScreen from "../screens/OrderNumberScreen";
import ManageOrderScreen from "../screens/ManageOrderScreen";
import HelpOrderScreen from "../screens/HelpOrderScreen";
import CancelOrderScreen from "../screens/CancelOrderScreen";
import RateOrderScreen from "../screens/RateOrderScreen";
import FestivalProgramScreen from "../screens/FestivalProgramScreen";
import { selectedFestivalCodeNameSelector, selectedFestivalSelector } from "../core/store/selectors";
import ArtistScreen from "../screens/ArtistScreen";

enableFreeze(true);

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const RightToLeftAnimation = {
  cardStyleInterpolator: ({ current, layouts }: any) => {
    return {
      cardStyle: {
        transform: [
          {
            translateX: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [layouts.screen.width, 0],
            }),
          },
        ],
      },
    };
  },
};

function CustomDrawerContent(props: any) {
  const dataSaver = useSelector((state: RootState) => state.ui.dataSaver);
  const animations = useSelector((state: RootState) => state.ui.animations);
  const selectedFestivalCodeName = useSelector(selectedFestivalCodeNameSelector)
  const selectedFestival = useSelector(selectedFestivalSelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useTheme<AppTheme>();
  const styles = makeStyles(colors);

  const onToggleDataSaverSwitch = () => {
    dispatch(setDataSaver(!dataSaver));
  };

  const onToggleAnimationsSwitch = () => {
    dispatch(setAnimations(!animations));
  };

  function handleHomePress() {
    props.navigation.navigate("home");
  }

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.navigationContainer}>

        <>
          <View style={styles.navigationHeader}>
            <TouchableRipple
              onPress={handleHomePress}
            >
              <Image style={styles.logo} source={require('../../assets/images/logo.png')} />
            </TouchableRipple>

            <IconButton
              size='small'
              icon='general_fill_close'
              color='black'
              variant="tertiary"
              onPress={() => props.navigation.dispatch(DrawerActions.closeDrawer())}
            />
          </View>

          <Text variant="labelMedium" style={styles.listCategory}>{t("drawer.section.festival")}</Text>

          <ListItem
            label={t("drawer.item.language")}
            iconColor="ketchup"
            icon="general_fill_globe"
            onPress={() => props.navigation.navigate("language")}
          />

          {selectedFestival?.isMapEnabled && <ListItem
            label={t("drawer.item.map")}
            iconColor="ketchup"
            icon="general_outline_location"
            onPress={() => props.navigation.navigate("map")}
          />}

          {selectedFestival?.isScheduleEnabled && <ListItem
            label={t("drawer.item.program")}
            iconColor="ketchup"
            icon="general_outline_calendar"
            onPress={() => props.navigation.navigate("program", {
              festivalCodeName: selectedFestivalCodeName
            })}
          />}

          {selectedFestival?.isMerchEnabled && <ListItem
            label={t("drawer.item.merch")}
            iconColor="ketchup"
            icon="general_outline_user"
            onPress={() => {
              props.navigation.navigate("restaurant", {
                restaurantCodeName: `${selectedFestivalCodeName}_merch`,
                festivalCodeName: selectedFestivalCodeName as string
              });
            }}
          />}

          <Text variant="labelMedium" style={styles.listCategory}>{t("drawer.section.info")}</Text>

          <ListItem
            label={t("drawer.item.conditions")}
            iconColor="ketchup"
            icon="general_outline_empty_document"
          />

          <ListItem
            label="Cookies"
            iconColor="ketchup"
            icon="general_outline_settings"
          />

          <Text variant="labelMedium" style={styles.listCategory}>{t("drawer.section.support")}</Text>

          <ListItem
            label="+421 123 456 789"
            sublabel={t("drawer.item.infoline")}
            icon="media_outline_microphone"
            onPress={() => { Linking.openURL(`tel:+421123456789`) }}
          />

          <ListItem
            label="info@bramble.sk"
            sublabel={t("drawer.item.mail")}
            icon="general_outline_mail"
            onPress={() => Linking.openURL('mailto:info@bramble.sk?subject=SendMail&body=Description')}
          />
        </>

        <>
          <View style={styles.dataSaveContainer}>
            <View>
              <Text variant="paragraphBold" style={{
                fontSize: 16,
                lineHeight: 24
              }}>
                {t("drawer.item.dataSaver")}
              </Text>
            </View>
            <Switch value={dataSaver} onValueChange={onToggleDataSaverSwitch} />

          </View>

          <Text variant="labelMedium" style={styles.listCategory}>Bramble {version}</Text>

        </>
      </View>
    </DrawerContentScrollView>
  );
}

const DrawerScreen = ({ route }: any) => {

  const dataSaver = useSelector((state: RootState) => state.ui.dataSaver);
  const currentOrder = useSelector((state: RootState) => state.currentOrder.currentOrder);

  return (
    <Drawer.Navigator
      screenOptions={{
        title: "Bramble",
        drawerPosition: 'left',
        headerShown: false
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >

      {/* TODO festivalId */}
      <Drawer.Screen name="drawer" >
        {(props) => (
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
              animationEnabled: !dataSaver
            }}
            initialRouteName='festival'
            // TODO
            screenListeners={({ navigation }) => ({
              focus() {
                const { routes } = navigation.getState()

                // Check if HomeScreen is not loaded
                if (routes[0].name !== 'festival') {
                  // @ts-expect-error poor typing of react-navigation
                  navigation.dispatch((state) => {

                    // console.log(state.routes)
                    // console.log(navigation)
                    // Add the home route to top of the stack
                    const routes = [
                      {
                        name: 'festival',
                        initialParams: props.route.params,
                        params: props.route.params
                      },
                      ...state.routes,
                    ]
                    return CommonActions.reset({
                      ...state,
                      routes,
                      index: routes.length - 1,
                    })
                  })
                }
              },
            })}
          >

            <Stack.Screen
              name="home"
              component={HomeScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="festival"
              component={FestivalScreen}
              initialParams={props.route.params}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="cuisine"
              component={CuisineFilterScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="search"
              component={SearchScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="restaurant"
              component={RestaurantScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                // ...RightToLeftAnimation
                ...TransitionPresets.SlideFromRightIOS
              }}
            />

            <Stack.Screen
              name="menuitem"
              component={MenuItemScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="order"
              component={OrderScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.SlideFromRightIOS
              }}
            />

            {currentOrder != null && <Stack.Screen
              name="orderState"
              component={OrderStateScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />}

            <Stack.Screen
              name="manageOrder"
              component={ManageOrderScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="cancelOrder"
              component={CancelOrderScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.SlideFromRightIOS
              }}
            />

            <Stack.Screen
              name="helpOrder"
              component={HelpOrderScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.SlideFromRightIOS
              }}
            />

            {currentOrder != null && <Stack.Screen
              name="orderNumber"
              component={OrderNumberScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />}

            <Stack.Screen
              name="rateOrder"
              component={RateOrderScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="upsale"
              component={UpsaleScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.SlideFromRightIOS
              }}
            />

            {currentOrder == null && <Stack.Screen
              name="payment"
              component={PaymentScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.SlideFromRightIOS
              }}
            />}

            <Stack.Screen
              name="program"
              component={FestivalProgramScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.SlideFromRightIOS
              }}
            />

            <Stack.Screen
              name="artist"
              component={ArtistScreen}
              options={{
                title: "Bramble",
                // animationTypeForReplace: 'push',
                // presentation: "modal",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="map"
              component={MapScreen}
              options={{
                title: "Bramble",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />

            <Stack.Screen
              name="language"
              component={LanguageScreen}
              options={{
                title: "Bramble",
                ...TransitionPresets.ModalSlideFromBottomIOS
              }}
            />
          </Stack.Navigator>
        )}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
};

const Main = () => {

  return (
    <DrawerScreen />
  )
};

const makeStyles = (colors: any) => StyleSheet.create({
  stickyActionContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    width: '100%'
  },
  navigationContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  navigationHeader: {
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listCategory: {
    fontSize: 12,
    lineHeight: 18,
    padding: 16,
    color: colors.greyMedium
  },
  logo: {
    width: 108.65,
    height: 24
  },
  dataSaveContainer: {
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    margin: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.greyLight,
    backgroundColor: colors.white
  }
});


export default Main;