
import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from 'react-native-paper';
import MenuItem from "../MenuItem/MenuItem";
import { Menuitem } from "../../core/interfaces/menuitem.interface";
import { Category as ICategory } from "../../core/interfaces/category.interface";


export interface CategoryProps {
    category: ICategory;
    style?: any;
    saveDataMode?: boolean;
    showTitle: boolean;
    onLayout?: (event: any) => void;
    onMenuItemsLayout?: (event: any, itemId: string) => void;
    onPress?: () => void;
    onMenuItemPress?: (e: any) => void;
}

export default function Category(props: CategoryProps) {

    function handleMenuItemPress(menuitem: Menuitem) {
        props?.onMenuItemPress?.({ ...menuitem, restaurantId: props.category.restaurantId });
    }

    return (
        <View
            onLayout={props?.onLayout}
            style={{
                ...props.style,
                ...styles.container
            }}
        >
            <View style={{ flex: 1 }} >
                <>
                    {props.showTitle && <>
                        <Text variant="headingBlack" style={styles.header}>{props.category.name}</Text>
                    </>}

                    {props.category.menuitems.filter((m: any) => !m.isHidden).map(menuitem => ({ ...menuitem, restaurantId: props.category.restaurantId })).map((menuitem: any, index: number) => (<MenuItem
                        key={menuitem.id}
                        onLayout={(event: any) => props?.onMenuItemsLayout?.(event, menuitem?.id)}
                        saveDataMode={props?.saveDataMode}
                        menuitem={menuitem}
                        onPress={() => handleMenuItemPress(menuitem)}
                    />))}
                </>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white'
    },
    header: {
        lineHeight: 36,
        fontSize: 24,
        marginTop: 16,
        marginLeft: 16
    }
});