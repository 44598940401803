
import React from "react";
import { StyleSheet, View } from "react-native";
import { Chip as _Chip, Button as _Button, Text, useTheme, TouchableRipple, Surface } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { BrambleColor } from "../../types/theme";
import { capitalize } from "../../utils/utilities";
import Icon from "../Icon/Icon";


export interface BadgeProps {
    variant?: 'primary' | 'secondary' | 'tertiary';
    dimension?: 'rectangle' | 'rounded' | 'small-rounded' | 'small-rectangle' | 'pill';
    color?: BrambleColor;
    size?: 'small' | 'normal';
    text?: string;
    closable?: boolean;
    icon?: string;
    style?: any;
    disabled?: boolean;
    accessible?: boolean | undefined;
    /**
     * Accessibility label for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityLabel?: string;
    /**
     * Accessibility hint for the button. This is read by the screen reader when the user taps the button.
     */
    accessibilityHint?: string;
    /**
     * testID to be used on tests.
     */
    testID?: string;
}

export default function Badge({
    disabled,
    dimension,
    accessibilityLabel,
    accessibilityHint,
    style,
    icon,
    text,
    testID = 'badge',
    size = 'small',
    color = 'ketchup',
    variant = 'primary',
    accessible,
    ...rest
}: BadgeProps) {

    const theme = useTheme<AppTheme>();

    let _suffix: string = variant === "secondary" ? "Light" : "";
    let _on: string = variant === "secondary" ? "on" + capitalize(color).toString() + _suffix : "on" + capitalize(color).toString();

    let _color: string = color + _suffix;

    let backgroundColor = theme.colors[_color as keyof object];
    if (!backgroundColor) {
        backgroundColor = theme.colors["greyLight" as keyof object];
    }

    let textColor: string = theme.colors[_on as keyof object];
    if (!textColor) {
        textColor = theme.colors["black" as keyof object];
    }

    if (variant === "tertiary") {
        textColor = theme.colors["black" as keyof object];
    }

    let iconColor = variant === "primary" ? theme.colors[_on as keyof object] : theme.colors[color + "Dark" as keyof object];

    let height = dimension !== 'pill' ? (size === 'small' ? 24 : (size === 'normal' ? 36 : 24)) : size === 'small' ? 8 : 16;
    let marginHorizontal = dimension === 'rounded' ? 4 : 4;
    let iconSize = 12;
    let fontSize = 12;
    let borderRadius = (dimension === 'rounded' || dimension === 'small-rounded' || dimension === 'pill') ? height : 0;

    let paddingVertical = dimension === 'rounded' ? 3 : 3;
    let paddingHorizontal = dimension === 'rounded' ? 8 : 4;


    const textStyle = {
        fontSize: fontSize,
        fontWeight: "500" as "500",
        color: textColor
    };

    return (
        <Surface
            {...rest}
            testID={`${testID}-container`}
            style={{ ...style}}
            elevation={0}
        >
            <TouchableRipple
                borderless
                accessibilityLabel={accessibilityLabel}
                accessibilityHint={accessibilityHint}
                accessibilityRole="button"
                accessibilityState={{ disabled }}
                accessible={accessible}
                disabled={disabled}
                testID={testID}
                theme={theme}
                style={{
                    backgroundColor: variant !== 'tertiary' ? backgroundColor : undefined,
                    width: dimension === 'small-rectangle' ? height : undefined,
                    minWidth: dimension === 'pill' ? 8 : height,
                    height,
                    paddingVertical,
                    paddingHorizontal,
                    borderWidth: 0,
                    borderRadius,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <View
                    style={styles.content}
                >
                    {dimension !== 'pill' && <>

                        {icon &&
                            <>
                                <Icon name={icon} size={iconSize} color={iconColor} />
                                {(dimension === 'rounded' || dimension === 'rectangle') &&
                                    <View style={{ flex: 1, marginRight: marginHorizontal }}></View>
                                }
                            </>
                        }

                        <Text style={textStyle}>
                            {text}
                        </Text>

                        {/* {(dimension === 'rounded' || dimension === 'rectangle') && icon &&
                            <>
                                <View style={{ flex: 1, marginRight: marginHorizontal }}></View>
                                <Icon name={icon} size={iconSize} color={iconColor} />
                            </>
                        } */}
                    </>}
                </View>
            </TouchableRipple >
        </Surface >
    );
}

const styles = StyleSheet.create({
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    }
});
