import React, { } from "react";
import { View, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text, TouchableRipple, useTheme } from "react-native-paper";
import { useDispatch } from "react-redux";
import { AppTheme } from "../../App";
import useAxiosSecure from "../hooks/useAxiosSecure";
import IconButton from "../components/IconButton/IconButton";
import { RootStackParamList } from "../types/navigation";
import { useTranslation } from "react-i18next";
import Icon from "../components/Icon/Icon";
import { HelpEnum } from "../types/enums";
import { OrderStateEnum } from "../core/interfaces/order-state.interface";


export default function ({
    navigation, route
}: NativeStackScreenProps<RootStackParamList, "manageOrder">) {

    const dispatch = useDispatch();
    const axiosSecure = useAxiosSecure();
    const { t } = useTranslation();
    const { colors } = useTheme<AppTheme>();
    const styles = makeStyles(colors);


    return (
        <>
            <View
                style={{
                    ...styles.container,
                    height: '100%'
                }}
            >
                <View
                    style={styles.headerRow}
                >
                    <>
                        <IconButton
                            icon='arrows_outline_arrow_left'
                            color="white"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1
                            }}
                            onPress={() =>
                                navigation.goBack()
                            }
                        />

                        <Text variant="headingBlack" style={styles.header}>{t('manageOrderScreen.header')}</Text>

                    </>

                </View>

                <View style={{
                    width: '100%',
                    padding: 16
                }}>
                    <TouchableRipple
                        onPress={() => {
                            navigation.navigate("helpOrder", {
                                type: HelpEnum.Payment,
                                orderId: route?.params?.orderId
                            })
                        }}
                        style={styles.listItem}
                    >
                        <>
                            <View style={styles.row}>
                                <Icon name='ecommerce_outline_credit_card' size={20} color='black' />
                                <Text variant="paragraphBold" style={styles.paragraph16}>{t('manageOrderScreen.actions.payment')}</Text>
                            </View>
                            <Icon name='arrows_outline_chevron_right' size={20} color='black' />
                        </>
                    </TouchableRipple>

                    <TouchableRipple
                        onPress={() => {
                            navigation.navigate("helpOrder", {
                                type: HelpEnum.Order,
                                orderId: route?.params?.orderId
                            })
                        }}
                        style={styles.listItem}
                    >
                        <>
                            <View style={styles.row}>
                                <Icon name='general_outline_text_documet' size={20} color='black' />
                                <Text variant="paragraphBold" style={styles.paragraph16}>{t('manageOrderScreen.actions.order')}</Text>
                            </View>
                            <Icon name='arrows_outline_chevron_right' size={20} color='black' />
                        </>
                    </TouchableRipple>

                    <TouchableRipple
                        disabled={route?.params?.orderStateId !== OrderStateEnum.IsCreated}
                        onPress={() => {
                            navigation.navigate("cancelOrder", {
                                orderId: route?.params?.orderId
                            })
                        }}
                        style={styles.listItem}
                    >
                        <>
                            <View style={styles.row}>
                                <Icon name='ecommerce_outline_trash' size={20} color={route?.params?.orderStateId !== OrderStateEnum.IsCreated ? colors.grey : colors.black} />
                                <Text variant="paragraphBold" style={{
                                    ...styles.paragraph16,
                                    color: route?.params?.orderStateId !== OrderStateEnum.IsCreated ? colors.grey : undefined
                                }}>{t('manageOrderScreen.actions.cancel')}</Text>
                            </View>
                            <Icon name='arrows_outline_chevron_right' size={20} color={route?.params?.orderStateId !== OrderStateEnum.IsCreated ? colors.grey : colors.black} />
                        </>
                    </TouchableRipple>

                    <TouchableRipple
                        onPress={() => {
                            navigation.navigate("helpOrder", {
                                type: HelpEnum.Other,
                                orderId: route?.params?.orderId
                            })
                        }}
                        style={styles.listItem}
                    >
                        <>
                            <View style={styles.row}>
                                <Icon name='general_outline_more_horizontal' size={20} color='black' />
                                <Text variant="paragraphBold" style={styles.paragraph16}>{t('manageOrderScreen.actions.other')}</Text>
                            </View>
                            <Icon name='arrows_outline_chevron_right' size={20} color='black' />
                        </>
                    </TouchableRipple>

                </View>

                <View style={styles.infoContainer}>
                    <Text variant="paragraphRegular" style={styles.paragraph16}>{t('manageOrderScreen.info')}</Text>
                </View>

            </View>
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    stickyActionContainer: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 16,
        gap: 16,
        backgroundColor: colors.white,
        borderTopColor: colors.greyLight,
        borderTopWidth: 1
    },
    headerContainer: {
        // position: 'absolute',
        // zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 16,
        paddingLeft: 0,
        backgroundColor: colors.white,
    },
    infoContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 16,
        padding: 16,
        backgroundColor: colors.mustardLight,
        borderRadius: 4
    },
    container: {
        backgroundColor: colors.white
    },
    paragraph16: {
        lineHeight: 24,
        fontSize: 16,
    },
    paragraph14: {
        lineHeight: 21,
        fontSize: 14,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        backgroundColor: colors.white
    },
    header: {
        lineHeight: 27,
        fontSize: 18
    },
    listItem: {
        borderBottomColor: colors.greyLight,
        borderBottomWidth: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16
    },
    divider: {
        height: 16,
        backgroundColor: colors.offWhite
    }
});
