import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export const cuisineSlice = createSlice({
    name: 'cuisine',
    initialState: {
        cuisines: [] as any[]
    },
    reducers: {
        setCuisines: (state, action: PayloadAction<any[]>) => {
            state.cuisines = action.payload;
        },
        addCuisines: (state, action: PayloadAction<any[]>) => {
            if (state.cuisines.length > 0) {
                let newCuisines = action.payload.filter(c => state.cuisines.map(i => i.id).indexOf(c.id) === -1);
                if (newCuisines && newCuisines.length > 0) {
                    state.cuisines = [...state.cuisines, ...newCuisines];
                }
            } else {
                state.cuisines = action.payload;
            }
        },
        addCuisine: (state, action: PayloadAction<any>) => {
            state.cuisines.push(action.payload);
        },
        updateCuisine: (state, action: PayloadAction<any>) => {
            const cuisine = state.cuisines.find(c => c.id === action.payload.id) as any;
            Object.assign(cuisine, action.payload);
        },
        updateCuisines: (state, action: PayloadAction<Partial<any>[]>) => {
            for (let change of action.payload) {
                const cuisine = state.cuisines.find(c => c.id === change.id) as any;
                Object.assign(cuisine, change);
            }
        },
        deleteCuisine: (state, action: PayloadAction<string>) => {
            state.cuisines = [...state.cuisines.filter(c => c.id !== action.payload)];
        },
        deleteCuisines: (state, action: PayloadAction<string[]>) => {
            state.cuisines = [...state.cuisines.filter(c => action.payload.indexOf(c.id) === -1)];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setCuisines,
    addCuisines,
    addCuisine,
    updateCuisine,
    updateCuisines,
    deleteCuisine,
    deleteCuisines
} = cuisineSlice.actions

export default { cuisineReducer: cuisineSlice.reducer }
