import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface ModalState {
    dismissable?: boolean | null | undefined;
    visible?: boolean | null | undefined;
    overlayAccessibilityLabel?: string | null | undefined;
    style?: any | null | undefined;
    title: string | null | undefined;
    content: string | null | undefined;
}

const initialState: ModalState = {
    dismissable: true,
    visible: false,
    overlayAccessibilityLabel: 'Close modal',
    style: null,
    title: null,
    content: null
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        displayModal: (state, action: PayloadAction<ModalState>) => {
            state.dismissable = action.payload.dismissable;
            state.visible = action.payload.visible;
            state.overlayAccessibilityLabel = action.payload.overlayAccessibilityLabel;
            state.style = action.payload.style;
            state.title = action.payload.title;
            state.content = action.payload.content;
        },
        closeModal: (state) => {
            state.visible = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const { displayModal, closeModal } = modalSlice.actions

export default { modalReducer: modalSlice.reducer } 
