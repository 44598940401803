
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet } from "react-native";
import { Card, Text, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import { Menuitem } from "../../core/interfaces/menuitem.interface";
import Badge from "../Badge/Badge";
import { MINIO_URL } from "../../core/api/axios";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";


export interface MenuItemInfoProps {
    style?: any;
    saveDataMode?: boolean;
    menuitem?: Menuitem;
    onPress?: () => void;
    onAllergensPress?: () => void;
}

export default function MenuItemInfo(props: MenuItemInfoProps) {

    const { colors } = useTheme<AppTheme>();
    const [thumbnail, setThumbnail] = useState<string>("../../../assets/images/menuitem.png");
    const hasMandatory = props?.menuitem?.menuitemExtraCategories.map(em => em.extraCategory.isMandatory)?.some(Boolean);

    const { t } = useTranslation();
    const styles = makeStyles(colors);

    useEffect(() => {
        if (props.menuitem) {
            setThumbnail(`${MINIO_URL}/menuitems/${props?.menuitem?.id}`);
        }
    }, [props.menuitem])

    return (

        <Card mode="contained" style={{
            ...styles.container
        }}>

            {!props?.saveDataMode && <Image
                style={
                    styles.cover
                }
                defaultSource={require("../../../assets/images/menuitem.png")}
                source={{ uri: thumbnail }}
            />}

            {props?.saveDataMode && <View
                style={{ height: 80 }
                }
            />}

            <Card.Content style={styles.infoContainer}>

                <View style={{
                    flexDirection: "row",
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 4
                }}>
                    {props?.menuitem?.isPopular && <View style={{
                        flexDirection: "row",
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                    }}>
                        <Badge
                            text={t('menuItemScreen.info.isPopular')}
                            color="green"
                            variant="secondary"
                            size="small"
                            dimension="rounded"
                        />
                    </View>}

                    {props?.menuitem?.isRecommended && <View style={{
                        flexDirection: "row",
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                    }}>
                        <Badge
                            text={t('menuItemScreen.info.isRecommended')}
                            color="mustardLight"
                            variant="primary"
                            size="small"
                            dimension="rounded"
                        />
                    </View>}
                </View>

                <Text
                    style={styles.header}
                    variant="headingBlack"
                >
                    {props?.menuitem?.name}
                </Text>

                <View style={styles.descriptionRow}>
                    <Text variant="paragraphRegular" style={styles.description}>{props?.menuitem?.description}</Text>
                    {props?.menuitem?.allergens && <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: "center",
                        gap: 8
                    }}>
                        <Text variant="paragraphRegular" style={styles.description}>{t('menuItemScreen.info.allergens')}: {props?.menuitem?.allergens}</Text>
                        <Icon
                            name="system__status_outline_info"
                            color="black"
                            size={20}
                            onPress={() => props?.onAllergensPress?.()}
                        />
                    </View>}
                </View>

                {hasMandatory && <View style={styles.warningContainer}>
                    <Text variant="paragraphRegular" style={styles.warningText}>{t('menuItemScreen.info.mandatory')}</Text>
                </View>}
            </Card.Content>
        </Card >
    );
}


const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        backgroundColor: colors.white,
        borderRadius: 0
    },
    infoContainer: {
        marginTop: 16
    },
    warningContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 16,
        padding: 16,
        // height: 56,
        backgroundColor: colors.mustardLight,
        borderRadius: 4
    },
    warningText: {
        lineHeight: 24,
        fontSize: 16
    },
    header: {
        lineHeight: 35,
        fontSize: 28,
        marginTop: 16,
        marginBottom: 16
    },
    descriptionRow: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: "flex-start",
        gap: 8
    },
    description: {
        lineHeight: 24,
        fontSize: 16
    },
    cover: {
        width: '100%',
        height: 230
    }
});
