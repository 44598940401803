
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { apiSecure } from "../core/api/axios";
import { showLoading } from "../core/store/features/loading/loadingSlice";
import { ExceptionMap } from "../core/api/exceptionsMapping";
import { displayNotification } from "../core/store/features/notification/notificationSlice";
import useRefreshToken from "./useRefreshToken";

const useAxiosSecure = () => {
    const dispatch = useDispatch();
    const refresh = useRefreshToken();
    // const auth = useSelector((state: RootState) => state.auth)

    useEffect(() => {

        // const requestIntercept = apiSecure.interceptors.request.use(
        //     config => {
        //         if (config?.headers && !config.headers['Authorization']) {
        //             config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
        //         }
        //         return config;
        //     }, (error) => Promise.reject(error)
        // );

        const responseIntercept = apiSecure.interceptors.response.use(
            response => {
                return response;
            },
            async (error) => {
                const prevRequest = error?.config;
                // if response status is 401 - unauthorized, try refresh token and send request again
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    // const newAccessToken = await refresh();
                    await refresh();
                    // prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return apiSecure.request(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        const loadingResponseIntercept = apiSecure.interceptors.response.use(
            response => {
                return response;
            },
            async (error) => {

                dispatch(showLoading({
                    showLoading: false
                }));

                return Promise.reject(error);
            }
        );

        const errorIntercept = apiSecure.interceptors.response.use(
            response => {
                return response;
            },
            async (error) => {

                let message;

                if (!error.response) {
                    message = "Chyba pri pripájaní k serveru. Skontrolujte svoje internetové pripojenie.";
                } else {
                    message = error.response.data.detail.traceback; // ExceptionMap[error.response.data.detail.errorCode] || "Neznáma chyba.";
                }

                dispatch(displayNotification({
                    message: message,
                    severity: "error"
                }));

                return Promise.reject(error);
            }
        );

        return () => {
            // apiSecure.interceptors.request.eject(requestIntercept);
            apiSecure.interceptors.response.eject(responseIntercept);
            apiSecure.interceptors.response.eject(loadingResponseIntercept);
            apiSecure.interceptors.response.eject(errorIntercept);
        }
    }, [refresh])

    return apiSecure;
}

export default useAxiosSecure;
