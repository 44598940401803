
import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, ScrollView } from "react-native";
import { Checkbox, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { AppTheme } from "../../../App";
import Button from "../Button/Button";
import { RootState } from "../../core/store/store";
import { useSelector } from "react-redux";
import FestivalItem from "../FestivalItem/FestivalItem";
import AppHeader from "../AppHeader/AppHeader";


export interface FestivalsListProps {
    style?: any;
    festivals: any[];
    saveDataMode?: boolean;
    onPress?: (e: any) => void;
}

export default function FestivalsList(props: FestivalsListProps) {

    const { colors } = useTheme<AppTheme>();

    const styles = makeStyles(colors);


    return (
        <>
            <ScrollView
                style={{
                    padding: 8
                }}
            >
                {/* <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Text
                        style={styles.header}
                        variant="headingBlack"
                    >
                        Vyber Festival!
                    </Text>
                </View> */}

                {props?.festivals?.map((festival, index) => (
                    <FestivalItem
                        key={index}
                        saveDataMode={props?.saveDataMode}
                        festival={festival}
                        onPress={props?.onPress}
                    />
                ))}
            </ScrollView>
        </>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    header: {
        lineHeight: 36,
        fontSize: 24
    }
});